import React, { useState, useEffect } from "react";
import { Step } from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_ACTIVE_VERSION } from "../../../models/catalogs/queries";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";
import CatalogPageView from "./manage";
import { withUser } from "../../../context/user";
import FinishStep from "./finish";
import CompletedStep from "./completed";
import "./index.css";

const CreateForm = ({ mode, ...props }) => {
  const user = props.user;

  const { loading, error, data } = useQuery(GET_ACTIVE_VERSION, {
    variables: { id: parseInt(user.activeCatalog) },
  });

  const [step, setStep] = useState(1);
  const [pages, setPages] = useState([]);
  const [dynalog, setDynalog] = useState({});

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const redirectDynalog = (dynalog) => {
    console.log("redirect to catalog ", dynalog);
    setDynalog(dynalog);
  };

  const renderStep = (currentCatalog) => {
    switch (step) {
      case 1:
        return (
          <CatalogPageView
            syncPages={(pages) => setPages(pages)}
            pages={pages}
            goNext={nextStep}
            goBack={prevStep}
          />
        );
        break;
      case 2:
        return (
          <FinishStep
            catalog={currentCatalog}
            version={version}
            user={user}
            goBack={prevStep}
            goNext={(dynalog) => redirectDynalog(dynalog)}
            pages={pages}
            mode={mode}
          />
        );
        break;
      case 4:
        return (
          <CompletedStep goBack={prevStep} dynalog={dynalog} mode={mode} />
        );
        break;
    }
  };

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data) return <p>Not found</p>;
  if (!user.activeCatalog) return <p>Select Account/Dynalog First</p>;

  const catalog = data.catalog;
  const version = catalog.activeVersion;

  //if (!isEmpty(dynalog)) return <Redirect to={`/editor`} />;

  return (
    <div className="createSteps">
      <Step.Group size="mini" fluid ordered>
        <Step completed={step > 1} active={step == 1}>
          <Step.Content>
            <Step.Title>Upload</Step.Title>
            <Step.Description>Create from PDF or Images</Step.Description>
          </Step.Content>
        </Step>

        <Step completed={step > 2} active={step == 2}>
          <Step.Content>
            <Step.Title>Save</Step.Title>
            <Step.Description>Add Information</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>

      <div>{renderStep(catalog)}</div>
    </div>
  );
};

export default withUser(CreateForm);
