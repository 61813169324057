import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ApolloProvider } from '@apollo/react-hooks';
import DynalogPreview from './preview';
import './Dynalog.css';

const Catalog = (props) => {
	return (
			<div className="content">
				<div className="dynalogWrapper">
					<DynalogPreview />
				</div>
			</div>
	);
};

export default Catalog;
