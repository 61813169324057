import React, { Component } from "react";
import styled, { css } from "styled-components";

function Product8(props) {
  return (
    <>
      <Rect>{props.products[0]}</Rect>
      <Rect73>{props.products[1]}</Rect73>
      <Rect74>{props.products[2]}</Rect74>
      <Rect75>{props.products[3]}</Rect75>
      <Rect76>{props.products[4]}</Rect76>
      <Rect77>{props.products[5]}</Rect77>
      <Rect78>{props.products[6]}</Rect78>
      <Rect79>{props.products[7]}</Rect79>
    </>
  );
}

const Rect = styled.div`
  top: 41px;
  left: 12px;
  width: 175px;
  height: 175px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect73 = styled.div`
  top: 182px;
  left: 158px;
  width: 175px;
  height: 175px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect74 = styled.div`
  top: 41px;
  left: 208px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect75 = styled.div`
  top: 232px;
  left: 12px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect76 = styled.div`
  top: 368px;
  left: 12px;
  width: 75px;
  height: 75px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect77 = styled.div`
  top: 368px;
  left: 94px;
  width: 75px;
  height: 75px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect78 = styled.div`
  top: 368px;
  left: 175px;
  width: 75px;
  height: 75px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect79 = styled.div`
  top: 368px;
  left: 257px;
  width: 75px;
  height: 75px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default Product8;
