import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Profile from './profile';
import Dynalogs from './dynalogs';
import Users from './users';
import Settings from './settings';
import Incentive from './incentive';
import Api from '../../lib/api';
import { withUser } from '../../context/user';
import EmailTemplate from './emailTemplate';

const Pad = (props) => {
	const user = props.user;
	const [ org, setOrg ] = useState({});

	const [ tabIndex, setTabIndex ] = useState(0);

	useEffect(
		() => {
			handleOrg();
		},
		[ user.padOrg ]
	);

	const handleOrg = async () => {
		if (user.padOrg) {
			const { data } = await Api.getOrg(user.padOrg);
			setOrg(data);
		} else {
			setOrg({});
		}
	};

	return (
		<div className="content">
			{!isEmpty(org) && (
				<Tabs selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)}>
					<TabList>
						<Tab>Profile</Tab>
						<Tab>Dynalogs</Tab>
						<Tab>Email Template</Tab>
						<Tab>Users</Tab>
						<Tab>Incentives</Tab>
						<Tab>Settings</Tab>
					</TabList>

					<TabPanel>
						<Profile org={org} />
					</TabPanel>
					<TabPanel>
						<Dynalogs org={org} />
					</TabPanel>
					<TabPanel>
						<EmailTemplate org={org} />
					</TabPanel>
					<TabPanel>
						<Users org={org} />
					</TabPanel>
					<TabPanel>
						<Incentive org={org} />
					</TabPanel>
					<TabPanel>
						<Settings org={org} />
					</TabPanel>
				</Tabs>
			)}
		</div>
	);
};

export default withUser(Pad);
