import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import CatalogPageView from "./manage";
import { withUser } from "../../../context/user";
import "./index.css";
import { Dimmer, Loader, Button } from "semantic-ui-react";
import CatalogInput from "./catalogInput";
import {
  CREATE_CATALOG,
  CREATE_CATALOG_VERSION,
  UPDATE_CATALOG,
} from "../../../models/merchant/mutations";
const uuidv1 = require("uuid/v1");

const CreateCatalog = ({ mode, done, ...props }) => {
  const [pages, setPages] = useState([]);

  const user = props.user;

  const version = user.activeVersion;
  const catalog =
    user.activeAccount?.catalogs?.find((xx) => xx.id === user.activeCatalog) ||
    user.catalog;

  const [loading, setLoading] = useState(false);
  const [createCatalog] = useMutation(CREATE_CATALOG);
  const [createVersion] = useMutation(CREATE_CATALOG_VERSION);
  const [updateCatalog] = useMutation(UPDATE_CATALOG);

  const onSubmit = (data) => {
    if (pages.length === 0) return;

    const catalogOid = uuidv1();
    const versionOid = uuidv1();

    setLoading(true);

    const versionOptions = version ? version.options : {};

    const versionPages = getPagesArray();
    const versionPayload = {
      oid: versionOid,
      coverUrl: versionPages[0].imageUrl,
      active: true,
      order: getPagesOrder(versionPages),
      pagesUsingOid: {
        create: versionPages,
      },
    };

    if (mode === "version") {
      createVersion({
        variables: {
          version: {
            ...versionPayload,
            catalog: catalog.oid,
          },
        },
      })
        .then(({ data }) => {
          console.log("createdDynalogVersionMutation: ", data);
          updateCatalog({
            variables: {
              oid: catalog.oid,
              catalog: {
                activeVersion: versionOid,
                cover: versionPayload.coverUrl,
              },
            },
          })
            .then(({ data }) => {
              props.setUser({
                ...props.user,
                activeVersion: versionOid,
              });
              setLoading(false);
              done();
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    } else if (mode === "catalog") {
      const catalogPayload = {
        oid: catalogOid,
        account: user.activeAccount.oid,
        name: data.name,
        isDigital: true,
        active: true,
      };

      createCatalog({
        variables: {
          catalog: catalogPayload,
        },
      })
        // .then(({ data }) => {
        //   //return data;
        // })
        .then(() => {
          const payload = {
            ...versionPayload,
            catalog: catalogPayload.oid,
          };
          createVersion({
            variables: {
              version: payload,
            },
          })
            .then(() => {
              updateCatalog({
                variables: {
                  oid: catalogOid,
                  catalog: {
                    activeVersion: versionOid,
                    cover: versionPayload.coverUrl,
                  },
                },
              })
                .then(({ data }) => {
                  console.log("updatedDynalogMutation: ", data);
                  const catalog = data.updateCatalog.catalog;

                  props.clearUser(props.user.email);

                  setLoading(false);
                  done();
                })
                .catch((e) => console.log(e));
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }

    setLoading(false);
  };

  const getPagesArray = () => {
    return pages.map((page) => {
      return {
        oid: uuidv1(),
        imageUrl: page.path,
        pageType: "lifestyle",
      };
    });
  };
  const getPagesOrder = (npages) => {
    return npages.map((page, index) => {
      return {
        oid: page.oid,
        num: index,
      };
    });
  };

  //const currDynalog = data.catalog;
  //const version = currDynalog.version;
  const newName = () => {
    return user.activeAccount?.name + " - New Catalog";
  };
  return (
    <>
      {pages.length > 0 &&
        (mode === "catalog" ? (
          <CatalogInput name={newName()} onSubmit={onSubmit} />
        ) : (
          <Button onClick={onSubmit}>Save</Button>
        ))}

      <CatalogPageView setPages={setPages} pages={pages} />
    </>
  );
};

export default withUser(CreateCatalog);
