export default [
	{
		value: 'Title',
		id: 'title',
		text: 'Title',
		required: false
	},
	{
		value: 'SKU',
		id: 'SKU',
		text: 'SKU',
		required: true
	},
	{
		value: 'Price',
		id: 'Price',
		text: 'Price',
		required: false
	},
	{
		value: 'SalePrice',
		id: 'SalePrice',
		text: 'SalePrice',
		required: false
	},
	{
		value: 'ImageUrl',
		id: 'ImageUrl',
		text: 'ImageUrl',
		required: false
	},
	{
		value: 'ProductUrl',
		id: 'ProductUrl',
		text: 'ProductUrl',
		required: false
	},
	{
		value: 'Description',
		id: 'description',
		text: 'Description',
		required: false
	},
	{
		value: 'DescriptionHTML',
		id: 'descriptionHtml',
		text: 'DescriptionHtml',
		required: false
	},
	{
		value: 'Category',
		id: 'Category',
		text: 'Category',
		required: false
	},
	{
		value: 'Subcategory',
		id: 'Subcategory',
		text: 'Subcategory',
		required: false
	},
	{
		value: 'Tags',
		id: 'Tags',
		text: 'Tags',
		required: false
	},	
	{
		value: 'AdditionalImages',
		id: 'AdditionalImages',
		text: 'AdditionalImages',
		required: false
	},
	{
		value: 'Brand',
		id: 'Brand',
		text: 'Brand',
		required: false
	},
	{
		value: 'Color',
		id: 'Color',
		text: 'Color',
		required: false
	},
	{
		value: 'ID',
		id: 'id',
		text: 'ID',
		required: false
	},
];
