import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import DataGrid, { Paging, Column, Editing, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import Api from '../../lib/api';

const Users = (props) => {
	const org = props.org;
	const [ users, setUsers ] = useState([]);
	useEffect(() => {
		handleListUsers();
	}, []);

	const handleListUsers = async () => {
		const { data } = await Api.getOrgUsers(org.id);
		console.log(data);
		setUsers(data);
	};

	const data = () => {
		return users.map((user) => {
			return {
				ID: user.id,
				fname: user.fname,
				lname: user.lname,
				email: user.email,
				phone: user.phone,
				title: user.title,
				website: user.website
			};
		});
	};

	//INSERT
	const onInitNewRow = (event) => {
		//console.log('onInitNewRow:', event);
	};
	const onRowInserting = (event) => {
		//console.log('onRowInserting:', event);
	};
	const onRowInserted = (user) => {
		//console.log('onRowInserted:', user);
		
		let body = new FormData();
		body.append('fname', user.data.fname);
		body.append('lname', user.data.lname);
		body.append('email', user.data.email);
		body.append('phone', user.data.phone);
		body.append('website', user.data.website);
		body.append('title', user.data.title);
		body.append('role', 'user');
		body.append('active', true);
		body.append('org_id', org.id);
		body.append('org_override', false);
		
		Api.createUser(body).then(
			(user) => {
				console.log(user);
			},
			(err) => {
				console.log(err);
			}
		);
		
	};

	// UPDATE
	const onEditingStart = (event) => {
		//console.log('onRowUpdating:', event);
	};
	const onRowUpdating = (user) => {
		//console.log('onRowUpdating:', user);
	};
	const onRowUpdated = (user) => {
		//console.log('onRowUpdated', user);
		let body = new FormData();
		body.append('fname', user.data.fname);

		Api.updateUser(user.data.ID, body).then(
			(user) => {
				console.log(user);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	// DELETE
	const onRowRemoving = (event) => {
		console.log('onRowRemoving:', event);
	};
	const onRowRemoved = (user) => {
		console.log('onRowRemoved:', user);
		Api.updateUser(user.data.ID).then(
			(user) => {
				console.log(user);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const columns = [ 'Fname', 'Email' ];

	return (
		<div className="content">
			<DataGrid
				columnHidingEnabled={true}
				dataSource={data()}
				keyExpr={'ID'}
				showBorders={true}
				onEditingStart={onEditingStart}
				onInitNewRow={onInitNewRow}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
			>
				<SearchPanel visible={true} placeholder={'Search...'} />
				<Paging enabled={true} />
				<Editing mode={'form'} allowUpdating={true} allowDeleting={true} allowAdding={true} />
				<Column dataField={'fname'} caption={'First Name'} />
				<Column dataField={'lname'} caption={'Last Name'} />
				<Column dataField={'email'} caption="Email" />
				<Column dataField={'phone'} caption="Phone" />
				<Column dataField={'title'} caption="Title" />
				<Column dataField={'website'} caption="Website" />
			</DataGrid>
		</div>
	);
};

export default Users;
