import React, { useState, useEffect } from 'react';
import { isEmpty, includes } from 'lodash';
import { Card, Image } from 'semantic-ui-react';
import Api from '../../lib/api';

const Dynalogs = (props) => {

    const org = props.org;
    console.log("ORG ", org)
    const [ dynalogs, setDynalogs ] = useState([]);
	useEffect(() => {
        handleListDynalogs();
    }, []);

    const handleListDynalogs = async () => {
		const { data } = await Api.getGroups(org.merchant_portal_id);
		setDynalogs(data);
    }

    const renderDynalogs = () => {
        
        return dynalogs.map( (group)=>{
            let checked = includes(dynalogs, group.group_id);

            return (

                <Card key={group.group_id}>
                    <Card.Content>
                      {!isEmpty(group.group_cover.src_thumb) &&
                        <Image floated='left' size='mini' src={"http://cdn.catalogs.com" + group.group_cover.src_thumb} />
                      }
                      <Card.Header>
                        {group.group_name}
                      </Card.Header>
                      <Card.Meta>
                        <a href={`${group.group_url}?merchantID=${org.merchant_portal_id}`} target="_blank"> {group.group_url} </a>
                      </Card.Meta>
                      <Card.Description>
                        {group.description}
                      </Card.Description>
                    </Card.Content>
                  </Card>

            )
        })
    }

    return (
        <div className="content">
                {
                    !isEmpty(dynalogs) &&
                    <Card.Group>
                        {renderDynalogs()}
                    </Card.Group>
                }
        </div>
    )
}

export default Dynalogs;