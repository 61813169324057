import moment from 'moment';
import React from 'react';
import { Button } from 'semantic-ui-react';

const VentureEmailReports = (props) => {
	const reports = props.reports;
	const ventureData = props.data;

	const emailReports = reports.filter((report) => {
		return report.type == 'email';
	});

	return emailReports.map((report) => {
		const settings = report.settings;
		const campaignid = settings.campaignid;
		const dealerid = settings.dealerid;

		const data = ventureData.filter((data) => {
			let date = moment(data.date).format('YYYYMM');
			return data.domain == dealerid;
		});
		return data.map((emailData) => {
			return (
				<div className="emailReport" key={emailData.emailstats_id}>
					<h4>{report.name}</h4>
					<div className="emailReportData">
						<div className="startDate">
							{emailData.date}
							<label>Start Date</label>
						</div>
						<div className="ordered">
							{emailData.quantity}
							<label>Ordered</label>
						</div>
						<div className="opened">
							{emailData.opens}
							<label>{emailData.openpct}% Opened</label>
						</div>
						<div className="clicked">
							{emailData.clicks}
							<Button
								content={`${emailData.clickpct}% Clicks`}
								icon="eye"
								labelPosition="right"
								onClick={() => props.openClicksPopup(emailData)}
							/>
						</div>
					</div>
				</div>
			);
		});
	});
};

export default VentureEmailReports;
