import React, { useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { isEmpty } from "lodash";
import { Image, Button, Icon, Modal, Header } from "semantic-ui-react";
import defaultImage from "./../../assets/img/page.png";

export function RestorePages({ items, restore }) {
  const [visible, setvisible] = useState(false);

  const SortableItem = SortableElement((props) => {
    let backgroundColor = "#CCC";
    if (!isEmpty(props.value.options) && !isEmpty(props.value.options.canvas)) {
      //backgroundColor = props.value.options.canvas.data.background;
    }
    const { imageUrl, pageType } = props.value;
    const isDouble =
      !isEmpty(props.value.options) && !isEmpty(props.value.options.isDouble)
        ? true
        : false;
    return (
      <li className={["dynPage", isDouble ? "dynPage-isDouble" : ""].join(" ")}>
        <Button
          circular
          className="deleteBtn"
          icon="recycle"
          color="green"
          size="mini"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            restore(props.value);
            setvisible(false);
          }}
        />
        {pageType === "products" ? (
          <div
            style={{
              backgroundColor: backgroundColor,
              width: "100%",
              height: "86%",
            }}
          />
        ) : (
          <Image
            src={
              !isEmpty(imageUrl) && imageUrl != "/"
                ? `https://cdn.catalogs.com${imageUrl}`
                : defaultImage
            }
            size="small"
            bordered
          />
        )}

        <div>{props.position + 1}</div>
      </li>
    );
  });
  const SortableList = SortableContainer(() => {
    return (
      <ul className="pagesWrapper">
        {items?.map((value, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              position={index}
            />
          );
        })}
      </ul>
    );
  });
  return (
    <>
      <Button
        secondary
        icon
        labelPosition="left"
        onClick={() => setvisible(true)}
      >
        <Icon name="redo" />
        Restore Pages
      </Button>
      <Modal
        size="fullscreen"
        className="settingsModal"
        closeIcon
        open={visible}
        onClose={() => setvisible(false)}
      >
        <Header icon="browser" content="Restore Deleted Pages" />
        <Modal.Content scrolling>
          <SortableList distance={1} axis="xy" />
        </Modal.Content>
      </Modal>
    </>
  );
}
