import React, { useState, useRef } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import DataGrid, {
	Paging,
	Column,
	Editing,
	Lookup,
	RequiredRule,
	SearchPanel,
	Scrolling,
	Export,
	DataSource
} from 'devextreme-react/data-grid';
import Papa from 'papaparse';
import axios from 'axios';
import { useQuery, useMutation } from '@apollo/react-hooks';
import MappingForm from '../../Mapping/mappingForm';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import defaultMapping from '../../Mapping/products_mapping';

const CompletedStep = ({ dynalog, ...props }) => {
	const gridEl = useRef(null);

	const [ items, setItems ] = useState([]);
	const [ mapping, setMapping ] = useState([]);
	const [ options, setOptions ] = useState([]);
	const [ mapItems, setMapItems ] = useState(true);
	const [ openMap, setOpenMap ] = useState(false);

	const getMappingOptions = () => {
		const grid = gridEl.current.instance;
		const products = grid.getDataSource().items();

		let mapping = {};
		Object.keys(products[0]).map((key) => {
			mapping[key] = key;
		});
		return mapping || {};
	};

	const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
		onDrop: (acceptedFiles) => {
			acceptedFiles.forEach((file) => {
				Papa.parse(file, {
					header: true,
					worker: true, // Don't bog down the main thread if its a big file
					complete: function(results) {
						if (results.data) {
							const rows = results.data;
							setMapItems(false);
							setItems(rows);
							setOptions(Object.keys(rows));
							console.log('ROWS ', rows);
						}
					},
					error: function(err, file, inputElem, reason) {
						console.log('error ', err);
					}
				});
			});
		},
		multiple: false,
		accept: '.csv',
		noClick: true,
		noKeyboard: true
	});

	const updateProductMapping = (mapping) => {
		setMapping(mapping);
		setOpenMap(false);
	};

	const saveData = () => {
		console.log('save mapping to db ', mapping);
	};

	return (
		<div>
			<h4>Dynalog completed!</h4>
			<h3>Connect Products</h3>
			<div>
				<DataGrid ref={gridEl} dataSource={items} showBorders={true} columnHidingEnabled={true}>
					<Scrolling columnRenderingMode="virtual" />
					<SearchPanel visible={true} placeholder={'Search...'} />
					<Paging enabled={true} limit={10} />
					<Editing mode="popup" allowUpdating={true} allowDeleting={true} />
					{/*<Export fileName={dynalog.name} enabled={true} allowExportSelectedData={true} />*/}
				</DataGrid>
				<input {...getInputProps()} />
				<br />
				<Button.Group>
					<Button color="teal" onClick={() => open()}>
						LOAD DATA
					</Button>
					{!isEmpty(items) && (
						<React.Fragment>
							<Button secondary onClick={() => setOpenMap(true)}>
								MAP PRODUCTS
							</Button>
						</React.Fragment>
					)}
					{openMap && (
						<Modal closeIcon open={openMap} onClose={() => setOpenMap(false)}>
							<Header icon="browser" content="Map Products" />
							<Modal.Content scrolling>
								<MappingForm
									mapping={defaultMapping}
									options={getMappingOptions()}
									title="Map products fields"
									onSave={(mapping) => updateProductMapping(mapping)}
								/>
							</Modal.Content>
						</Modal>
					)}
					{!isEmpty(items) &&
					!isEmpty(mapping) && (
						<Button primary onClick={() => saveData()}>
							SAVE DATA
						</Button>
					)}
				</Button.Group>
			</div>

			<br />
			{/*** ${dynalog.version.id} */}
			<Link to={`/editor`}>
				<Button primary fluid>
					View/Manage Dynalog
				</Button>
			</Link>
		</div>
	);
};

export default CompletedStep;
