import React, { useState, useMemo } from 'react';
import { Input, Checkbox, Form } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

const EditProductValue = (props) => {

	const setEditedValue = (e, valueChangedEventArg) => {
		props.onValueChanged(valueChangedEventArg.value);
	};

	const onUpdateChanged = (e, data) => {
		props.onUpdateMode(props.name, data.checked);
	};

	const MemoizedCheckbox = useMemo(
		() => () => (
			<Form.Field className="feezeValueCell">
				<Checkbox label="freeze value" checked={props.checked} onChange={onUpdateChanged} />
			</Form.Field>
		),
		[ props.name ]
	);

	return (
		<Form className="updateProductForm">
			<Form.Group widths={2}>
				<Form.Field>
					<Input defaultValue={props.defaultValue} onChange={setEditedValue} />
				</Form.Field>
				<MemoizedCheckbox />
			</Form.Group>
		</Form>
	);
};

export default EditProductValue;
