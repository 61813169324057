export const getAuthorizedUsers = `query GetUserData($email: String!){
    authorizedUsers(condition: {email: $email}) {
      oid
      email
      partnerPartnerAccessAuthorizedUserAuthorizedUsersByAuthorizedUsers{
        partnerByPartnerAccess {
          oid
          name
          dynalogAccountsByPartner {
            id
            oid
            legacyId
            name
            dynalogsByAccount{
              id
              oid
              account
              merchantId
              name
              status
              dynalogVersionByActiveDynalog {
                id
                oid
                status
                name
              }
            }
          }
        }
      }
    }
}
  `;

export const getAllAuthorizedUsers = `
query GetAllAuthorizedUsers {
  authorizedUsers(orderBy: EMAIL_ASC) {
    email
  }
}
`;

export const getLastUserId = `query GetLastUserId {
  authorizedUsers(orderBy: OID_DESC, first: 1) {
    oid
  }
}`;

export const getLastPartnerAccessId = `query GetLastPartnerAccessId {
  partnerPartnerAccessAuthorizedUserAuthorizedUsers(orderBy: OID_DESC, first: 1) {
    oid
  }
}`;

export const getUserCampaigns = `query GetUserCampaigns($email: String!){
    campaignUsers(condition: {email: $email}){
      oid
      email
      campaignGroupByCampaignGroup{
        oid
        name
        dynalogByDynalog {
          account
          id
          name
        }
      }
    }
}`;

export const getCampaigns = `query GetCampaigns($url: String!){
  dynalogCampaigns(condition: {url: $url}){
    oid
    name
    url
    campaignGroupsByDynalogCampaign{
      oid
      name
      dynalogByDynalog {
        id
        name
      }
    }
  }
}`;

export const getMarketingCampaigns = `query GetMarketingCampaigns($account: UUID!){
  marketingCampaigns(condition: {account: $account}) {
    oid
    account
    sender
    subject
    dynalogAccountByAccount {
      name
    }
  }
}`;
