import React, { useState, useCallback, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { isEmpty, includes } from 'lodash';
import DataGrid, {
	Paging,
	Column,
	Editing,
	Lookup,
	RequiredRule,
	SearchPanel,
	MasterDetail
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { CREATE_CATALOG_FEED, UPDATE_CATALOG_FEED, DELETE_CATALOG_FEED, GET_CATALOG_FEEDS } from '../../models/merchant/mutations';
import DealerLookup from './dealerLookup';
import Mapping from '../Mapping/mapping';
import defaultMapping from '../Mapping/marketcheck_mapping';
import FeedGrid from './feedGrid';
const uuidv1 = require('uuid/v1');

const ListMFeeds = (props) => {
	const catalogs = props.user.activeAccount?.catalogs || [];
	const done = props.onFinished;

	const { loading, error, data, refetch } = useQuery(GET_CATALOG_FEEDS);

	const [createFeed] = useMutation(CREATE_CATALOG_FEED);
	const [updateFeed] = useMutation(UPDATE_CATALOG_FEED);
	const [deleteFeed] = useMutation(DELETE_CATALOG_FEED);

	const carTypes = [
		{
			value: 'new',
			Name: 'New'
		},
		{
			value: 'used',
			Name: 'Used'
		},
		{
			value: 'certified',
			Name: 'Certified'
		}
	];
	const feeds = () => {
		if (data.feeds) {
			if (props.catalog)
				return data.feeds.filter(xx => xx.catalog.oid === props.catalog.oid);
			return data.feeds;
		}

	}
	//INSERT
	const onInitNewRow = (event) => {
		//console.log('onInitNewRow:', event);
	};
	const onRowInserting = (event) => {
		const oid = props.catalog?.oid || event.data.catalog.oid;
		const payload = event.data;
		payload.oid = uuidv1();
		delete payload.__typename;
		delete payload.catalog;
		payload.catalog = oid;

		createFeed({
			variables: {
				feed: payload,
			},
		})
			.then((result) => {
				console.log(result);
				refetch();
			})
			.catch((e) => console.log(e));

		//console.log('onRowInserting:', payload, event);
	};

	const onRowInserted = (feed) => {
		//refetch();
		//console.log('onRowInserted:', feed);
	};

	// UPDATE
	const onEditingStart = (event) => {
		//console.log('onRowUpdating:', event);
	};
	const onRowUpdating = (event) => {

		//console.log('onRowUpdating:', event);
	};
	const onRowUpdated = (event) => {
		// const payload = {
		// 	catalog,
		// 	autoUpdate: false,
		// 	feedType: 'marketcheck',
		// 	feedMap: map,
		// 	options: JSON.stringify(feed)
		// };
		const oid = event.data.catalog.oid;
		const payload = event.data;
		delete payload.__typename;
		delete payload.catalog;
		payload.catalog = oid;


		updateFeed({
			variables: {
				oid: event.data.oid,
				feed: payload
			}
		})
			.then((result) => {
				refetch();
			})
			.catch((e) => console.log(e));
		//refetch();
	};

	// DELETE
	const onRowRemoving = (event) => {
		const { oid } = event.data;
		deleteFeed({
			variables: {
				oid
			}
		})
			.then((result) => {
				refetch();
			})
			.catch((e) => console.log(e));
	};
	const onRowRemoved = (feed) => {
		refetch();
	};

	const onRowValidating = (event) => {
		//console.log('onRowValidating:', event);

		/*
		const { oldData, newData } = event
		if(!isEmpty(oldData) && isEmpty(oldData.dealerId)){
			alert("Select Dealer using Dealer Lookup Button")
			return false;
		}
		*/
	};

	const catalogList = () => {
		return catalogs.map((catalog) => {
			return {
				ID: catalog.oid,
				Name: catalog.name
			};
		});
	};

	const mappingEdit = (cell) => {
		const data = cell.data.data;

		const mapping = !isEmpty(data.mapping) ? JSON.parse(data.mapping) : {};
		return <Mapping mapping={mapping} onSave={(v) => cell.data.setValue(v)} />;
	};

	if (loading) return <p>Loading ...</p>;
	if (error) return <p>ERROR: {error.message}</p>;
	if (!data) return <p>Not found</p>;
	return (
		<div className="content">
			<DataGrid
				columnHidingEnabled={true}
				dataSource={feeds()}
				keyExpr={'oid'}
				showBorders={true}
				onEditingStart={onEditingStart}
				onInitNewRow={onInitNewRow}
				onRowInserting={onRowInserting}
				onRowInserted={onRowInserted}
				onRowUpdating={onRowUpdating}
				onRowUpdated={onRowUpdated}
				onRowRemoving={onRowRemoving}
				onRowRemoved={onRowRemoved}
				onRowValidating={onRowValidating}
			>
				<SearchPanel visible={true} placeholder={'Search...'} />
				<Paging enabled={true} />
				<Editing mode="form" allowUpdating={true} allowDeleting={true} allowAdding={true} />
				{!props.catalog ?
				 <Column caption="Catalog" dataField="catalog.oid" visible={true}>
					<Lookup dataSource={catalogs} displayExpr="name" valueExpr="oid" />
					<RequiredRule />
				</Column> :
				 <Column caption="name" dataField="options.name" visible={true}>					 
					 <RequiredRule />
			 	</Column>
				}
				<Column caption="Type" dataField="options.carType" visible={false}>
					<Lookup dataSource={carTypes} displayExpr="Name" valueExpr="value" />
					<RequiredRule />
				</Column>
				<Column caption="Make" dataField="options.make" visible={false} />
				<Column caption="Model" dataField="options.model" visible={false} />
				<Column caption="Limit" dataField="options.limit" visible={false} />
				{/*<Column dataField="active" visible={false} />*/}
				<Column caption="Mapping" dataField="feedMap" editCellComponent={mappingEdit} visible={false} />
				<Column caption="Dealer" dataField="options.dealerId" editCellComponent={DealerLookup} visible={false} />
				<Column dataField="dateModified" formItem={{ visible: false }} />
				<MasterDetail enabled={true} component={FeedGrid} />
			</DataGrid>
		</div>
	);
};

export default ListMFeeds;
