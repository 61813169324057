import React  from 'react';
import { useParams } from 'react-router-dom';
import CreateForm from '../../components/Dynalog/CreateForm';

const Create = () => {
	const { type } = useParams();

	return (
			<div className="content">
				<div className="dynalogWrapper">
					<h1>Create {type}</h1>
					<CreateForm mode={type} />
				</div>
			</div>
	);
};

export default Create;
