/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f1397594-a842-4104-8dd6-3a4a3582ce23",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_roaUAzk7A",
    "aws_user_pools_web_client_id": "1fdb7rkq6vrghmvij3re00ci89",
    "oauth": {
        "domain": "reactcentner9c054a71-9c054a71-beta.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://center.dynalog.net:3000/",
        "redirectSignOut": "https://center.dynalog.net:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "reactcentner72c7e6faa3e5401eb83d36f3d46e4141-beta",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
