import React, { Component } from "react";
import styled, { css } from "styled-components";

function Product4(props) {
  return (
    <>
      <Rect>{props.products[0]}</Rect>
      <Rect73>{props.products[1]}</Rect73>
      <Rect74>{props.products[2]}</Rect74>
      <Rect75>{props.products[3]}</Rect75>
    </>
  );
}

const Rect = styled.div`
  top: 41px;
  left: 12px;
  width: 175px;
  height: 175px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect73 = styled.div`
  top: 66px;
  left: 208px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect74 = styled.div`
  top: 275px;
  left: 12px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect75 = styled.div`
  top: 250px;
  left: 158px;
  width: 175px;
  height: 175px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default Product4;
