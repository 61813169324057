import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form, Input} from 'semantic-ui-react';
import { RHFInput } from 'react-hook-form-input';

const CatalogInput = ({ ...props }) => {
	const { register, errors, handleSubmit, setValue, control } = useForm();

	return (
		<Form onSubmit={handleSubmit(props.onSubmit)}>
			<Form.Group widths="equal">
				<Form.Field>
					<label>Title</label>
					<RHFInput
						as={<Input />}
						register={register}
						setValue={setValue}
						defaultValue={props.name}
						name="name"
						rules={{ required: true, maxlength: 20 }}
					/>
					{errors.title && 'Name is required'}
				</Form.Field>
			</Form.Group>
			<Button primary type="submit">
				SAVE
			</Button>
		</Form>
	);
};

export default CatalogInput;
