import "./MainContainer.css";
import React from "react";
import Catalog from "../Dynalog";
import DynalogEditor from "../DynalogEditor";
import Create from "../Create";
import Pad from "../Pad";
import Reports from "../Reports";
import Campaigns from "../Campaigns";
import Ingestor from "../Ingestor";
import Merchant from "../Merchant";
import Analytics2 from "../Analytics";
import { Switch, Route } from "react-router-dom";
import Analytics from "../Dashboard/analytics";
import AccountView from "../Dashboard/account";
import Leads from "../Leads";
import CatalogList from "../../components/Merchant/catalogs";

function Routes() {
  const routes = [
    {
      path: ["/analytics"],
      component: Analytics2,
    },

    {
      path: "/editor/:id",
      component: DynalogEditor,
    },
    {
      path: "/new/:type",
      component: Create,
    },
    {
      path: "/preview",
      component: Catalog,
    },
    {
      path: "/reports",
      component: Reports,
    },
    {
      path: "/pad",
      component: Pad,
    },
    {
      path: "/campaigns",
      component: Campaigns,
    },
    {
      path: "/feed_import",
      component: Ingestor,
    },
    {
      path: "/merchant",
      component: Merchant,
    },
    {
      path: "/catalogs",
      component: CatalogList,
    },
    {
      path: "/editor",
      component: DynalogEditor,
    },
    {
      path: "/leads",
      component: Leads,
    },
    {
      path: "/analytics2",
      component: Analytics,
    },
    {
      path: "/",
      component: DynalogEditor,
    },
  ];
  function getRoutes() {
    return routes.map((prop, key) => {
      return (
        <Route
          path={prop.path}
          render={(props) => <prop.component {...props} />}
          key={key}
        />
      );
    });
  }

  return <Switch>{getRoutes()}</Switch>;
}

export const MainContainer = () => {
  return (
    <div className="main-container">
      <Routes />
    </div>
  );
};

export default MainContainer;
