import { gql } from 'apollo-boost';

export const GET_MERCHANTS = gql`
	query getMerchants {
		merchants {
			id
			name
			login
			password
			phone
			homePageUrl
			status
			limitAccess
			legacyId
			groupsPurchased
			comments
			feedLimit
			featuredLimit
			email
			defaultTrackingCode
			poweredByDynalogLogo
			createdOn
		}
	}
`;

export const GET_GROUPS = gql`
	query getGroups($merchantId: BigInt!) {
		catalogGroups(condition: { merchantId: $merchantId }) {
			id
			name
			status
		}
	}
`;

export const GET_CATALOG = gql`
	query getCatalog($id: BigInt!) {
		catalog(id: $id) {
			name
			shortName
			description
			contactEmail
			contactPhone
		}
	}
`;

export const GET_PDF_PAGES = gql`
	query getPdfPages($catalogId: BigInt!) {
		pdfCatalogPages(condition: { catalogId: $catalogId }, orderBy: PAGE_NUM_ASC) {
			id
			isDoubleSpread
			pageNum
			imgUrl: srcMedium
			catalogId
		}
	}
`;

export const GET_CATALOG_PAGES = gql`
	query getPages($catalogId: BigInt!) {
		dynCatalogPageOrders(condition: { catalogId: $catalogId }, orderBy: PAGE_NUM_ASC) {
			id
			imgUrl: pageImageUrl
			productCount
			layout
			pageNum
			pageType
		}
	}
`;

export const GET_CATALOGS_GROUPS = gql`
	query getCatalogsInGroups($catalogGroupId: BigInt!) {
		catalogsInGroups(
			condition: { catalogGroupId: $catalogGroupId }
			orderBy: DATE_MODIFIED_DESC
			offset: 0
			first: 5
		) {
			activeVersion
			catalogId
			dateModified
		}
	}
`;

export const GET_CATALOG_COVER = gql`
	query getCatalogCover($catalogId: Int!) {
		imagesCovers(condition: { typeId: $catalogId, type: "catalog" }) {
			srcMedium
			srcThumb
			src
		}
	}
`;
