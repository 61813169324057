import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_CATALOG } from "../../models/merchant/mutations";
import { Confirm } from "semantic-ui-react";

export function ArchiveCatalog({ catalogoid, user, setUser }) {
  const [prompt, setprompt] = useState(false);
  const [updateCatalogMutation] = useMutation(UPDATE_CATALOG);

  const archiveCatalog = () => {
    updateCatalogMutation({
      variables: {
        oid: catalogoid,
        catalog: {
          active: false,
        },
      },
    })
      .then(({ data }) => {
        setUser({
          ...user,
          userChanged: true,
          catalog: null,
          version: null,
          activeAccount: null,
          activeCatalog: null,
          activeVersion: null,
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <div onClick={() => setprompt(true)} className="right ui icon button red">
        <i className="warning icon"></i> Archive
      </div>
      <Confirm
        open={prompt}
        content="This Catalog Will Be Archived. You will not be able to access it without contacting Customer Support. Are you Sure?"
        cancelButton="NO"
        confirmButton="Archive"
        onConfirm={() => archiveCatalog()}
        onCancel={() => setprompt(false)}
      />
    </>
  );
}
