import React, { useState, useEffect, useCallback } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { sortBy, orderBy, isEmpty } from "lodash";
import {
  Image,
  Button,
  Grid,
  Dimmer,
  Loader,
  Segment,
  Header,
} from "semantic-ui-react";
import defaultImage from "../../../assets/img/page.png";
import axios from "axios";
import DropZone from "./dropZone";
import "./manage.css";
import { myConfig } from "../../../config";
import UploadS3 from "../../TopMenu/uploads3";
import awsConfig from "../../../aws-exports";

const uuidv1 = require("uuid/v1");

const CatalogPageView = ({ pages, setPages, editPage, onDelete }) => {
  const [loading, setLoading] = useState(false);

  function createPages(response) {
    if (Array.isArray(response.data.pages)) {
      setPages((pages) => {
        console.log("response.data.pages", response.data.pages);
        const npages = orderBy(response.data.pages, "pagenum").map((page) => {
          return {
            ...page,
            oid: page.oid || uuidv1(),
            pageType: "lifestyle",
            name: pages.length + page.pagenum,
            imageUrl: `${page.path}`,
          };
        }); //Jimmy Homework
        return [...pages, ...npages];
      });
    } else {
      const page = response.data;
      const newpage = {
        ...page,
        oid: uuidv1(),
        name: pages.length + 1,
        pageType: "lifestyle",
        imageUrl: `${page.path}`,
      };
      setPages((pages) => [...pages, newpage]);
    }
  }
  const onDrop = useCallback((acceptedFiles) => {
    if (!isEmpty(acceptedFiles)) {
      acceptedFiles.forEach(async (file) => {
        const fileType = file.type;

        switch (fileType) {
          case "application/pdf":
            await handleFile(file, "pdf");
            break;
          case "application/x-zip-compressed":
            await handleFile(file, "zip");
            break;
          case "application/zip":
            await handleFile(file, "zip");
            break;
          default:
            await handleFile(file, "single", `/${file.name}`);
          //handleImages(acceptedFiles);
        }
      });
    }
  }, []);

  const handleFile = (file, action, name = "") => {
    const reader = new FileReader();
    reader.onload = async () => {
      setLoading(true);
      const fileAsBinaryString = reader.result;
      await axios
        .post(
          `${myConfig.IMAGE_API_URL}/${action}/${uuidv1()}${name}`,
          fileAsBinaryString
        )
        .then(function (response) {
          //	console.log('RESponse ', response.data);
          createPages(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      setLoading(false);
    };
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");

    reader.readAsArrayBuffer(file);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(pages, oldIndex, newIndex);
    //'new array ', newArray);
    setPages(newArray);
  };

  const deletePage = (position) => {
    const filteredItems = pages
      .slice(0, position)
      .concat(pages.slice(position + 1, pages.length));
    setPages(filteredItems);
  };

  const SortableItem = SortableElement((props) => {
    const { imageUrl, pageType } = props.value;
    let backgroundColor = "#CCC";
    return (
      <li
        key={props.position}
        className="dynPage"
        onClick={() => {
          editPage && editPage(props.position);
        }}
      >
        <Button
          circular
          className="deleteBtn"
          icon="trash"
          color="red"
          size="mini"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete ? onDelete(props.position) : deletePage(props.position);
          }}
        />
        {pageType === "products" ? (
          <div
            style={{
              backgroundColor: backgroundColor,
              width: "100%",
              height: "86%",
            }}
          />
        ) : (
          <Image
            src={
              !isEmpty(imageUrl) && imageUrl != "/"
                ? `https://cdn.catalogs.com/s/medium${imageUrl}`
                : defaultImage
            }
            size="small"
            bordered
          />
        )}

        <div className="pageNumber">
          {props.position === 0 ? "cover" : props.position}
        </div>
      </li>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    //console.log('items...', items);
    return (
      <ul className="pagesWrapper">
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              value={value}
              position={index}
            />
          );
        })}
      </ul>
    );
  });
  const processLargeFile = (uploadState) => {
    //console.log('uploadState', uploadState);
    setLoading(true);
    var action = "ziplarge";
    if (uploadState.fileType === "application/pdf") action = "pdflarge";

    axios
      .post(`${myConfig.IMAGE_API_URL}/${action}`, {
        bucket: awsConfig.aws_user_files_s3_bucket,
        path: `public/${uploadState.fileName}`,
        saveFolder: uuidv1(),
      })
      .then(function (response) {
        //	console.log('RESponse ', response.data);
        createPages(response);
        setLoading(false);
      })
      .catch(function (error) {
        alert(error);
        setLoading(false);
      });
  };
  return (
    <div>
      <Dimmer.Dimmable as={Segment} dimmed={loading}>
        <Dimmer active={loading}>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
        <section className="container">
          <DropZone
            onDrop={onDrop}
            accept={
              "image/*,application/pdf,application/x-zip-compressed,application/zip"
            }
            multiple={true}
            maxSize={20000000}
            className="dropZone"
          />
        </section>
        <UploadS3 processFile={processLargeFile} />
        <SortableList
          distance={1}
          items={pages}
          onSortEnd={onSortEnd}
          axis="xy"
        />
      </Dimmer.Dimmable>
    </div>
  );
};

export default CatalogPageView;
