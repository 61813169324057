import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import DynalogSettingsForm from '../../Merchant/dynalogSettingsForm';
import { CREATE_CATALOG, CREATE_CATALOG_VERSION, UPDATE_CATALOG } from '../../../models/merchant/mutations';

const uuidv1 = require('uuid/v1');

const FinishStep = ({ catalog, version, mode, ...props }) => {
	const user = props.user;

	const [loading, setLoading] = useState(false);

	const [createCatalogMutation] = useMutation(CREATE_CATALOG);
	const [createVersionMutation] = useMutation(CREATE_CATALOG_VERSION);
	const [updateCatalogMutation] = useMutation(UPDATE_CATALOG);

	const onSubmit = (data) => {
		const catalogId = uuidv1();
		const versionId = uuidv1();

		setLoading(true);
		const {
			title,
			description,
			googleTrackingUa,
			googleUtmCampaign,
			googleUtmMedium,
			googleUtmSource,
			leadFormType,
			openiniframe,
			leadOptions,
			leadComments,
			leadEmail,
			leadPhone,
			leadHeader,
			leadModes
		} = data;
		const versionOptions = !isEmpty(version.options) ? version.options : {};

		const versionPages = getPagesArray();
		const versionPayload = {
			oid: versionId,
			coverUrl: versionPages[0].imageUrl,
			active: true,
			catalog: catalog.oid,
			order: getPagesOrder(versionPages),
			name: title,
			description,
			options: {
				...versionOptions,
				leadHeader,
				leadModes,
				leadFormType,
				openiniframe: openiniframe ? 1 : 0,
				leadEmail,
				leadPhone,
				leadComments,
				leadOptions
			},
			pagesUsingOid: {
				create: versionPages
			}
		};

		if (mode === 'version') {
			createVersionMutation({
				variables: {
					version: versionPayload
				}
			})
				.then(({ data }) => {
					//console.log('createdDynalogVersionMutation: ', data);
					updateCatalogMutation({
						variables: {
							oid: catalog.oid,
							catalog: {
								options: {
									...catalog.options,
									googleTrackingUa,
									googleUtmCampaign,
									googleUtmMedium,
									googleUtmSource
								},
								activeVersion: data.createVersion.version.oid
							}
						}
					})
						.then(({ data }) => {
							//console.log('updatedDynalogMutation: ', data);
							const catalog = data.updateCatalog.catalog;
							setLoading(false);
							props.goNext(catalog);
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		} else if (mode === 'catalog') {
			const catalogPayload = {
				oid: catalogId,
				account: user.activeAccount.oid,
				name: title,
				description,
				isDigital: true,
				options: {
					googleTrackingUa,
					googleUtmCampaign,
					googleUtmMedium,
					googleUtmSource
				},
				merchantId: data.merchantId,
				acive: true
			};

			createCatalogMutation({
				variables: {
					catalog: catalogPayload
				}
			})
				.then(({ data }) => {
					//console.log('createdDynalogMutation: ', data);
					return data;
				})
				.then((result) => {
					//console.log('calling createDynalogVersion: ', result);
					const payload = {
						...versionPayload,
						catalog: catalogPayload.oid
					};
					//console.log('createDynalogVersionMutation ', dynalogVersionPayload);
					createVersionMutation({
						variables: {
							version: payload
						}
					})
						.then(({ data }) => {
							//console.log('createdDynalogVersionMutation: ', data);
							updateCatalogMutation({
								variables: {
									oid: catalogId,
									catalog: {
										activeVersion: versionId
									}
								}
							})
								.then(({ data }) => {
									//console.log('updatedDynalogMutation: ', data);
									const catalog = data.updateCatalog.catalog;
									setLoading(false);
									props.goNext(catalog);
								})
								.catch((e) => console.log(e));
						})
						.catch((e) => console.log(e));
				})
				.catch((e) => console.log(e));
		}

		setLoading(false);
	};

	const getPagesArray = () => {
		const pages = props.pages || [];
		return pages.map((page, index) => {
			return {
				oid: uuidv1(),
				imageUrl: page.path,
				pageType: 'lifestyle'
			};
		});
	};

	const getPagesOrder = (pages) => {
		return pages.map((page, index) => {
			return {
				oid: page.oid,
				num: index
			};
		});
	};

	const merchantOptions = () => {
		const user = props.user;
		const merchants = user.merchants || [];
		return merchants.map((merchant) => {
			return {
				ID: merchant.id,
				Name: merchant.name
			};
		});
	};
	if (loading)
		return (
			<Dimmer active>
				<Loader indeterminate>Working on your dynalog...</Loader>
			</Dimmer>
		);
	return (
		<div>
			<h4>Dynalog Information:</h4>
			<DynalogSettingsForm catalog={catalog} version={version} onSubmit={onSubmit} />
		</div>
	);
};

export default FinishStep;
