export default [
	{
		value: 'Title',
		id: 'title',
		text: 'Title',
		required: true
	},
	{
		value: 'Vin',
		id: 'SKU',
		text: 'SKU',
		required: true
	},
	{
		value: 'MarkettextPrice',
		id: 'Price',
		text: 'Price',
		required: true
	},
	{
		value: 'InternetPrice',
		id: 'SalePrice',
		text: 'SalePrice',
		required: true
	},
	{
		value: 'ImageURL',
		id: 'ImageUrl',
		text: 'ImageUrl',
		required: true
	},
	{
		value: 'ProductPageURL',
		id: 'ProductUrl',
		text: 'ProductUrl',
		required: true
	},
	{
		value: 'Id',
		id: 'id',
		text: 'ID',
		required: false
	},
	{
		value: 'Category',
		id: 'Category',
		text: 'Category',
		required: false
	},
	{
		value: 'Subcategory',
		id: 'Subcategory',
		text: 'Subcategory',
		required: false
	},
	{
		value: 'ExteriorColor',
		id: 'Color',
		text: 'Color',
		required: false
	},
	{
		value: 'Make',
		id: 'Brand',
		text: 'Brand',
		required: false
	},
	{
		value: 'Model',
		id: 'Tags',
		text: 'Tags',
		required: false
	},	
	{
		value: 'Images',
		id: 'AdditionalImages',
		text: 'AdditionalImages',
		required: false
	},
];

var mapped =
{
	"MSRPPrice": "MSRPPrice",
	"SKUStock": "SKUStock",
	"Year": "Year",
	"Model": "Model",
	"Trim": "Trim",
	"BodyType": "BodyType",
	"DriveTrain": "DriveTrain",
	"EngineSize": "EngineSize",
	"Transmission": "Transmission",
	"InteriorColor": "InteriorColor",
	"MPG": "MPG",
	"Mileage": "Mileage",
}