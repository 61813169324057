import React from 'react';
import { withUser } from '../../context/user';
import ListFeeds from '../../components/Ingestor/list';

const Ingestor = (props) => { 

	return (
			<div className="content">
				<div className="dynalogWrapper">
					<div className="dynalogFrameWrapper">
						<h2> Feed Imports </h2>
						<ListFeeds user={props.user} />
					</div>
				</div>
			</div>
	);
};

export default withUser(Ingestor);
