import React, { useContext } from 'react';
import { withUser } from '../../context/user';
//import client from '../../models/catalogs/client';
import ListMerchants from '../../components/Merchant/list'

const Merchant = (props) => {
	
	return (
		<div className="content">
			<div className="dynalogWrapper">
				<div className="dynalogFrameWrapper">
					<h2> Client List </h2>
					<ListMerchants />
				</div>
			</div>
		</div>
	);
};

export default withUser(Merchant);
