import React, { useContext } from 'react';
import { withUser } from '../../context/user';
import LeadsGrid from '../../components/Leads/leadsGrid';

const Leads = (props) => {
	
	if(!props.user.activeAccount) return 'loading'

	return (
		<div className="content">
			<div className="dynalogWrapper">
				<div className="dynalogFrameWrapper">
					<h2> Leads </h2>
					<LeadsGrid account={props.user.activeAccount.oid} />
				</div>
			</div>
		</div>
	);
};

export default withUser(Leads);
