import React, { useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const DropZone = (props) => {
	const maxSize = props.maxSize || 1048576;
	const accept = props.accept || 'image/*';
	const multiple = props.multiple || false;

	const baseStyle = {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '20px',
		borderWidth: 2,
		borderRadius: 2,
		borderColor: '#eeeeee',
		borderStyle: 'dashed',
		backgroundColor: '#fafafa',
		color: '#bdbdbd',
		outline: 'none',
		transition: 'border .24s ease-in-out'
	};

	const activeStyle = {
		borderColor: '#2196f3'
	};

	const acceptStyle = {
		borderColor: '#00e676'
	};

	const rejectStyle = {
		borderColor: '#ff1744'
	};

	const {
		isDragActive,
		getRootProps,
		getInputProps,
		isDragAccept,
		isDragReject,
		acceptedFiles,
		rejectedFiles,
		open
	} = useDropzone({
		onDrop: props.onDrop,
		multiple: multiple,
		accept: accept,
		minSize: 0,
		maxSize
	});
	const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {})
		}),
		[isDragActive, isDragReject]
	);
	return (
		<div {...getRootProps({ style })}>
			{props.image && props.image}
			<input {...getInputProps()} />
			{!isDragActive && (
				<div>
					<p>{props.title ? props.title : `Drag a PDF or Image File`}</p>
					<button type="button" onClick={() => open}>
						Open File Dialog
					</button>
				</div>
			)}
			{isDragActive && !isDragReject && 'Convert File!'}
			{isDragReject && 'File type not accepted, sorry!'}
			{isFileTooLarge && <div className="text-danger mt-2">File is too large.</div>}
		</div>
	);
};

export default DropZone;
