import React, { useState, useCallback } from 'react';
import DropZone from './CreateForm/dropZone';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { myConfig } from '../../config';
import {Input, Icon } from 'semantic-ui-react';
const uuidv1 = require('uuid/v1');

const ImageSrcEdit = (props) => {
	//console.log('Image Source Props', props);
	const [ loading, setLoading ] = useState(false);
	const [ srcImage, setSrcImage ] = useState(props.data.value);
	const [value, setvalue] = useState();

	const folder = !isEmpty(props.data.data) ? props.data.data.oid : uuidv1();
	
	const onDrop = useCallback((acceptedFiles) => {
		if (!isEmpty(acceptedFiles)) {
			acceptedFiles.forEach(async (file) => {
				const fileType = file.type;

				await handleFile(file, 'single', `/${file.name}`);
			});
		}
	}, []);

	const handleFile = (file, action, name = '') => {
		const reader = new FileReader();
		reader.onload = async () => {
			setLoading(true);
			const fileAsBinaryString = reader.result;
			await axios({
				method: 'post',
				url: `${myConfig.IMAGE_API_URL}/${action}/${folder}${name}`,
				data: fileAsBinaryString})
				.then(function(response) {
					const image = response.data;
					setSrcImage(image.path);
					props.data.setValue(image.path);
				})
				.catch(function(error) {
					console.log(error);
				});
			setLoading(false);
		};
		reader.onabort = () => console.log('file reading was aborted');
		reader.onerror = () => console.log('file reading has failed');

		reader.readAsArrayBuffer(file);
	};

	const importImage = async () =>{
		const key = 'image-1/product/'+uuidv1()+'.jpg';

		let body = {
			payload:{
				url:value,
				path:key
			}
		};

		await axios({
			method: 'post',
			url: `${myConfig.IMAGE_API_URL}/image/save`,
			data: body})
			.then(function(response) {
				console.log(response);
				setTimeout(() => {
					setSrcImage('/'+key);
					props.data.setValue('/'+key);
				}, 1000);
			})
			.catch(function(error) {
				console.log(error);
			});

		setLoading(false);
	}
	return (
		<section className="container fluid" style={{display: 'flex'}}>
			{srcImage?
			<img src={`https://cdn.catalogs.com${srcImage}`} style={{
                width:'100px'
            }} />:
			<Icon size='massive' name="image outline" />
			}
			<div className="fluid">
				<Input fluid
				action= {{content:'Import', onClick: importImage}}
				onChange = {(e, valueChangedEventArg) => (setvalue(valueChangedEventArg.value))}
//				icon={<Icon name='search' inverted circular link />}
				placeholder='Enter URL to Import Image..'
				/>
            	<DropZone onDrop={onDrop} accept={'image/*'} multiple={false} maxSize={5000000} className="dropZone" />
			</div>
		</section>
	);
};

export default ImageSrcEdit;
