import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import DataGrid, {
  Paging,
  MasterDetail,
  Column,
  Editing,
  SearchPanel,
  FilterRow,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import MerchantGroupsView from "./merchantGroupsView";
import { GET_MERCHANTS } from "../../models/merchant/queries";
import { GET_CATALOG_ACCOUNTS } from "../../models/catalogs/queries";
import pictureCellRender from "../ColumnImageEdit";
import ImageSrcEdit from "../Dynalog/imageSrcEdit";
import { UPDATE_ACCOUNT } from "../../models/merchant/mutations";
const ListMerchants = (props) => {
  const { loading, error, data } = useQuery(GET_CATALOG_ACCOUNTS);
  const [updateAccount] = useMutation(UPDATE_ACCOUNT);
  // const merchantList = () => {
  // 	const merchants = data.accounts || [];
  // 	return merchants.map((merchant) => {
  // 		return {
  // 			ID: merchant.legacyId,
  // 			name: merchant.name,
  // 			email: merchant.email,
  // 			catalogs: merchant.catalogs || []
  // 		};
  // 	});
  // };

  const onRowUpdated = (event) => {
    //console.log('onRowUpdated', event);

    const { oid, logo, name, email, ...account } = event.data;

    const payload = {
      oid,
      logo,
      email,
      name,
    };
    updateAccount({
      variables: {
        oid: oid,
        account: payload,
      },
    })
      //.then((result) => refetch())
      .catch((e) => console.log(e));
  };

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error</p>;
  if (!data) return <p>NO DATA</p>;
  return (
    <div className="content">
      <DataGrid
        columnHidingEnabled={true}
        dataSource={data.accounts}
        keyExpr={"oid"}
        showBorders={true}
        remoteOperations={true}
        onRowUpdated={onRowUpdated}
      >
        <SearchPanel visible={true} placeholder={"Search Accounts..."} />

        <FilterRow visible={true} />
        <Paging enabled={true} />
        <Editing mode="popup" allowUpdating={true} />
        <Column
          dataField="logo"
          width={100}
          cellRender={pictureCellRender}
          editCellComponent={ImageSrcEdit}
        />
        <Column dataField="legacyId" allowEditing={false} />
        <Column dataField="name" />
        <Column dataField="email" />
        <MasterDetail enabled={true} component={MerchantGroupsView} />
      </DataGrid>
    </div>
  );
};

export default ListMerchants;
