import React, { useState, useCallback } from 'react';
import DropZone from './Dynalog/CreateForm/dropZone';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { myConfig } from '../config';
import {Input, Icon } from 'semantic-ui-react';
const uuidv1 = require('uuid/v1');

const ImageEdit = ({srcImage, setSrcImage, key}) => {
	//console.log('Image Source Props', props);
	const [ loading, setLoading ] = useState(false);
	const [value, setvalue] = useState();

	const folder = key || uuidv1();
	
	const onDrop = useCallback((acceptedFiles) => {
		if (!isEmpty(acceptedFiles)) {
			acceptedFiles.forEach(async (file) => {
				const fileType = file.type;
				await handleFile(file, 'single', `/${file.name}`);
			});
		}
	}, []);

	const handleFile = (file, action, name = '') => {
		const reader = new FileReader();
		reader.onload = async () => {
			setLoading(true);
			const fileAsBinaryString = reader.result;
			await axios({
				method: 'post',
				url: `${myConfig.IMAGE_API_URL}/${action}/${folder}${name}`,
				data: fileAsBinaryString})
				.then(function(response) {
					const image = response.data;
					setSrcImage(image.path);					
				})
				.catch(function(error) {
					console.log(error);
				});
			setLoading(false);
		};
		reader.onabort = () => console.log('file reading was aborted');
		reader.onerror = () => console.log('file reading has failed');

		reader.readAsArrayBuffer(file);
	};

	const importImage = async () =>{
		const key = 'image-1/product/'+uuidv1()+'.jpg';

		let body = {
			payload:{
				url:value,
				path:key
			}
		};

		await axios({
			method: 'post',
			url: `${myConfig.IMAGE_API_URL}/image/save`,
			data: body})
			.then(function(response) {
				console.log(response);
				setTimeout(() => {
					setSrcImage('/'+key);
				}, 1000);
			})
			.catch(function(error) {
				console.log(error);
			});

		setLoading(false);
	}
	return (
		<section className="container fluid" style={{display: 'flex'}}>
			<CustomImage src={srcImage}/>
			<div className="fluid">
			<Input 	action= {{content:'Import', onClick: importImage}}
			onChange = {(e, valueChangedEventArg) => (setvalue(valueChangedEventArg.value))}
//				icon={<Icon name='search' inverted circular link />}
			placeholder='Enter URL to Import Image..'
			/>
			<DropZone onDrop={onDrop} accept={'image/*'} multiple={false} maxSize={5000000} className="dropZone" />
			</div>
		</section>
	);
};

const CustomImage = ({src}) =>{
    if (src)
        return(
         <img style={{maxWidth:'30%', maxHeight:'100%'}} src={src.includes("http") ? src : `https://cdn.catalogs.com${src}`} 
  />)
        return (<Icon size='massive' name="image outline" />)
}

export default ImageEdit;
