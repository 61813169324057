import React from "react";
import Product1 from "./Product1";
import Product2 from "./Product2";
import Product3 from "./Product3";
import Product4 from "./Product4";
import Product5 from "./Product5";
import Product6 from "./Product6";
import Product8 from "./Product8";
//import "./index.scss";


function ProductTemplate(props) {
  

  const nProducts = props.products || [];
  const totalProducts = nProducts.length || 0;

  function plist() {
    switch (totalProducts) {
      case 1:
        return <Product1 products={nProducts} />;
        break;
      case 2:
        return <Product2 products={nProducts} />;
        break;
      case 3:
        return <Product3 products={nProducts} />;
        break;
      case 4:
        return <Product4 products={nProducts} />;
        break;
      case 5:
        return <Product5 products={nProducts} />;
        break;
      case 6:
        return <Product6 products={nProducts} />;
        break;
      // case 7:
      // case 8:
      default:
        return <Product8 products={nProducts} />;
    }
  }

  return (plist());
}

export default ProductTemplate;
