import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Button, Form, Input, Dropdown, Checkbox, TextArea } from 'semantic-ui-react';
import { RHFInput } from 'react-hook-form-input';

const VersionSettingForm = ({ catalog, version, ...props }) => {
	const versionOptions = !isEmpty(version.options) ? version.options : {};
	const { register, errors, handleSubmit, setValue, control } = useForm();

	const leadModes = [
		{
			value: 0,
			text: 'OFF(Default)'
		},
		{
			value: 1,
			text: 'On ALL products'
		},
		{
			value: 2,
			text: "On MISSING product URL's"
		}
	];
	const leadFormOptions = [
		/*
		{
			value: 0,
			text: 'Short Form'
		},
		*/
		{
			value: 1,
			text: 'Lead Form'
		},
		{
			value: 2,
			text: 'Call Form'
		}
	];
	const priceOptions = [
		{
			value: 0,
			text: 'Hide'
		},
		{
			value: 1,
			text: 'Show Normal (Default)'
		},
		/*
		{
			value: 2,
			text: 'If Price empty then use Sale Price'
		}	
		*/
	]

	return (
		<Form onSubmit={handleSubmit(props.onSubmit)}>

			<Form.Group widths="equal">
				<Form.Field>
					<Controller
						as={<Checkbox label="Force Links to Open in New Window" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="forceNewWindowLinkout"
						defaultChecked={versionOptions.forceNewWindowLinkout}
					/>
				</Form.Field>
				<Form.Field>
					<Controller
						as={<Checkbox label="Disable Categories" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="disableCategories"
						defaultChecked={versionOptions.disableCategories}
					/>
				</Form.Field>
				<Form.Field>
					<Controller
						as={<Checkbox label="Hide Page Number" />}
						control={control}
						onChange={([e, data]) => {
							return data.checked;
						}}
						name="hidePageNumber"
						defaultChecked={versionOptions.hidePageNumber}
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Options</label>
					<Controller
						as={<Dropdown fluid selection options={leadModes} />}
						defaultValue={versionOptions.leadOptions}
						control={control}
						name="leadOptions"
						onChange={([e, data]) => {
							return data.value;
						}}
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Desktop</label>
					<Controller
						as={<Dropdown fluid selection options={leadFormOptions} />}
						defaultValue={versionOptions.leadFormType}
						control={control}
						name="leadFormType"
						onChange={([e, data]) => {
							return data.value;
						}}
					/>
				</Form.Field>
			</Form.Group>
			<Form.Group widths={2}>
				<Form.Field>
					<label>Lead Form Message</label>
					<RHFInput
						as={<TextArea />}
						register={register}
						defaultValue={versionOptions.leadHeader}
						setValue={setValue}
						name="leadHeader"
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Form Comments</label>
					<RHFInput
						as={<TextArea />}
						register={register}
						defaultValue={versionOptions.leadComments}
						setValue={setValue}
						name="leadComments"
					/>
				</Form.Field>
			</Form.Group>
			<Form.Group widths={2}>
				<Form.Field>
					<label>Lead Form Email</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={versionOptions.leadEmail}
						setValue={setValue}
						name="leadEmail"
					/>
				</Form.Field>
				<Form.Field>
					<label>Lead Form Phone</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={versionOptions.leadPhone}
						setValue={setValue}
						name="leadPhone"
					/>
				</Form.Field>
			</Form.Group>
			<Form.Group widths={2}>
				<Form.Field>
					<label>Category Custom Label</label>
					<RHFInput
						as={<Input />}
						register={register}
						defaultValue={versionOptions.categoryBarLabel}
						setValue={setValue}
						name="categoryBarLabel"
					/>
				</Form.Field>
				<Form.Field>
					<label>Price Options</label>
					<Controller
						as={<Dropdown fluid selection options={priceOptions} />}
						defaultValue={versionOptions.priceOption}
						control={control}
						name="priceOption"
						onChange={([e, data]) => {
							return data.value;
						}}
					/>
				</Form.Field>
			</Form.Group>

			<Button primary type="submit">
				SAVE
			</Button>
		</Form>
	);
};

export default VersionSettingForm;
