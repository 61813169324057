import React, { useState, useEffect } from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import MappingForm from './mappingForm';
import { isEmpty} from 'lodash';
import automotiveOptions from './marketcheck_automotive';
import automotiveMapping from './marketcheck_mapping';
import './mapping.css';

const Mapping = (props) => {
	const initialMapping = !isEmpty(props.mapping) ? props.mapping : automotiveMapping;

	const [ popup, setPopup ] = useState(false);

	const saveMapping = (data) => {
		const mapJson = JSON.stringify(data);
		props.onSave(mapJson);
		setPopup(false);
	};
	return (
		<div>
			<Button fluid primary onClick={() => setPopup(true)}>
				Override Mapping
			</Button>
			<Modal closeIcon open={popup} onClose={() => setPopup(false)}>
				<Header icon="browser" content="Map Feed" />
				<Modal.Content scrolling>
					<MappingForm
						mapping={initialMapping}
						options={automotiveOptions}
						title="Map provider fields"
						onSave={(v) => saveMapping(v)}
					/>
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default Mapping;
