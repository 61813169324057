import React, { Component } from "react";
import styled, { css } from "styled-components";

function Product5(props) {
  return (
    <>
      <Rect>{props.products[0]}</Rect>
      <Rect73>{props.products[1]}</Rect73>
      <Rect74>{props.products[2]}</Rect74>
      <Rect75>{props.products[3]}</Rect75>
      <Rect76>{props.products[4]}</Rect76>
    </>
  );
}

const Rect = styled.div`
  top: 38px;
  left: 12px;
  width: 190px;
  height: 190px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect73 = styled.div`
  top: 38px;
  left: 214px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect74 = styled.div`
  top: 174px;
  left: 214px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect75 = styled.div`
  top: 248px;
  left: 12px;
  width: 190px;
  height: 190px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect76 = styled.div`
  top: 313px;
  left: 214px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default Product5;
