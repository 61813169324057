import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DataGrid, {
	Export,
	Scrolling,
    SearchPanel,
    Column
} from 'devextreme-react/data-grid';
import { GET_LEADS_BY_ACCOUNT } from '../../models/catalogs/queries';
import { UPDATE_LEAD_STATUS } from '../../models/catalogs/mutations';
import { CommonSeriesSettingsSelectionStyle } from 'devextreme-react/chart';
import { CheckBox } from 'devextreme-react';

const LeadsGrid = (props) => {
    
	const { loading: loadingLeads, error: leadsError, data, refetch } = useQuery(GET_LEADS_BY_ACCOUNT, {
		variables: { account: props.account }, 
		fetchPolicy: "network-only"
    });
  
	
	const [updateLead, {loading, error}] = useMutation(UPDATE_LEAD_STATUS)
	const [leadFilter, setLeadFilter] = useState("");
	const [showDetailColumns, setShowDetailColumns] = useState(true);
	const [combinedAddress, setCombinedAddress] = useState(false);
	const [combinedName, setCombinedName] = useState(false);
	const [abbreviateStates, setAbbreviateStates] = useState(true);
	if (loadingLeads) return <p>Loading ...</p>;
	if (leadsError) return <p>ERROR: {leadsError.message}</p>;
    if (!data) return <p>Not found</p>;
    

	const listLeads = ()=> {
			if (data.account){
				var leads = [];
				data.account.catalogsByAccount.forEach((cat)=> {
						if (cat.active){
						cat.subscriptionsByCatalog.forEach((lead)=>{
							const leadProfile = lead?.profileByCustomer;
							const formattedLead = {
								oid: lead.oid,
								catalog: cat.title,
								date: new Date(lead.date),
								firstName: leadProfile?.firstName,
								lastName: leadProfile?.lastName,
								fullName: leadProfile?.firstName + " " + leadProfile?.lastName,
								email: leadProfile?.email,
								phone: leadProfile?.phone,
								street: leadProfile?.street,
								city: leadProfile?.city,
								state: convertStates(leadProfile?.state,true),
								stateFull: convertStates(leadProfile?.state,false),
								zip: leadProfile?.zipPostal,
								addressFull: ((leadProfile?.street || "N/A") + ", " + 
									(leadProfile?.city || "N/A") + ", " + 
									(convertStates(leadProfile?.state,false) || "N/A") + ", " + 
									(leadProfile?.zipPostal || "N/A")),
								address: ((leadProfile?.street || "N/A") + ", " + 
									(leadProfile?.city || "N/A") + ", " + 
									(convertStates(leadProfile?.state,true) || "N/A") + ", " + 
									(leadProfile?.zipPostal || "N/A")),	
								exportStatus: lead.exportStatus ? (lead.exportStatus == "G" ? "Exported" : lead.exportStatus == "R" ? "New" : "N/A") : "N/A"
							}
							leads.push(formattedLead);
						})
					}
				})
				return leads;
		}
	}

	const dynalogCellRender = (data)=> {
			return data.leads.name
	}

	const updateLeads = (leads) =>{
		//console.log(leads);
		leads.forEach((l)=>{
			updateLead({variables: {oid: l.data.oid}}).then(()=>console.log("updated lead", l.data.email));
		})
	}

	const convertStates = (stateData,abbreviate) =>{
		const states = {
			'AL': 'Alabama',
			'AK': 'Alaska',
			'AS': 'American Samoa',
			'AZ': 'Arizona',
			'AR': 'Arkansas',
			'CA': 'California',
			'CO': 'Colorado',
			'CT': 'Connecticut',
			'DE': 'Delaware',
			'DC': 'District Of Columbia',
			'FM': 'Federated States Of Micronesia',
			'FL': 'Florida',
			'GA': 'Georgia',
			'GU': 'Guam',
			'HI': 'Hawaii',
			'ID': 'Idaho',
			'IL': 'Illinois',
			'IN': 'Indiana',
			'IA': 'Iowa',
			'KS': 'Kansas',
			'KY': 'Kentucky',
			'LA': 'Louisiana',
			'ME': 'Maine',
			'MH': 'Marshall Islands',
			'MD': 'Maryland',
			'MA': 'Massachusetts',
			'MI': 'Michigan',
			'MN': 'Minnesota',
			'MS': 'Mississippi',
			'MO': 'Missouri',
			'MT': 'Montana',
			'NE': 'Nebraska',
			'NV': 'Nevada',
			'NH': 'New Hampshire',
			'NJ': 'New Jersey',
			'NM': 'New Mexico',
			'NY': 'New York',
			'NC': 'North Carolina',
			'ND': 'North Dakota',
			'MP': 'Northern Mariana Islands',
			'OH': 'Ohio',
			'OK': 'Oklahoma',
			'OR': 'Oregon',
			'PW': 'Palau',
			'PA': 'Pennsylvania',
			'PR': 'Puerto Rico',
			'RI': 'Rhode Island',
			'SC': 'South Carolina',
			'SD': 'South Dakota',
			'TN': 'Tennessee',
			'TX': 'Texas',
			'UT': 'Utah',
			'VT': 'Vermont',
			'VI': 'Virgin Islands',
			'VA': 'Virginia',
			'WA': 'Washington',
			'WV': 'West Virginia',
			'WI': 'Wisconsin',
			'WY': 'Wyoming'
		};

		const reverseStates = {};
		for (const [abbreviation, fullName] of Object.entries(states)) {
				reverseStates[fullName.toLowerCase()] = abbreviation;
		}
		
		// stateData is expected to come in as explicitly an abbreviation or a fully written out state
		if (stateData != null){ // If state value is fully written and we want to abbreviate it
			if (stateData.length > 2){
				if (abbreviate){
					//console.log("converting full state " + stateData + " to " + reverseStates[stateData.toLowerCase()]);
					if (reverseStates.hasOwnProperty(stateData.trim().toLowerCase())){
						return reverseStates[stateData.trim().toLowerCase()];
					}
				}
				else {
					stateData = stateData.replace(/\w\S*/g, function(txt) { // Change data to Title Case if it isn't already
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				} 
			}
			else if (stateData.length == 2){ // If state value is an abbreviation and we want to write it out
				//console.log("converting abbreviation " + stateData + " to " + states[stateData.toUpperCase()]);
				if (!abbreviate){
					if (states.hasOwnProperty(stateData.trim().toUpperCase()))
						return states[stateData.trim().toUpperCase()];
				}
				else return stateData.trim().toUpperCase();
			}
		}
		// Else we are already in the correct format
		return stateData;
	
	}

	const colorRow = (row) => {
		const exportStatus = row.cells.find((c)=> c.column.dataField == "exportStatus");
		if (exportStatus && exportStatus.displayValue == "New"){
			return '#d9ead3';
		}
		else if (exportStatus && exportStatus.displayValue == "Exported"){
			return '#c9daf8';
		}
		else {
			return '#FFFFFF';
		}
	}
	return (
		<div className="leads-grid">
			<CheckBox text="New Leads Only" defaultValue={false} onValueChanged={(e)=> setLeadFilter(e.value ? "New" : "")}/>
			<CheckBox style={{"padding":"0px 10px"}}text="Show Details" defaultValue={true} onValueChanged={(e)=> setShowDetailColumns(e.value)}/>
			<CheckBox style={{"visibility": showDetailColumns ? "visible" : "hidden", "padding":"0px 10px"}} text="Use State Abbreviations" defaultValue={true} onValueChanged={(e)=> setAbbreviateStates(e.value)}/>
			<CheckBox style={{"visibility": showDetailColumns ? "visible" : "hidden", "padding":"0px 10px"}}text="Combine Address" defaultValue={false} onValueChanged={(e)=> setCombinedAddress(e.value)}/>
			<CheckBox style={{"visibility": showDetailColumns ? "visible" : "hidden", "padding":"0px 10px"}}text="Combine Name" defaultValue={false} onValueChanged={(e)=> setCombinedName(e.value)}/>

			<DataGrid
				columnHidingEnabled={true}
				style={{ maxHeight: '500px' }}
				focusedRowEnabled={true}
				dataSource={listLeads()}
				keyExpr={'oid'}
				showBorders={true}
				columnHidingEnabled={true}
				headerFilter={{visible: true}}
				filterRow={{visible: true}}
				paging={{pageSize: 500}}
				scrolling={{mode: 'standard'}}
				onExported={(e) => updateLeads(e.component.getVisibleRows())}
				onRowPrepared={(e) => e.rowElement.style.backgroundColor = colorRow(e)}
			>
				<Export fileName='leads' enabled={true} allowExportSelectedData={false} />
				<Scrolling mode="virtual" />
				<SearchPanel visible={true} placeholder={'Search...'} />
                <Column dataField="oid" visible={false} />
								<Column dataField="exportStatus" width={120} filterValue={leadFilter}/>
								<Column dataField="date" width={100} sortOrder={"desc"}/>
								<Column dataField="email" width={150}/>
                <Column dataField="firstName" width={150} visible={showDetailColumns && !combinedName}/>
                <Column dataField="lastName" width={150} visible={showDetailColumns  && !combinedName}/>
								<Column dataField="fullName" width={150} visible={showDetailColumns  && combinedName}/>
								<Column dataField="phone" width={100} visible={showDetailColumns}/>
								<Column dataField="address" visible={showDetailColumns && combinedAddress && abbreviateStates}/>
								<Column dataField="addressFull" visible={showDetailColumns && combinedAddress && !abbreviateStates}/>
								<Column dataField="street" visible={showDetailColumns && !combinedAddress}/>
								<Column dataField="city" width={100} visible={showDetailColumns && !combinedAddress}/>
								<Column dataField="state" width={100} visible={showDetailColumns && !combinedAddress && abbreviateStates}/>
								<Column dataField="stateFull"width={100} visible={showDetailColumns && !combinedAddress && !abbreviateStates}/>
								<Column dataField="zip" width={100} visible={showDetailColumns && !combinedAddress}/>
                <Column dataField="catalog" width={150}/>
			</DataGrid>
			
		</div>
		
	);
};

export default LeadsGrid;
