import { gql } from "apollo-boost";

export const UPDATE_CATALOG_PAGE = gql`
  mutation UpdatePage($id: UUID!, $payload: PagePatch!) {
    updatePage(input: { patch: $payload, oid: $id }) {
      page {
        oid
        imageSource
        imageUrl
        pageType
        options
      }
    }
  }
`;
/**
 * CatalogInput
 * oid: UUID!
 * account: UUID
 * name: String
 * merchantId: BigInt
 */
export const CREATE_CATALOG = gql`
  mutation CreateCatalog($catalog: CatalogInput!) {
    createCatalog(input: { catalog: $catalog }) {
      catalog {
        oid
        id
        name
        title
        cover
        description
        activeVersion: versionByActiveVersion {
          oid
          id
          coverUrl
        }
        versions: versionsByCatalog(
          orderBy: VERSION_NO_DESC
          offset: 0
          first: 10
        ) {
          oid
          id
          name
          coverUrl
          versionNo
        }
      }
    }
  }
`;

export const UPDATE_CATALOG = gql`
  mutation UpdateCatalog($catalog: CatalogPatch!, $oid: UUID!) {
    updateCatalog(input: { patch: $catalog, oid: $oid }) {
      catalog {
        oid
        id
        name
        title
        options
        cover
        description
        active
        activeVersion: versionByActiveVersion {
          oid
          id
          coverUrl
        }
        versions: versionsByCatalog(
          orderBy: VERSION_NO_DESC
          offset: 0
          first: 10
        ) {
          oid
          id
          name
          coverUrl
          versionNo
          options
        }
      }
    }
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($account: AccountPatch!, $oid: UUID!) {
    updateAccount(input: { patch: $account, oid: $oid }) {
      account {
        oid
        name
        email
        legacyId
        active
        logo
        catalogs: catalogsByAccount {
          oid
          id
          name
          title
          status
          cover
          active
          isDigital
          activeVersion: versionByActiveVersion {
            oid
            id
            name
            coverUrl
          }
          versions: versionsByCatalog(
            orderBy: VERSION_NO_DESC
            offset: 0
            first: 10
          ) {
            oid
            id
            name
            coverUrl
            versionNo
          }
        }
      }
    }
  }
`;
export const DELETE_CATALOG = gql`
  mutation DeleteCatalog($oid: UUID!) {
    deleteCatalog(input: { oid: $oid }) {
      catalog {
        oid
        id
      }
    }
  }
`;
/**
 * VersionInput
 * oid: UUID!
 * catalog: UUID
 * name: String
 *
 */
export const CREATE_CATALOG_VERSION = gql`
  mutation CreateVersion($version: VersionInput!) {
    createVersion(input: { version: $version }) {
      version {
        id
        oid
        name
        options
      }
      catalog: catalogByCatalog {
        id
        oid
        name
        title
      }
    }
  }
`;
export const UPDATE_CATALOG_VERSION = gql`
  mutation UpdateVersion($version: VersionPatch!, $oid: UUID!) {
    updateVersion(input: { patch: $version, oid: $oid }) {
      version {
        id
        oid
        order
        active
        coverUrl
        options
        pages: pagesByVersion {
          oid
          imageSource
          imageUrl
          pageType
          options
        }
      }
      catalog: catalogByCatalog {
        oid
        id
      }
    }
  }
`;
export const DELETE_CATALOG_VERSION = gql`
  mutation DeleteVersion($oid: UUID!) {
    deleteVersion(input: { oid: $oid }) {
      clientMutationId
      catalog: catalogByCatalog {
        oid
        id
      }
    }
  }
`;
/**
 * PageInput
 * oid: UUID!
 * version: UUID
 * imageUrl: String
 * pageNum: Int
 * pageOptions: String
 * pageType: String
 */
export const CREATE_CATALOG_PAGE = gql`
  mutation CreatePage($page: PageInput!) {
    createPage(input: { page: $page }) {
      page {
        oid
      }
    }
  }
`;

export const DELETE_CATALOG_PAGE = gql`
  mutation DeletePage($oid: UUID!) {
    deletePage(input: { oid: $oid }) {
      page {
        oid
      }
    }
  }
`;

/**
 * FeedInput
 * oid: UUID!
 * catalog: UUID
 * feedPath: String
 * feedType: Int
 * feedMap: String
 * autoUpdate: Boolean
 */

export const GET_CATALOG_FEEDS = gql`
  query GetCatalogFeeds {
    feeds {
      oid
      feedType
      feedMap
      productMap
      autoUpdate
      options
      dateModified
      catalog: catalogByCatalog {
        id
        oid
        name
      }
    }
  }
`;

export const CREATE_CATALOG_FEED = gql`
  mutation CreateFeed($feed: FeedInput!) {
    createFeed(input: { feed: $feed }) {
      feed {
        oid
        options
        feedMap
        productMap
      }
    }
  }
`;

export const UPDATE_CATALOG_FEED = gql`
  mutation UpdateFeed($feed: FeedPatch!, $oid: UUID!) {
    updateFeed(input: { patch: $feed, oid: $oid }) {
      feed {
        oid
        options
        feedMap
        productMap
      }
    }
  }
`;

export const DELETE_CATALOG_FEED = gql`
  mutation DeleteFeed($oid: UUID!) {
    deleteFeed(input: { oid: $oid }) {
      feed {
        oid
        options
        feedMap
        productMap
      }
    }
  }
`;

export const CREATE_CATALOG_CATEGORY = gql`
  mutation CreateCategory($category: CategoryInput!) {
    createCategory(input: { category: $category }) {
      category {
        oid
        id
        active
      }
    }
  }
`;

export const UPDATE_CATALOG_CATEGORY = gql`
  mutation UpdateCategory($category: CategoryPatch!, $oid: UUID!) {
    updateCategory(input: { patch: $category, oid: $oid }) {
      category {
        oid
        id
        active
      }
    }
  }
`;

export const DELETE_CATALOG_CATEGORY = gql`
  mutation DeleteCategory($oid: UUID!) {
    deleteCategory(input: { oid: $oid }) {
      category {
        oid
        id
      }
    }
  }
`;

export const CREATE_CATALOG_PRODUCT = gql`
  mutation CreateProduct($product: ProductInput!) {
    createProduct(input: { product: $product }) {
      product {
        oid
      }
    }
  }
`;

export const UPDATE_CATALOG_PRODUCT = gql`
  mutation UpdateProduct($product: ProductPatch!, $oid: UUID!) {
    updateProduct(input: { patch: $product, oid: $oid }) {
      product {
        oid
      }
    }
  }
`;

export const DELETE_CATALOG_PRODUCT = gql`
  mutation DeleteProduct($oid: UUID!) {
    deleteProduct(input: { oid: $oid }) {
      product {
        oid
      }
    }
  }
`;
