import React from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
	ReferenceLine,
	Brush
} from 'recharts';

const DynalogTimeline = ({ data, show, ...props }) => {
	let key = 'totalCatalogView';
	switch (show) {
		case 'totalCatalogView':
			key = 'totalCatalogView';
			break;
		case 'totalPageView':
		case 'totalPageViewPerCatalogView':
			key = 'totalPageView';
			break;
		case 'totalProductView':
		case 'totalProductViewPerCatalogView':
			key = 'totalProductView';
			break;
		case 'totalLinkout':
		case 'totalLinkoutPerCatalogView':
			key = 'totalLinkout';
			break;
		default:
			key = 'totalCatalogView';
	}
	return (
		<ResponsiveContainer width="100%" height={300}>
			<LineChart data={data} margin={{ top: 10, right: 0, left: 0, bottom: 10 }}>
				<XAxis dataKey="date" axisLine={false} fontSize={10} tickLine={false} padding={{ left: 5, right: 5 }} />
				<YAxis fontSize={10} axisLine={false} tickLine={false} />
				<CartesianGrid stroke="#eee" vertical={false} />
				<Tooltip />

				<ReferenceLine y={0} stroke="#000" />
				<Brush dataKey="date" height={30} stroke="#8884d8" />
				<Line type="monotone" dataKey={key} stroke="#8884d8" activeDot={{ r: 8 }} />
				{/**
				 * 
				 * 	<Area
					type="monotone"
					dataKey={key}
					stackId="1"
					strokeWidth={2}
					stroke="#88f3bf"
					fill="#88f3bf"
					fillOpacity=".8"
				/>
				 */}
			</LineChart>
		</ResponsiveContainer>
	);
};

export default DynalogTimeline;
