import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { isEmpty } from "lodash";
import DataGrid, {
  Paging,
  MasterDetail,
  Column,
  Editing,
  SearchPanel,
  Button,
  FilterRow,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import MerchantGroupsView from "./merchantGroupsView";
import { GET_MERCHANTS } from "../../models/merchant/queries";
import {
  GET_CATALOGS_LIST,
  GET_CATALOG_ACCOUNTS,
  GET_ACCOUNT_FULL
} from "../../models/catalogs/queries";
import pictureCellRender from "../ColumnImageEdit";
import ImageSrcEdit from "../Dynalog/imageSrcEdit";
import {
  UPDATE_ACCOUNT,
  UPDATE_CATALOG,
  UPDATE_CATALOG_FEED,
} from "../../models/merchant/mutations";
import { useHistory } from "react-router";
import groupCatalogsView from "./groupCatalogsView";
import { withUser } from "../../context/user";
const CatalogList = (props) => {
  const [updateCatalog] = useMutation(UPDATE_CATALOG);
  const { loading, error, data } = useQuery(GET_CATALOGS_LIST);
  const [getAccountDetails, {loading: loadingAccount, data: accountData, error: errorAccount}] = useLazyQuery(GET_ACCOUNT_FULL);
  const [selectedCatalog, setSelectedCatalog] = useState({});
  // const merchantList = () => {
  // 	const merchants = data.accounts || [];
  // 	return merchants.map((merchant) => {
  // 		return {
  // 			ID: merchant.legacyId,
  // 			name: merchant.name,
  // 			email: merchant.email,
  // 			catalogs: merchant.catalogs || []
  // 		};
  // 	});
  // };
  const history = useHistory();
  
  useEffect(()=> { // used for fetching & updating user account data when selecting a catalog
    //console.log("pre-updated user", props.user);
    if (selectedCatalog != {} && accountData && !loadingAccount && !errorAccount){
      let newUser = {
        ...props.user,
        activeAccount: accountData.account,
        catalog: selectedCatalog,
        version: selectedCatalog.activeVersion,
        userChanged: false,
        activeCatalog: selectedCatalog.id,
        activeVersion: selectedCatalog.activeVersion?.id,
      }
      //console.log("updated user", newUser);
      props.setUser(newUser);
      history.push("/editor");
    }
  }, [accountData]);

  const navigateToCatalog = (e) => {
    setSelectedCatalog(e.row.data);
    getAccountDetails({variables:{oid: e.row.data.account.oid}})
  }

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>Error</p>;
  if (!data) return <p>NO DATA</p>;

  const groupList = () => {
    return data.catalogs.map((xx) => {
      return { ...xx, status: status(xx.status) };
    });
  };
  const status = (key) => {
    switch (key) {
      case 0:
        return "Subscribed";
      case 1:
        return "Affiliate";
      case 2:
        return "Expired";
      case 3:
        return "Free";
      case 4:
        return "Amazon";
      case 5:
        return "OutOfStock";
      case 6:
      default:
        return "Admin Only";
    }
  };
  const onRowUpdated = (event) => {
    //console.log('onRowUpdated', event);

    const { oid, name, cover, isDigital, active, ...category } = event.data;

    const payload = {
      name,
      cover,
      isDigital,
      active,
    };
    updateCatalog({
      variables: {
        oid: oid,
        catalog: payload,
      },
    })
      //.then((result) => refetch())
      .catch((e) => console.log(e));
  };

  return (
    <div className="content">
      <div className="dynalogWrapper">
        <div className="dynalogFrameWrapper">
          <h2> Catalog List </h2>
          <div className="">
            <DataGrid
              allowEditing={false}
              dataSource={groupList()}
              keyExpr={"oid"}
              showBorders={true}
              onRowUpdated={onRowUpdated}
            >
              <SearchPanel visible={true} placeholder={"Search Catalogs..."} />
              <FilterRow visible={true} />
              <Paging enabled={true} />
              <Editing mode="form" allowUpdating={true} />
              <Column
                dataField="cover"
                width={100}
                cellRender={pictureCellRender}
                editCellComponent={ImageSrcEdit}
              />
              <Column dataField="id" allowEditing={false} />
              <Column dataField="name" />
              <Column dataType={"boolean"} dataField="active" />
              <Column dataField="isDigital" />
              <Column dataField="status" allowEditing={false} />
              <Column type="buttons">
                <Button name="edit" />
                <Button
                  text="Select"
                  onClick={(e) => {navigateToCatalog(e);}}
                />
              </Column>
              <MasterDetail enabled={true} component={groupCatalogsView} />
            </DataGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUser(CatalogList);
