import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, TextArea, Checkbox, Dropdown, Label, Button, Message } from 'semantic-ui-react';
import notify from 'devextreme/ui/notify';
import Api from '../../lib/api';

const Settings = (props) => {
	const org = props.org;

	const init_settings = {
		send_catalog_label: !isEmpty(org) ? org.send_catalog_label || org.template.send_catalog_label : '',
		send_resources_label: !isEmpty(org) ? org.send_resources_label || org.template.send_resources_label : '',
		view_catalogs_label: !isEmpty(org) ? org.view_catalogs_label || org.template.view_catalogs_label : '',
		linkout_phone: !isEmpty(org) ? org.linkout_phone : 0,
		linkout_email: !isEmpty(org) ? org.linkout_email : 0,
		linkout_sms: !isEmpty(org) ? org.linkout_sms : 0,
		linkout_details: !isEmpty(org) ? org.linkout_details : 0,
		theme_color: !isEmpty(org) ? org.theme_color || org.template.theme_color : '#db2828',
		custom_utm: !isEmpty(org) ? org.custom_utm : 0,
		crm_email: !isEmpty(org) ? org.crm_email : ''
	};

	const {
		send_catalog_label,
		send_resources_label,
		view_catalogs_label,
		linkout_phone,
		linkout_email,
		linkout_sms,
		linkout_details
	} = init_settings;

	const [ sendCatalogLabel, setSendCatalogLabel ] = useState(send_catalog_label);
	const [ sendResourcesLabel, setResourcesLabel ] = useState(send_resources_label);
	const [ viewCatalogLabel, setViewCatalogLabel ] = useState(view_catalogs_label);
	const [ linkoutPhone, setLinkoutPhone ] = useState(linkout_phone);
	const [ linkoutEmail, setLinkoutEmail ] = useState(linkout_email);
	const [ linkoutSms, setLinkoutSms ] = useState(linkout_sms);
	const [ linkoutDetails, setLinkoutDetails ] = useState(linkout_details);

	const handleSubmit = () => {
		var body = new FormData();
		body.append('send_catalog_label', sendCatalogLabel);
		body.append('send_resources_label', sendResourcesLabel);
		body.append('view_catalogs_label', viewCatalogLabel);
		body.append('linkout_phone', linkoutPhone);
		body.append('linkout_email', linkoutEmail);
		body.append('linkout_sms', linkoutSms);
		body.append('linkout_details', linkoutDetails);

		Api.updateOrgProfile(org.id, body)
			.then((org) => {
				console.log(org);

				const notificationText = 'Updated successfully';
				notify({
					message: notificationText,
					type: 'success',
					width: 250,
					displayTime: 600
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className="content">
			<Form>
				<Form.Group widths="equal">
					<Form.Input
						fluid
						label="Send A Catalog Label"
						placeholder="Send A Catalog"
						value={sendCatalogLabel}
						onChange={(e, data) => setSendCatalogLabel(data.value)}
					/>
					<Form.Input
						fluid
						label="Send Other Resources Label"
						placeholder="Send Other Resources"
						value={sendResourcesLabel}
						onChange={(e, data) => setResourcesLabel(data.value)}
					/>
					<Form.Input
						fluid
						label="View Catalogs Label"
						placeholder="View Catalogs"
						value={viewCatalogLabel}
						onChange={(e, data) => setViewCatalogLabel(data.value)}
					/>
				</Form.Group>

				<h4>Linkout options</h4>
				<Form.Group>
					<Form.Field>
						<Checkbox
							name="linkout_sms"
							label={<label>Show SMS</label>}
							checked={linkoutSms ? true : false}
							onClick={(e, { checked }) => setLinkoutSms(checked ? 1 : 0)}
						/>
					</Form.Field>

					<Form.Field>
						<Checkbox
							name="linkout_phone"
							label={<label>Show Call</label>}
							checked={linkoutPhone ? true : false}
							onClick={(e, { checked }) => setLinkoutPhone(checked ? 1 : 0)}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							name="linkout_email"
							label={<label>Show Email</label>}
							checked={linkoutEmail ? true : false}
							onClick={(e, { checked }) => setLinkoutEmail(checked ? 1 : 0)}
						/>
					</Form.Field>
					<Form.Field>
						<Checkbox
							name="linkout_details"
							label={<label>Show Details</label>}
							checked={linkoutDetails ? true : false}
							onClick={(e, { checked }) => setLinkoutDetails(checked ? 1 : 0)}
						/>
					</Form.Field>
				</Form.Group>
				<Button type="buttom" primary onClick={() => handleSubmit()}>
					SAVE
				</Button>
			</Form>
		</div>
	);
};

export default Settings;
