export default {
	'id': 'ID',
	'inventory_type': 'Inventory type',
	'heading': 'Heading',
	'msrp': 'MSRP',
	'ref_price': 'Ref price',
	'price': 'Price',
	'stock_no': 'Stock',
	'vin': 'Vin',
	'media.photo_links.0': 'Product image',
	'vdp_url': 'Product url',
	'build.year': 'Year',
	'build.make': 'Make',
	'build.model': 'Model',
	'build.trim': 'Trim',
	'build.body_type': 'Body type',
	'build.drivetrain': 'Drive train',
	'build.engine_size': 'Engine size',
	'build.transmission': 'Transmission',
	'interior_color': 'Interior color',
	'exterior_color': 'Exterior color',
	'build.city_miles': 'MPG city',
	'miles': 'Mileage'
};
