import React, { Component } from "react";
import styled, { css } from "styled-components";

function Product6(props) {
  return (
    <>
      <Rect>{props.products[0]}</Rect>
      <Rect73>{props.products[1]}</Rect73>
      <Rect74>{props.products[2]}</Rect74>
      <Rect75>{props.products[3]}</Rect75>
      <Rect76>{props.products[4]}</Rect76>
      <Rect77>{props.products[5]}</Rect77>
    </>
  );
}

const Rect = styled.div`
  top: 32px;
  left: 24px;
  width: 145px;
  height: 145px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect73 = styled.div`
  top: 45px;
  left: 178px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect74 = styled.div`
  top: 181px;
  left: 44px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect75 = styled.div`
  top: 176px;
  left: 178px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect76 = styled.div`
  top: 310px;
  left: 44px;
  width: 125px;
  height: 125px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect77 = styled.div`
  top: 308px;
  left: 178px;
  width: 145px;
  height: 145px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default Product6;
