import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useDrag } from 'react-dnd';

const CategoryGrid = ( {category} ) => {
    const data = category.data;

	const [ { isDragging }, drag ] = useDrag({
		item: { category, type: 'Category' },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult) {
				//alert(`You dropped ${item} into ${dropResult.name}!`);
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});
	const opacity = isDragging ? 0.4 : 1;
	return (
		<div ref={drag} style={{ opacity }}>
			{data.name}
		</div>
	);
};
export default CategoryGrid;
