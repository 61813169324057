import React from 'react'
import { withUser } from '../../context/user';
import DataGrid, {
	Paging,
	MasterDetail,
	Column,
	Form,
	Popup,
	Item,
	Position,
	Editing,
	SearchPanel
} from 'devextreme-react/data-grid';
import GroupCatalogsView from '../../components/Merchant/groupCatalogsView';

const AccountView = (props)  => {

	//console.log("Groups! ", props);
	const groupList = () => {
		const catalogGroups = props.user.activeAccount?.catalogs || [];
		return catalogGroups.map((group) => {
			return {
				ID: group.id, 
				name: group.name,
				catalogActive: group.activeVersion,
				versions: group.versions || []
			};
		});
	};

	return (
		<div className="">
			<DataGrid columnHidingEnabled={true} dataSource={groupList()} keyExpr={'ID'} showBorders={true}>
				<SearchPanel visible={true} placeholder={'Search...'} />
				<Paging enabled={true} />
				<Editing mode="form" allowUpdating={true} />
				<Column dataField="ID" allowEditing={false} />
				<Column dataField="name" />
				<MasterDetail enabled={true} component={GroupCatalogsView} />
			</DataGrid>
		</div>
	);
};

export default withUser(AccountView);
