import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_CATALOG_ID } from "../../models/catalogs/queries";

import "./previewGroup.css";
import { isEmpty } from "lodash";
import defaultImage from "../../assets/img/page.png";
import { Image, Modal, Header } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { withUser } from "../../context/user";
import CreateForm from "../Dynalog/CreateForm/createCatalog";
import PageManager from "../Merchant/pageManager";
import DynalogSettings from "./dynalogSettings";
import { ArchiveCatalog } from "./ArchiveCatalog";

const PreviewGroup = (props) => {
  const { loading, error, data, refetch } = useQuery(GET_CATALOG_ID, {
    variables: { id: parseInt(props.user.activeCatalog) },
  });
  const [selected, setselected] = useState();
  const [active, setactive] = useState();
  const [openCreate, setOpenCreate] = useState(false);
  const [saved, setSaved] = useState(true);
  const [showArchived, setshowArchived] = useState(false);
  const [showSettings, setshowSettings] = useState(false);
  let catalog = data?.catalog;

  function versionAdded() {
    setOpenCreate(false);
    refetch();
  }
  useEffect(() => {
    if (data && data.catalog) {
      setselected(
        data.catalog.activeVersion?.id ||
          (data.catalog.versions.length > 0
            ? data.catalog.versions[0].id
            : null)
      );
      setactive(data.catalog.activeVersion?.id);
    }
  }, [catalog?.oid]);

  //   if (props.user.activeCatalog === undefined || !selected)
  //     return <p>No Digital Catalog</p>;

  if (loading) return <p>Loading ...</p>;
  if (error) return <p>{JSON.stringify(error)}</p>;
  if (!data || !data.catalog?.versions || props.user.activeCatalog === 0)
    return <p>{JSON.stringify(data.catalog)}</p>;

  catalog = data.catalog;
  if (!catalog) return <p>No Catalog Found</p>;
  const options = catalog.options || {};
  const versions = catalog?.versions || [];
  const renderCatalogs = () => {
    return versions.map((version, index) => {
      if (!showArchived && !version.active && version.id !== active) return;

      const cover = !isEmpty(version.coverUrl)
        ? `https://cdn.catalogs.com${version.coverUrl}`
        : defaultImage;
      return (
        <div
          key={version.id}
          onClick={() => {
            setselected(version.id);
          }}
          className={`catalogpGroup${!version.active ? " catarchived" : ""}`}
        >
          <Image
            key={version.id}
            src={cover}
            size="small"
            className={[
              "catalogImage",
              version.id === active ? "activeGroup" : "",
              version.id === selected ? "selectedGroup" : "",
            ].join(" ")}
          />
          version {version.id}
        </div>
      );
    });
  };
  if (!catalog) return "";

  const logoUrl = options?.logo || props.user.activeAccount?.logo;

  const logo = logoUrl && (
    <Image src={`https://cdn.catalogs.com/small${logoUrl}`} />
  );
  const coverImage = (
    <Image src={`https://cdn.catalogs.com/large${catalog.cover}`} />
  );
  return (
    <>
      <div className="ui fluid container segment">
        <h2 className="ui header">
          {coverImage} - {catalog.name} ({catalog.id}) - Versions{logo}
        </h2>
        {saved && (
          <>
            <div
              style={{ display: "inherit" }}
              onClick={() => setOpenCreate(true)}
              className="ui icon button"
            >
              <i className="add icon"></i>Add New Version
            </div>
            {renderCatalogs()}
          </>
        )}
      </div>
      <Modal closeIcon open={openCreate} onClose={() => setOpenCreate(false)}>
        <Header icon="browser" content="Create New Version" />
        <Modal.Content scrolling>
          <CreateForm mode="version" done={versionAdded} />
        </Modal.Content>
      </Modal>
      {selected ? (
        <>
          <div
            onClick={() => setshowArchived(!showArchived)}
            className="ui icon button"
          >
            <i
              className={showArchived ? "low vision icon" : "archive icon"}
            ></i>
            {showArchived ? " Hide " : " Show "} Archived
          </div>
          <div onClick={() => setshowSettings(true)} className="ui icon button">
            <i className="settings icon"></i> Catalog Options
          </div>
          <div onClick={() => refetch()} className="ui icon button">
            <i className="settings icon"></i> Refresh
          </div>
          <ArchiveCatalog
            catalogoid={data.catalog.oid}
            user={props.user}
            setUser={props.setUser}
          />
          <Modal
            closeIcon
            open={showSettings}
            onClose={() => setshowSettings(false)}
          >
            <Header icon="browser" content="CATALOG OPTIONS" />
            <Modal.Content scrolling>
              <DynalogSettings
                mode="catalog"
                versionId={selected}
                done={() => setshowSettings(false)}
              />
            </Modal.Content>
          </Modal>
          <PageManager
            saved={saved}
            setSaved={setSaved}
            versionId={selected || active}
            isActive={active === selected}
            update={refetch}
            activate={() => setactive(selected)}
          />
        </>
      ) : null}
    </>
  );
};

export default withUser(PreviewGroup);
