import React, { useState, useEffect, useRef } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { isEmpty, keys, snakeCase } from 'lodash';
import { Input, Button } from 'semantic-ui-react';
import { withUser } from '../../context/user';
import moment from 'moment';
import Api from '../../lib/api';
import data from './catagories.json';
import './CountsForm.css';

const CountsFrom = (props) => {
	const initialCountName = snakeCase(props.user.activeVersion.name) + moment().unix()
	const [ categories, setCategories ] = useState([]);
	const [ selectedCategory, setSelectedCategory ] = useState({});
	const [ options, setOptions ] = useState([]);
	const [ selectedIncludes, setSelectedIncludes ] = useState([]);
	const [ selectedExcludes, setSelectedExcludes ] = useState([]);
	const [ groups, setGroups ] = useState({});
	const [ countName, setCountName ] = useState(initialCountName);

	const prevCategory = usePrevious(selectedCategory);

	useEffect(() => {
		getCategories();
	}, []);

	useEffect(
		() => {
			console.log('******', prevCategory, selectedCategory);
			if (!isEmpty(selectedCategory)) {
				const id = selectedCategory.itemData.key;
				const parent = selectedCategory.parent;

				console.log('save group ', selectedCategory, id, parent);

				let newGroups = Object.assign({}, groups);

				// if category has includes update it
				if (!isEmpty(options)) {
					if (isEmpty(newGroups[parent])) {
						newGroups[parent] = {};
					}

					newGroups[parent][id] = {
						include: getIncludeValues(),
						exclude: getExcludeValues()
					};
				} else {
					console.log('updateNewGroup ', newGroups[parent]);
					try {
						if (!isEmpty(newGroups[parent])) {
							if (!isEmpty(newGroups[parent][id])) {
								delete newGroups[parent][id];
							}
						} else {
							delete newGroups[parent];
						}
					} catch (e) {}
				}

				setGroups(newGroups);
			}
		},
		[ options ]
	);

	const getCategories = () => {
		console.log('Categories ', data);
		setCategories(data);
	};

	const selectCategory = async (category) => {
		const node = category.node;

		const id = category.itemData.key;
		console.log('Selected Category ', id, category);
		const { data } = await Api.getSiteimpactCategoryOptions(id);
		console.log('Category Options ', data);

		category.options = data;
		let parent = 0;
		if (!isParent(node)) {
			parent = node.parent.key;
			category.parent = parent;
		}

		setSelectedCategory(category);

		if (!isEmpty(groups[parent]) && !isEmpty(groups[parent][id])) {
			//setOptions(groups[parent][id]['include']);
		} else {
			setOptions([]);
		}
	};
	const selectOption = (option) => {
		const item = option.itemData;
		console.log('itemSelected ', item);
		item.exclude = false;

		if (item.selected == true) {
			//add to include
			setOptions([ ...options, item ]);
		} else {
			const newOptions = options.filter((option) => {
				return option.value !== item.value;
			});
			setOptions(newOptions);
			//remove from list
		}
	};

	const getIncludeValues = () => {
		return options.filter((include) => include.exclude === false).map((include) => {
			return include.value;
		});
	};

	const getIncludes = () => {
		return options.filter((include) => include.exclude === false).map((include) => {
			return (
				<option key={include.value} value={include.value}>
					{include.description}
				</option>
			);
		});
	};

	const getExcludeValues = () => {
		return options.filter((include) => include.exclude === true).map((include) => {
			return include.value;
		});
	};

	const getExcludes = () => {
		return options.filter((include) => include.exclude === true).map((include) => {
			return (
				<option key={include.value} value={include.value}>
					{include.description}
				</option>
			);
		});
	};

	const onTreeViewReady = (event) => {};

	const isParent = (data) => {
		return data.items.length;
	};

	const move2include = () => {
		const updatedOptions = options.map((option) => {
			if (selectedExcludes.includes(option.value)) {
				option.exclude = false;
			}

			return option;
		});

		setOptions(updatedOptions);
	};

	const move2exclude = () => {
		const updatedOptions = options.map((option) => {
			if (selectedIncludes.includes(option.value)) {
				option.exclude = true;
			}

			return option;
		});

		setOptions(updatedOptions);
	};

	const selectedInclude = (e) => {
		const selectedOptions = e.target.options;
		let values = [];
		for (var i = 0, l = selectedOptions.length; i < l; i++) {
			if (selectedOptions[i].selected) {
				values.push(selectedOptions[i].value);
			}
		}

		setSelectedIncludes(values);
	};

	const selectedExclude = (e) => {
		const selectedOptions = e.target.options;
		let values = [];
		for (var i = 0, l = selectedOptions.length; i < l; i++) {
			if (selectedOptions[i].selected) {
				values.push(selectedOptions[i].value);
			}
		}

		setSelectedExcludes(values);
	};

	const renderGroups = () => {
		const parents = keys(groups);

		return parents.map((parent) => {
			let childrens = keys(groups[parent]);
			console.log(`childrens for ${parent}: `, childrens);
			return (
				<div key={parent}>
					<h4> {parent} </h4>
					<div>
						{childrens.map((children) => {
							console.log('children ', children);
							return (
								<div key={children}>
									<h4> {children} </h4>
									<div>includes: {groups[parent][children]['include'].join(', ')}</div>
									<div>excludes: {groups[parent][children]['exclude'].join(', ')}</div>
								</div>
							);
						})}
					</div>
				</div>
			);
		});
	};

	const saveCount = () => {
		const payload = {
			type: 'consumer',
			groups: {
				'1': {
					selections: JSON.stringify(groups)
				}
			},
			settings: {
				name: countName,
				per_household: 'one',
				count_id: '401008'
			}
		};
		console.log('save count! ', payload);
	};

	return (
		<div className="CountsFrom">
			<div className="selection-categories">
				<h4>Consumer Selection</h4>
				<TreeView
					items={categories}
					id={'selection-categories'}
					displayExpr="description"
					keyExpr="key"
					itemsExpr="categories"
					onItemClick={selectCategory}
					searchMode={'contains'}
					searchEnabled={true}
				/>
			</div>
			<div className="selection-options">
				{!isEmpty(selectedCategory) ? (
					<div>
						<h4>{selectedCategory.itemData.description}</h4>
						<div className="optionWrapper">
							<TreeView
								id={'selection-options'}
								displayExpr="description"
								keyExpr="key"
								itemsExpr="options"
								items={selectedCategory.options}
								showCheckBoxesMode={'normal'}
								onItemSelectionChanged={selectOption}
								searchMode={'contains'}
								searchEnabled={true}
								onContentReady={onTreeViewReady}
							/>
							<div className="includesWrap">
								<div className="block">
									<label>Includes</label>
									<Button floated="right" onClick={() => move2exclude()}>
										Move to Excludes
									</Button>
									<select multiple="multiple" onChange={selectedInclude}>
										{getIncludes()}
									</select>
								</div>
								<div className="block">
									<label>Excludes</label>
									<Button floated="right" onClick={() => move2include()}>
										Move to Includes
									</Button>
									<select multiple="multiple" onChange={selectedExclude}>
										{getExcludes()}
									</select>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div>No Data</div>
				)}
			</div>
			{!isEmpty(groups) && (
				<div className="countGroups">
					<div className="groups">{renderGroups()}</div>
					<div className="saveGroups">
						<Input value={countName} onChange={(e, data)=>setCountName(data.value)} fluid action={{ content: 'Save Count', color: 'blue', onClick: saveCount }} />
					</div>
				</div>
			)}
		</div>
	);
};

// Hook
const usePrevious = (value) => {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();

	// Store current value in ref
	useEffect(
		() => {
			ref.current = value;
		},
		[ value ]
	); // Only re-run if value changes

	// Return previous value (happens before update in useEffect above)
	return ref.current;
};

export default withUser(CountsFrom);
