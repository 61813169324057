import React, { useState, useEffect } from 'react';
import { Popup, ScrollView } from 'devextreme-react';
import { Form, Input, List, Button } from 'semantic-ui-react';
import { isEmpty, range, chunk, pickBy, identity} from 'lodash';
import Api from '../../lib/api';
import './dealerLookup.css';

const DealerLookup = (props) => {
	const [ popup, setPopup ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ dealers, setDealers ] = useState([]);
	const [ selectedDealer, setSelectedDealer ] = useState({});

	const [ state, setState ] = useState('');
	const [ city, setCity ] = useState('');
	const [ zipCode, setZipCode ] = useState('');
	const [ inventoryUrl, setInventoryUrl ] = useState('');

	const selectDealer = (id) => {
		props.data.setValue(id);
		setPopup(false);
	};

	const renderDealerList = () => {
		return dealers.map((dealer) => {
			return (
				<List.Item key={dealer.id}>
					<List.Content floated="right">
						<Button primary onClick={() => selectDealer(dealer.id)}>
							Select
						</Button>
					</List.Content>
					<List.Content>{dealer.seller_name}</List.Content>
				</List.Item>
			);
		});
	};

	const searchDealer = () => {
		setLoading(true);
		const params = {
			state: state,
			city: city,
			zip: zipCode,
			inventory_url: inventoryUrl,
			rows: 50
		};
		const payload = pickBy(params, identity)
		const queryString = Object.keys(payload).map((key) => key + '=' + payload[key]).join('&');

		Api.getMarketcheckDealer(queryString)
			.then((response) => {
				const { status, data } = response;
				//console.log(status, data);
				if (status == 200) {
					setDealers(data.dealers);
				}
			})
			.catch((error) => console.log(error));
	};
	return (
		<div>
			<Button fluid primary onClick={() => setPopup(true)}>
				Dealer Lookup
			</Button>
			<Popup
				visible={popup}
				onHiding={() => setPopup(false)}
				dragEnabled={false}
				closeOnOutsideClick={true}
				showTitle={true}
				title="Dealers"
				width={500}
				height={'80%'}
			>
				<ScrollView>
					<Form>
						<Form.Group widths="equal">
							<Form.Field>
								<label>STATE</label>
								<Input placeholder="Dealer State" onChange={(e, v) => setState(v.value)} />
							</Form.Field>
							<Form.Field>
								<label>City</label>
								<Input placeholder="Dealer City" onChange={(e, v) => setCity(v.value)} />
							</Form.Field>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field>
								<label>ZIP</label>
								<Input placeholder="Dealer Zip Code" onChange={(e, v) => setZipCode(v.value)} />
							</Form.Field>
							<Form.Field>
								<label>URL</label>
								<Input
									placeholder="Dealer Inventory URL"
									onChange={(e, v) => setInventoryUrl(v.value)}
								/>
							</Form.Field>
						</Form.Group>

						<Button fluid secondary onClick={() => searchDealer()}>
							SEARCH
						</Button>
					</Form>
					<br />
					<ScrollView>
						<List divided relaxed>
							{renderDealerList()}
						</List>
					</ScrollView>
				</ScrollView>
			</Popup>
		</div>
	);
};

export default DealerLookup;
