import { gql } from "apollo-boost";


export const UPDATE_LEAD_STATUS = gql`
  mutation updateLeadStatus($oid:UUID!) {
  updateSubscription(input: {patch: {exportStatus: "G"}, oid: $oid}){
    subscription{
      exportStatus
    }
  }
}
`;