import React, { useState } from "react";
import Storage from "@aws-amplify/storage";

//import "./styles.css";


const UploadS3 = ({ processFile }) => {
  const [state, setstate] = useState(
    {
      fileName: "",
      uploadedFile: "",
      fileType: "",
      response: "",
      uploaded: false
    });

  let upload = {};
  upload.files = [];

  function formatBytes(a, b = 2) { if (0 === a) return "0 Bytes"; const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024)); return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d] }
  const uploadImage = () => {
    if (upload && upload.files && upload.files[0])
      Storage.put(`${upload.files[0].name}`,
        upload.files[0],
        {
          contentType: upload.files[0].type, progressCallback(progress) {
            var resp = `Uploaded: ${formatBytes(progress.loaded)}/${formatBytes(progress.total)}`;
            setstate({ ...state, response: resp });
          }
        })
        .then(result => {
          upload = null;
          setstate({ ...state, response: "Success uploading file!", uploaded: true });
        })
        .catch(err => {
          setstate({ ...state, response: `Cannot upload file: ${err}` });
        });
  };


  return (
    <div className="Upload">
      <h5>Large PDF/ZIP Upload</h5>
      <input
        type="file"
        accept="application/pdf, application/zip"
        style={{ display: "none" }}
        ref={ref => (upload = ref)}
        onChange={e =>
          setstate({
            ...state,
            uploadedFile: upload.files[0],
            fileName: upload.files[0].name,
            fileType: upload.files[0].type,
            uploaded: false
          })
        }
      />
      <input defaultValue={state.fileName} placeholder="Select file" />
      <button
        onClick={e => {
          upload.value = null;
          upload.click();
        }}
      // loading={state.uploading}
      >
        Browse
        </button>
      {state.uploaded ? <button onClick={() => processFile(state)}>Process Uploaded PDF/ZIP</button> : <button onClick={uploadImage}> Upload File </button>}
      {!!state.response && <div>{state.response}</div>}
    </div>
  );

}

export default UploadS3;
