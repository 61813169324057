import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { isEmpty, keys, values, value, sum } from 'lodash';
import { Dropdown } from 'semantic-ui-react';
import { withUser } from '../../context/user';
import {
	Chart,
	Series,
	ArgumentAxis,
	CommonSeriesSettings,
	Export,
	Legend,
	Margin,
	Title,
	Subtitle,
	Tooltip,
	Grid
} from 'devextreme-react/chart';
import './Reports.css';
import Api from '../../lib/api';

const Reports = (props) => {
	const user = props.user;

	const [ dynalogReport, setDynalogReport ] = useState({});
	const [ year, setYear ] = useState(moment().format('YYYY'));
	const [ month, setMonth ] = useState(moment().format('MM'));
	const [ totalReport, setTotalReport ] = useState({});
	const [ totalReportMonths, setTotalReportMonths ] = useState(12);

	// useEffect(
	// 	() => {
	// 		const payload = {
	// 			dynalogId: user.activeVersion,
	// 			month: moment({ year: year, month: month - 1 }).format('MM'),
	// 			year: year
	// 		};
	// 		Api.getMerchantReport(payload).then((result) => {
	// 			setDynalogReport(result.data[0]);
	// 		});
	// 	},
	// 	[ year, month, user.activeVersion.id ]
	// );

	useEffect(
		() => {
			getLastMonthsReport(totalReportMonths).then((result) => {
				setTotalReport(result);
			});
		},
		[ totalReportMonths, user.activeVersion.id ]
	);

	const getLastMonthsReport = async (months) => {
		var startDate = moment().subtract(months, 'months');
		var endDate = moment();

		let catalogViewTotals = [];
		let pageViewTotals = [];
		let productViewTotals = [];
		let linkoutTotals = [];

		while (startDate.isBefore(endDate)) {
			const payload = {
				dynalogId: user.activeVersion,
				month: startDate.format('MM'),
				year: startDate.format('YYYY')
			};

			await Api.getMerchantReport(payload).then((result) => {
				if (!isEmpty(result.data[0])) {
					const report = result.data[0];
					catalogViewTotals.push(sum(values(report.dailyCatalogViewTotals)));
					pageViewTotals.push(sum(values(report.dailyPageViewTotals)));
					productViewTotals.push(sum(values(report.dailyProductViewTotals)));
					linkoutTotals.push(sum(values(report.dailyLinkoutTotals)));
				}
			});

			startDate = startDate.add(1, 'month');
		}

		return {
			catalogViewTotals: sum(values(catalogViewTotals)),
			pageViewTotals: sum(values(pageViewTotals)),
			productViewTotals: sum(values(productViewTotals)),
			linkoutTotals: sum(values(linkoutTotals))
		};
	};

	const renderTotalReport = () => {
		const dataSource = [
			{
				action: 'Catalog Views',
				value: totalReport.catalogViewTotals
			},
			{
				action: 'Page Views',
				value: totalReport.pageViewTotals
			},
			{
				action: 'Product Views',
				value: totalReport.productViewTotals
			},
			{
				action: 'Linkouts',
				value: totalReport.linkoutTotals
			}
		];

		return (
			<Chart id={'chart'} dataSource={dataSource}>
				<Series valueField={'value'} argumentField={'action'} name="total" type={'bar'} color={'#ffaa66'} />
				<Export enabled={true} />
				<Title text={`Totals for last ${totalReportMonths} months`} />
				<Tooltip enabled={true} />
			</Chart>
		);
	};

	const renderReport = () => {
		const report = dynalogReport;

		const reportSources = [
			{ value: 'catalog', name: 'Catalog views' },
			{ value: 'page', name: 'Page views' },
			{ value: 'product', name: 'Product views' },
			{ value: 'link', name: 'Link outs' }
		];

		const reportDataDesktop = keys(report.dynalog.dailyCatalogViewTotals).map((key, index) => {
			const day = parseInt(key);
			console.log(values(report.dynalog.dailyCatalogViewTotals));
			return {
				name: day,
				catalog: values(report.dynalog.dailyCatalogViewTotals)[index] || 0,
				page: values(report.dynalog.dailyPageViewTotals)[index] || 0,
				product: values(report.dynalog.dailyProductViewTotals)[index] || 0,
				link: values(report.dynalog.dailyLinkoutTotals)[index] || 0
			};
		});
		const reportDataMobile = keys(report.dynalogMobile.dailyCatalogViewTotals).map((key, index) => {
			const day = parseInt(key);
			console.log(values(report.dynalogMobile.dailyCatalogViewTotals));
			return {
				name: day,
				catalog: values(report.dynalogMobile.dailyCatalogViewTotals)[index] || 0,
				page: values(report.dynalogMobile.dailyPageViewTotals)[index] || 0,
				product: values(report.dynalogMobile.dailyProductViewTotals)[index] || 0,
				link: values(report.dynalogMobile.dailyLinkoutTotals)[index] || 0
			};
		});
		return (
			<div className="chartWrapper">
				<Chart palette={'Violet'} dataSource={reportDataDesktop} className="dynalogReport">
					<CommonSeriesSettings argumentField={'name'} type="line" />
					{reportSources.map(function(item) {
						return <Series key={item.value} valueField={item.value} name={item.name} />;
					})}
					<Margin bottom={20} />
					<Legend verticalAlignment={'bottom'} horizontalAlignment={'center'} itemTextPosition={'bottom'} />
					<Export enabled={true} />
					<Title text={'Dynalog Desktop Report'} />
					<Tooltip enabled={true} />
				</Chart>
				<Chart palette={'Violet'} dataSource={reportDataMobile} className="dynalogReport">
					<CommonSeriesSettings argumentField={'name'} type="line" />
					{reportSources.map(function(item) {
						return <Series key={item.value} valueField={item.value} name={item.name} />;
					})}
					<Margin bottom={20} />
					<Legend verticalAlignment={'bottom'} horizontalAlignment={'center'} itemTextPosition={'bottom'} />
					<Export enabled={true} />
					<Title text={'Dynalog Mobile Report'} />
					<Tooltip enabled={true} />
				</Chart>
			</div>
		);
	};

	const renderYears = () => {
		return years.map((year) => {
			return {
				text: year,
				value: year
			};
		});
	};

	const renderMonths = () => {
		return months.map((month) => {
			return {
				text: monthNames[month - 1],
				value: month
			};
		});
	};

	return (
		<div className="content">
			<div className="totalReport">{!isEmpty(totalReport) && renderTotalReport()}</div>

			<div className="dateRange">
				<Dropdown
					selection
					options={renderYears()}
					onChange={(e, data) => setYear(data.value)}
					value={parseInt(year)}
				/>
				<Dropdown
					selection
					options={renderMonths()}
					onChange={(e, data) => setMonth(data.value)}
					value={parseInt(month)}
				/>
			</div>

			{!isEmpty(dynalogReport) ? renderReport() : <p>No Data Available</p>}
		</div>
	);
};

const monthNames = moment.months();
const months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
const years = [ moment().year(), moment().year() - 1, moment().year() - 2 ];

export default withUser(Reports);
