import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form, Input, Button } from 'semantic-ui-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import MyEditor from '../../components/Editor';
import MyEditor2 from '../../components/Editor/editor';
import notify from 'devextreme/ui/notify';
import Api from '../../lib/api';

const EmailTemplate = (props) => {
	const org = props.org;

	const init_email = {
		email_subject: !isEmpty(org) ? org.email_subject || org.template.email_subject : '',
		email_body: !isEmpty(org) ? org.email_body || org.template.email_body : '',
		text_body: !isEmpty(org) ? org.text_body || org.template.text_body : '',
		resources_subject: !isEmpty(org) ? org.resources_subject || org.template.resources_subject : '',
		resources_body: !isEmpty(org) ? org.resources_body || org.template.resources_body : '',
		resources_text: !isEmpty(org) ? org.resources_text || org.template.resources_text : '',
		welcome_subject: !isEmpty(org) ? org.welcome_subject || org.template.welcome_subject : '',
		welcome_body: !isEmpty(org) ? org.welcome_body || org.template.welcome_body : '',
		welcome_text: !isEmpty(org) ? org.welcome_text || org.template.welcome_text : ''
	};

	const {
		email_subject,
		email_body,
		text_body,
		resources_subject,
		resources_body,
		resources_text,
		welcome_subject,
		welcome_body,
		welcome_text
	} = init_email;

	const [ emailSubject, setEmailSubject ] = useState(email_subject);
	const [ emailBody, setEmailBody ] = useState(email_body);
	const [ textBody, setTextBody ] = useState(text_body);

	const [ resourcesSubject, setResourcesSubject ] = useState(resources_subject);
	const [ resourcesBody, setResourcesBody ] = useState(resources_body);
	const [ resourcesText, setResourcesText ] = useState(resources_text);

	const [ welcomeSubject, setWelcomeSubject ] = useState(welcome_subject);
	const [ welcomeBody, setWelcomeBody ] = useState(welcome_body);
	const [ welcomeText, setWelcomeText ] = useState(welcome_text);

	const handleSubmit = () => {
		var body = new FormData();

		body.append('email_subject', emailSubject);
		body.append('email_body', emailBody);
		body.append('text_body', textBody);
	
		body.append('resources_subject', resourcesSubject);
		body.append('resources_body', resourcesBody);
		body.append('resources_text', resourcesText);
	
		body.append('welcome_subject', welcomeSubject);
		body.append('welcome_body', welcomeBody);
		body.append('welcome_text', welcomeText);

        Api.updateOrgProfile(org.id, body).then( (org) => {
            console.log(org);

            const notificationText = "Updated successfully";
            notify({
                message: notificationText,
                type: 'success',
                width: 250,
                displayTime: 600
            });
        })
        .catch( (err) => {
            console.log(err)
		})
		
	};

	return (
		<div className="content">
			<Form>
				<Tabs>
					<TabList>
						<Tab>Dynalog</Tab>
						<Tab>Resources</Tab>
						<Tab>Welcome</Tab>
					</TabList>

					<TabPanel>
						<Form.Field>
							<label>Email subject</label>
							<Input
								name="email_subject"
								placeholder="Email subject"
								value={emailSubject}
								onChange={(e, data) => setEmailSubject(data.value)}
							/>
						</Form.Field>
						<Form.Field>
							<label>Email Body</label>
							<MyEditor2 name="email_body" value={emailBody} onChange={(value) => setEmailBody(value)} />
						</Form.Field>
						<Form.Field>
							<label>Text</label>
							<MyEditor2 name="text_body" value={textBody} onChange={(value) => setTextBody(value)} />
						</Form.Field>
					</TabPanel>
					<TabPanel>
						<Form.Field>
							<label>Email subject</label>
							<Input
								name="resources_subject"
								placeholder="Email subject"
								value={resourcesSubject}
								onChange={(e, data) => setResourcesSubject(data.value)}
							/>
						</Form.Field>
						<Form.Field>
							<label>Email Body</label>
							<MyEditor2
								name="resources_body"
								value={resourcesBody}
								onChange={(value) => setResourcesBody(value)}
							/>
						</Form.Field>
						<Form.Field>
							<label>Text</label>
							<MyEditor2
								name="resources_text"
								value={resourcesText}
								onChange={(value) => setResourcesText(value)}
							/>
						</Form.Field>
					</TabPanel>
					<TabPanel>
						<Form.Field>
							<label>Email subject</label>
							<Input
								name="welcome_subject"
								placeholder="Email subject"
								value={welcomeSubject}
								onChange={(e, data) => setWelcomeSubject(data.value)}
							/>
						</Form.Field>
						<Form.Field>
							<label>Email Body</label>
							<MyEditor2
								name="welcome_body"
								value={welcomeBody}
								onChange={(value) => setWelcomeBody(value)}
							/>
						</Form.Field>
						<Form.Field>
							<label>Text</label>
							<MyEditor2
								name="welcome_text"
								value={welcomeText}
								onChange={(value) => setWelcomeText(value)}
							/>
						</Form.Field>
					</TabPanel>
				</Tabs>
				<br />
				<Button type="buttom" primary onClick={() => handleSubmit()}>
					SAVE
				</Button>
			</Form>
		</div>
	);
};

export default EmailTemplate;
