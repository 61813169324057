import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Header, Modal, Confirm } from 'semantic-ui-react';
import { isEmpty, range, chunk, includes, max, min } from 'lodash';
import DataGrid, {
	Paging,
	Column,
	Editing,
	Lookup,
	RequiredRule,
	SearchPanel,
	Scrolling,
	Export,
	DataSource
} from 'devextreme-react/data-grid';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UPDATE_CATALOG_FEED } from '../../models/merchant/mutations';
import { GET_CATALOG } from '../../models/catalogs/queries';
import MappingForm from '../Mapping/mappingForm';
import { useDropzone } from 'react-dropzone';
import defaultMapping from '../Mapping/products_mapping';
import autoMapping from '../Mapping/automapped';
import marketCheckMapping from '../Mapping/marketcheck_mapping';
import data from './dealer_1004115.json';
import Api from '../../lib/api';
import Papa from 'papaparse';
import { flattenObject } from '../../lib/utils';
import { myConfig } from '../../config';

const FeedGrid = (props) => {
	const feed = props.data.data;
	const done = props.data.done;

//	console.log('FEED ', feed);

	const [ updateFeedMutation ] = useMutation(UPDATE_CATALOG_FEED);
	const [ expiredPrompt, setExpiredPrompt ] = useState(false);
	const [donePrompt, setdonePrompt] = useState(false);
	const [ items, setItems ] = useState([]);
	const [ showMapBtn, setShowMapBtn ] = useState(false);
	const [ mapping, setMapping ] = useState([]);
	const [ options, setOptions ] = useState([]);
	const [ mapItems, setMapItems ] = useState(true);
	const [ openMap, setOpenMap ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [catalogId, setcatalogId] = useState(props.data.data.catalog.id)
	console.log('feed',catalogId, props.data.data.catalog.id);

	const gridEl = useRef(null);

	const initialMapping = !isEmpty(feed.productMap) ? JSON.parse(feed.productMap) : autoMapping;

	const [ GetCatalog, { loading: loadingDynalog, data: data } ] = useLazyQuery(GET_CATALOG, {
		variables: {
			oid: feed.catalog.oid
		}
	});


	const getMappingOptions = () => {
		const grid = gridEl.current.instance;
		const products = grid.getDataSource().items();

		let mapping = {};
		Object.keys(products[0]).map((key) => {
			mapping[key] = key;
		});
		console.log('mapping', mapping)
		return mapping || {};
	};

	const marketcheck_limit = 50;
	const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
		onDrop: (acceptedFiles) => {
			acceptedFiles.forEach((file) => {
				Papa.parse(file, {
					header: true,
					worker: true, // Don't bog down the main thread if its a big file
					complete: function(results) {
						if (results.data) {
							const rows = results.data;
							setMapItems(false);
							setItems(rows);
							setOptions(Object.keys(rows));
							//console.log('ROWS ', rows);
						}
					},
					error: function(err, file, inputElem, reason) {
						//console.log('error ', err);
					}
				});
			});
		},
		multiple: false,
		accept: '.csv',
		noClick: true,
		noKeyboard: true
	});
	if (loadingDynalog)
		return '';
	const dataSource = () => {
		//const mapping = !isEmpty(feed.mapping) ? JSON.parse(feed.mapping) : [];

		const mapping = marketCheckMapping;
		if (mapItems == false) return items;

		let objects = [];
		items.forEach((item) => {
			let ObjectMap = {};
			const flat = flattenObject(item);

			mapping.forEach((map) => {
				ObjectMap[map.text] = flat[map.value];
			});

			//add extra images
			const images = !isEmpty(item.media) ? item.media.photo_links : [];
			ObjectMap['Images'] = images;

			objects.push(ObjectMap);
		});

		return objects;
	};

	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	const pullData = async (start = 0) => {
		if (feed.options.dealerId) {
			const dealerId = feed.options.dealerId;
		
			const params = {
				dealer_id: dealerId,
				start: start,
				rows: feed.options.limit ? parseInt(feed.options.limit) : marketcheck_limit,
				car_type: feed.options.carType ? feed.options.carType : 'new'
			};
			if (feed.options.make) params.make = feed.options.make;
			if (feed.options.model) params.make = feed.options.model;

			const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');

			await Api.getMarketcheckInventory(queryString)
				.then(async (response) => {
					const { status, data } = response;
					if (status == 200) {
						const total = feed.options.limit ? parseInt(feed.options.limit) : data.num_found;
						const inventory = data.listings;

						setMapItems(true);
						setItems((inventory) => inventory.concat(data.listings));
						if (total > items.length) {
							const notfm = range(marketcheck_limit, total);
							const blocks = chunk(notfm, marketcheck_limit);

							for (const [ idx, block ] of blocks.entries()) {
								const i = idx + 1;
								await sleep(3000);
								await pullAsync(dealerId, i * marketcheck_limit);
							}

							
							// hide progress bar
						} else console.log('async ended!!!!');
					}
				})
				.catch((error) => console.log(error));
		}
	};

	const pullAsync = async (dealerId, start) => {
		const params = {
			dealer_id: dealerId,
			start: start,
			rows: marketcheck_limit
		};
		const queryString = Object.keys(params).map((key) => key + '=' + params[key]).join('&');

		await Api.getMarketcheckInventory(queryString)
			.then((response) => {
				const { status, data } = response;

				if (status == 200) {
					setItems((inventory) => inventory.concat(data.listings));
				}
			})
			.catch((error) => console.log(error));
	};

	const normalizeFeed = (product) => {
		const options = getMappingOptions();
		let object = {};
		mapping.forEach((map) => {
			var val = product[map.value];
			
			if (map.text==='Category')
				val = val.toUpperCase();

			object[map.text] = val;
		});

		return object;
	};

	const differenceData = (keys, base) => {
		let obj = {};
		Object.keys(base).forEach((key) => {
			if (!includes(keys, key)) {
				obj[key] = base[key];
			}
		});
		return obj;
	};
	const intersection = (o1, o2) => {
		return Object.keys(o1).concat(Object.keys(o2)).sort().reduce((r, a, i, aa) => {
			if (i && aa[i - 1] === a) {
				r.push(a);
			}
			return r;
		}, []);
	};

	const getExtraObjectProperties = (mapped, original) => {
		let uniqueKeys = intersection(original, mapped);
		const diff = differenceData(uniqueKeys, original);
		return diff;
	};

	const saveData = async (expired) => {
		setExpiredPrompt(false);
		const grid = gridEl.current.instance;
		const products = dataSource();

		const normalized = products.map((product) => {
			let nProduct = normalizeFeed(product);
			let extra = getExtraObjectProperties(nProduct, product);
			if (!isEmpty(product['Images'])) {
				delete extra['Images'];
				if (product['Images'].length > 9) {
					nProduct['AdditionalImages'] = product['Images'].slice(0, 10);
				} else {
					nProduct['AdditionalImages'] = product['Images'];
				}
			}
			nProduct['Additional'] = extra;

			/*
			nProduct['Price'] = max([ product['MarketValuePrice'], product['MSRPPrice'], product['InternetPrice'] ]);
			nProduct['SalePrice'] = min([
				product['MarketValuePrice'],
				product['MSRPPrice'],
				product['InternetPrice']
			]);
			*/

			/*
			if (nProduct['Category'].trim().toLowerCase() === 'used') {
				nProduct['Subcategory'] = nProduct['BodyType'];
			}
			nProduct['Combined'] = '';
			if (!isEmpty(nProduct['Category']) && !isEmpty(nProduct['Subcategory'])) {
				nProduct['Combined'] = `${nProduct['Category']}>${nProduct['Subcategory']}`;
			}
			nProduct['Combined2'] = '';
			if (!isEmpty(nProduct['Category']) && !isEmpty(nProduct['Subcategory'])) {
				nProduct['Combined2'] = `${nProduct['Category']} - ${nProduct['Subcategory']}`;
			}
			*/

			return nProduct;
		});

		console.log('normalized', normalized);

		setLoading(true);
		const url = `${myConfig.FEED_API_URL}?catid=${catalogId}&expired=${expired}`;

		await axios({
			method: 'post',
			url: url,
			data: normalized
		})
			.then(function(response) {
				setdonePrompt(true);
				console.log('feedresp',response);
				updateFeedMutation({
					variables: {
						oid: feed.OID,
						feed: { feedJson: JSON.stringify(products) }
					}
				})
					.then((result) => {
						setLoading(false);
						GetCatalog();
					})
					.catch((e) => {
						console.log('error', e);
						setLoading(false);
					});
			})
			.catch(function(error) {
				console.log(error);
			});
		setExpiredPrompt(false);
		return normalized;
	};

	const updateProductMapping = (mapping) => {
		/*
		const options = getMappingOptions();
		let map = {};
		const mapKeys = Object.keys(mapping);
		mapKeys.forEach((key) => {
			const value = mapping[key];
			map[key] = options[value];
		});
		*/
		updateFeedMutation({
			variables: {
				oid: feed.OID,
				feed: { productMap: JSON.stringify(mapping) }
			}
		})
			.then((result) => {
				//console.log('productmap', result);
			})
			.catch((e) => {
				//console.log('productmap', e);
			});

		setMapping(mapping);
		setOpenMap(false);
	};

	if (!isEmpty(data)) {
		const catalog = data.catalog;
		const version = catalog.activeVersion;
		if (version.id) {
			const redirectUrl = `/dynalog/${version.id}/editor?refresh=true`;
			console.log('redirect to ', redirectUrl);
			return <Redirect push to={redirectUrl} />;
		}
	}

	return (
		<div>
			<DataGrid ref={gridEl} dataSource={dataSource()} showBorders={true} columnHidingEnabled={true}>
				<Scrolling columnRenderingMode="virtual" />
				<SearchPanel visible={true} placeholder={'Search...'} />
				<Paging enabled={true} />
				<Editing mode="cell" allowUpdating={true} allowDeleting={true} />
				<Export fileName={feed.name} enabled={true} allowExportSelectedData={true} />
			</DataGrid>
			<input {...getInputProps()} />
			<br />
			<Button.Group>
				<Button color="teal" onClick={() => open()}>
					LOAD DATA
				</Button>
				<Button secondary onClick={() => pullData()}>
					PULL DATA
				</Button>

				{!isEmpty(items) && (
					<React.Fragment>
						<Button secondary onClick={() => setOpenMap(true)}>
							MAP PRODUCTS
						</Button>
					</React.Fragment>
				)}
				{openMap && (
					<Modal closeIcon open={openMap} onClose={() => setOpenMap(false)}>
						<Header icon="browser" content="Map Products" />
						<Modal.Content scrolling>
							<MappingForm
								mapping={initialMapping}
								options={getMappingOptions()}
								title="Map products fields"
								onSave={(mapping) => updateProductMapping(mapping)}
							/>
						</Modal.Content>
					</Modal>
				)}
				{!isEmpty(items) &&
				!isEmpty(mapping) && (
					<Button primary onClick={() => setExpiredPrompt(true)} loading={loading}>
						PROCESS FEED
					</Button>
				)}
			</Button.Group>
			<Confirm
				open={expiredPrompt}
				content="Mark Missing Products as expired"
				cancelButton="NO"
				confirmButton="YES"
				onCancel={() => saveData(false)}
				onConfirm={() => saveData(true)}
			/>
			<Confirm
				open={donePrompt}
				content="Feed Sent For Processing. Please Close and Press Refresh."
				cancelButton="Back"
				confirmButton="OK"
				onCancel={() => setdonePrompt(false)}
				onConfirm={() => setdonePrompt(false)}
			/>
		</div>
	);
};

export default FeedGrid;
