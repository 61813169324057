import React, { useState, useEffect } from 'react';
import { withUser } from '../../context/user';
import DataGrid, { Paging, Column, Editing, SearchPanel } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { Header, Icon } from 'semantic-ui-react';
import { getMarketingCampaigns } from '../../lib/user';
import CountsForm from '../../components/SiteImpact/CountsForm';
import OrderForm from '../../components/SiteImpact/OrderForm';
import Api from '../../lib/api';
import './Campaigns.css';

const Campaigns = (props) => {
	const user = props.user;
	const [ campaigns, setCampaigns ] = useState([]);

	useEffect(
		() => {
			//getSiteImpactCategories();
		},
		[  ]
	);

	useEffect(
		() => {
			handleListCampaigns();
		},
		[ user.activeAccount ]
	);

	const getSiteImpactCategories = async () => {

		await Api.getSiteimpactCategories().then((result) => {
			console.log(result)
		})
		.catch( (error) => {
			console.log(error)
		})
	}

	const handleListCampaigns = async () => {
		await getMarketingCampaigns(user.activeAccount).then((result) => {
			console.log('campaigns: ', result);

			if (!result.Error) {
				setCampaigns(result);
			} else {
				setCampaigns([]);
			}
		});
	};
	const data = () => {
		return campaigns.map((campaign) => {
			return {
				ID: campaign.oid,
				dynalog: campaign.dynalogAccountByAccount.name,
				sender: campaign.sender,
				subject: campaign.subject,
				distributionDate: campaign.distributionDate
			};
		});
	};

	//INSERT
	const onInitNewRow = (event) => {
		console.log('onInitNewRow:', event);
	};
	const onRowInserting = (event) => {
		console.log('onRowInserting:', event);
	};
	const onRowInserted = (campaign) => {
		console.log('onRowInserted:', campaign);
		const payload = {
			campaign: user.activeAccount,
			sender: campaign.data.sender,
			subject: campaign.data.subject,
			distributionDate: campaign.data.distributionDate.toString()
		}
		console.log('insert campaign payload:', payload);
	};

	return (
		<div className="content">

			<CountsForm />

			<OrderForm />

			<div className="campaignsPage">
				<div className="campaignActions">
					<div className="campaignAction campaignEmail">
						<Icon size="big" name="mail" />
						<label>Buy Email Campaign</label>
					</div>
					<div className="campaignAction campaignFacebook">
						<Icon size="big" name="facebook f" />
						<label>Buy Facebook Campaign</label>
					</div>
					<div className="campaignAction campaignText">
						<Icon size="big" name="phone" />
						<label>Buy Text Campaign</label>
					</div>
				</div>
				<div>
					<DataGrid
						columnHidingEnabled={true}
						dataSource={data()}
						keyExpr={'ID'}
						showBorders={true}
						onInitNewRow={onInitNewRow}
						onRowInserting={onRowInserting}
						onRowInserted={onRowInserted}
					>
						<Editing mode={'form'} allowUpdating={false} allowDeleting={false} allowAdding={true} />
						<Column dataField={'sender'} caption={'Sender'} />
						<Column dataField={'subject'} caption={'Subject'} />
						<Column dataField={'distributionDate'} width={125} dataType={'date'} />
					</DataGrid>
				</div>
			</div>
		</div>
	);
};

export default withUser(Campaigns);
