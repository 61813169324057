import React, { useState, useRef, useEffect } from 'react';
import { Header, Modal, Button } from 'semantic-ui-react';
import DataGrid, {
	Export,
	Editing,
	SearchPanel,
	Scrolling
} from 'devextreme-react/data-grid';
import Papa from 'papaparse';
import axios from 'axios';
import { useQuery } from '@apollo/react-hooks';
import MappingForm from '../Mapping/mappingForm';
import { isEmpty, includes } from 'lodash';

import { useDropzone } from 'react-dropzone';
import defaultMapping from '../Mapping/products_mapping';
import { myConfig } from '../../config';
import { Tab } from 'semantic-ui-react';
import './CSVImportGrid.scss';
import defaultMap from '../Mapping/products_mapping';

const CSVImportGrid = ({ catalog, onFinished, ...props }) => {
	const gridEl = useRef(null);
	const [items, setItems] = useState([]);
	const [mappedItems, setmappedItems] = useState(null);
	const [mapping, setMapping] = useState(null);
	const [openMap, setOpenMap] = useState(false);
	const [loading, setLoading] = useState(false);
	const [valid, setValid] = useState(false);

	useEffect(() => {
		if (isEmpty(items))
			return;

		let nMap = [];
		defaultMap.forEach(element => {
			Object.keys(items[0]).map((key) => {
				var nKey = key.replace(" ", "");
				var nMey = element.text.replace(" ", "");
				if (nKey.localeCompare(nMey, 'en', { sensitivity: 'base' }) === 0)
					element.value = key;
			});
			nMap.push(element);
		});
		setMapping(nMap);
	}, [items])

	useEffect(() => {
		setmappedItems(map(items));
	}, [mapping])

	const getMappingOptions = () => {

		let cmapping = {};
		Object.keys(items[0]).map((key) => {
			cmapping[key] = key;
		});
		return cmapping || {};
	};

	const { getInputProps, open } = useDropzone({
		onDrop: (acceptedFiles) => {
			acceptedFiles.forEach((file) => {
				Papa.parse(file, {
					header: true,
					worker: true, // Don't bog down the main thread if its a big file
					complete: function (results) {
						if (results.data) {
							const rows = results.data;
							setItems(rows);
							//console.log('ROWS ', rows);
						}
					},
					error: function (err) {
						console.log('error ', err);
					}
				});
			});
		},
		multiple: false,
		accept: '.csv',
		noClick: true,
		noKeyboard: true
	});

	const updateProductMapping = (mapping) => {
		setMapping(mapping);
		setOpenMap(false);
	};

	// const getExtraObjectProperties = (mapped, original) => {
	// 	let uniqueKeys = intersection(original, mapped);
	// 	const diff = differenceData(uniqueKeys, original);
	// 	return diff;
	// };
	// const normalized = (products) => {
	// 	console.log(mapping);
	// 	return products.map((product) => {
	// 		let nProduct = normalizate(product);
	// 		let extra = getExtraObjectProperties(nProduct, product);
	// 		if (!isEmpty(product['Images'])) {
	// 			delete extra['Images'];
	// 			nProduct['AdditionalImages'] = product['Images'];
	// 		}
	// 		nProduct['Additional'] = extra;

	// 		return nProduct;
	// 	});
	// }
	const map = (products) => {

		return products.map((product) => {
			let mappedProducts = [];
			let AdditionalImages = [];
			let nProduct = {};
			let Additional = {};
			//			nProduct.SalePrice=0;
			mapping.forEach(item => {

				if (item.value !== 0) {

					if (item.id.includes('Price')) {
						var val = parseFloat(product[item.value]?.replace(/[^\d.]/g, ''));
						nProduct[item.text] = isNaN(val) ? 0 : val;
					} else if (item.text.includes('AdditionalImages')) {
						AdditionalImages.push(product[item.value]);
					}
					else if (item.id === 'Category') {


						const val = product[item.value];
						if (val)
							nProduct[item.text] = val.toUpperCase();
					}
					else
						nProduct[item.text] = product[item.value];

				}
				mappedProducts.push(item.value);
			});
			Object.keys(product).forEach(element => {

				if (!mappedProducts.find(xx => xx === element))
					Additional[element] = product[element];

			});

			nProduct.dateEntered = new Date().toISOString().slice(0,19);
			nProduct.Additional = Additional;
			nProduct.AdditionalImages = AdditionalImages;
			return nProduct;
		});
	}

	const saveData = async () => {
		console.log(mappedItems);
		setLoading(true);
		const chunkSize = 500
		console.log(mappedItems[0]);
		const filteredItems = mappedItems.filter((p)=>p.SKU);
		for (let i = 0; i<filteredItems.length; i += chunkSize){
			let chunk = filteredItems.slice(i,i+chunkSize);
			console.log("chunk", i, i+chunkSize, chunk);
			await axios({
					method: 'post',
					url: `${myConfig.FEED_API_URL}?catid=${catalog.id}`,
					data: chunk
				}).then(function (response) {
					console.log(response);
					if (i+chunkSize>=filteredItems.length){
						setLoading(false);
						onFinished && onFinished();
					}
				}).catch(function (error) {
					console.log(error);
					setLoading(false);
				});
		}
	}
	const panes = [
		{
			menuItem: 'Import Feed',
			render: () =>
				<Tab.Pane attached={false}>
					<DataGrid columnHidingEnabled={true} style={{ height: '100%', maxHeight: '500px' }} ref={gridEl} dataSource={items} showBorders={false} columnAutoWidth={false}>
						<Scrolling mode="virtual" />
						<SearchPanel visible={true} placeholder={'Search...'} />
					</DataGrid>
				</Tab.Pane>
		},
		{
			menuItem: 'Mapped Items',
			render: () =>
				<Tab.Pane attached={false}>
					<DataGrid columnHidingEnabled={true} style={{ height: '100%', maxHeight: '500px' }} dataSource={mappedItems} showBorders={false} columnAutoWidth={false}>
						<Scrolling mode="virtual" />
						<SearchPanel visible={true} placeholder={'Search...'} />
					</DataGrid>
				</Tab.Pane>
		}
	]

	return (
		<React.Fragment>
			<input {...getInputProps()} />
			<Button.Group>
				<Button color="teal" onClick={() => open()}>
					LOAD DATA
				</Button>
				{!isEmpty(items) && (

					<Button secondary onClick={() => setOpenMap(true)}>
						MAP PRODUCTS
					</Button>

				)}
				{openMap && (
					<Modal closeIcon open={openMap} onClose={() => setOpenMap(false)}>
						<Header icon="browser" content="Map Products" />
						<Modal.Content scrolling>
							<MappingForm
								mapping={mapping || defaultMapping}
								options={getMappingOptions()}
								title="Map products fields"
								onSave={(mapping) => {
									updateProductMapping(mapping);
									setValid(true);
								}}
							/>
						</Modal.Content>
					</Modal>
				)}
				{!isEmpty(items) &&
					!isEmpty(mapping) && (
						<Button disabled={!valid} primary onClick={() => saveData()} loading={loading}>
							UPDATE PRODUCTS w/FEED
						</Button>
					)}
			</Button.Group>
			<Tab className="ingestorTab" menu={{ pointing: true }} panes={panes} />
		</React.Fragment>
	);
};

export default CSVImportGrid;
