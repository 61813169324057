import React, { useEffect, useState } from 'react';
import { Menu, Icon } from 'semantic-ui-react';
const CanvasTextProperties = (props) => {
	const handleItemClick = (e, v) => {
		//console.log(v.name)
		props.onClick(v.name);
	};
	return (
		<div className="menuWrapper">
			<Menu compact icon inverted widths={5}>
				<Menu.Item name="alignLeft" onClick={handleItemClick}>
					<Icon name="align left" />
				</Menu.Item>
				<Menu.Item name="alignCenter" onClick={handleItemClick}>
					<Icon name="align center" />
				</Menu.Item>

				<Menu.Item name="alignJustify" onClick={handleItemClick}>
					<Icon name="align justify" />
				</Menu.Item>
				<Menu.Item name="alignRight" onClick={handleItemClick}>
					<Icon name="align right" />
				</Menu.Item>
				<Menu.Item name="bold" onClick={handleItemClick}>
					<Icon name="bold" />
				</Menu.Item>
			</Menu>
		</div>
	);
};

export default CanvasTextProperties;
