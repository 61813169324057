import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form, Button } from 'semantic-ui-react';
import notify from 'devextreme/ui/notify';
import Api from '../../lib/api';

const Profile = (props) => {
    const org = props.org;
    const [ name, setName ] = useState(org.name);
    const [ address, setAddress ] = useState(org.address);
    const [ managerFname, setManagerFname ] = useState(org.manager_fname);
    const [ managerLname, setManagerLname ] = useState(org.manager_lname);
    const [ email, setEmail ] = useState(org.email);
    const [ logo, setLogo ] = useState({});


	const handleSubmit = () => {
        var body = new FormData();
        body.append('name', name);

        
        Api.updateOrgProfile(org.id, body).then( (org) => {
            console.log(org);

            const notificationText = "Updated successfully";
            notify({
                message: notificationText,
                type: 'success',
                width: 250,
                displayTime: 600
            });
        })
        .catch( (err) => {
            console.log(err)
        })
    };

    const imageProfileChanged = (e) => {

        console.log(e)
        setLogo(e.value[0]);
    }

	return (
		<div className="content">
			<Form>
				<Form.Group widths="equal">
					<Form.Input fluid label="Name" placeholder="Name" value={name} onChange={(e, data) => setName(data.value)} />
					<Form.Input fluid label="Address" placeholder="Address" value={address} onChange={(e, data) => setAddress(data.value)} />
				</Form.Group>
				<Form.Group widths="equal">
					<Form.Input fluid label="Manager First Name" placeholder="First Name" value={managerFname} onChange={(e, data) => setManagerFname(data.value)} />
					<Form.Input fluid label="Manager Last Name" placeholder="Last Name" value={managerLname} onChange={(e, data) => setManagerLname(data.value)} />
					<Form.Input fluid label="Manager Email" placeholder="Email" value={email} onChange={(e, data) => setEmail(data.value)} />
				</Form.Group>
				<Button type="buttom" primary onClick={() => handleSubmit()}>
					SAVE
				</Button>
			</Form>
		</div>
	);
};

export default Profile;
