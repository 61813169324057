import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Image } from 'semantic-ui-react';
import { useDrag } from 'react-dnd';

const ProductGrid = ( {product} ) => {
    const data = product.data;
	const productImage = data.src.includes('http') ? data.src : `https://cdn.catalogs.com${data.src}`;
	const [ { isDragging }, drag ] = useDrag({
		item: { product, type: 'Product' },
		end: (item, monitor) => {
			const dropResult = monitor.getDropResult();
			if (item && dropResult) {
				//alert(`You dropped ${item} into ${dropResult.name}!`);
			}
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});
	const opacity = isDragging ? 0.4 : 1;
	return (
		<div ref={drag} style={{ opacity }}>
			<Image src={productImage} size="small" />
		</div>
	);
};
export default ProductGrid;
