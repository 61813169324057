import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { SelectBox, ColorBox, Slider } from 'devextreme-react';

const strokeWidthOptions = [
	{ size: 2, Name: '2' },
	{ size: 5, Name: '5' },
	{ size: 10, Name: '10' },
	{ size: 15, Name: '15' },
	{ size: 20, Name: '20' }
];

const fontFamilyOptions = [
	{ font: 'Arial', Name: 'Arial' },
	{ font: 'Times New Roman', Name: 'Times New Roman' },
	{ font: 'Helvetica', Name: 'Helvetica' },
	{ font: 'Times', Name: 'Times' },
	{ font: 'Courier New', Name: 'Courier New' },
	{ font: 'Verdana', Name: 'Verdana' },
	{ font: 'Geneva', Name: 'Geneva' },
	{ font: 'Calibri', Name: 'Calibri' },
	{ font: 'Optima', Name: 'Optima' },
	{ font: 'Monaco', Name: 'Monaco' },
	{ font: 'Lucida Bright', Name: 'Lucida Bright' }
];

const fontSizeOptions = [
	{ size: 10, Text: '10px' },
	{ size: 12, Text: '12px' },
	{ size: 14, Text: '14px' },
	{ size: 16, Text: '16px' },
	{ size: 18, Text: '18px' },
	{ size: 20, Text: '20px' },
	{ size: 22, Text: '22px' }
];

const visibilityOptions = [
	{ value: '0', Text: 'Always' },
	{ value: '1', Text: 'Hover' },
	{ value: '2', Text: 'On Load & Hover' },
	{ value: '3', Text: 'Hidden' }
];

const linkoutOptions = [
	{ value: 'product', Text: 'Product Page' },
	{ value: 'category', Text: 'Category Pages' },
	{ value: 'url', Text: 'Open Link' }
];
const showAsOptions = [
	{ value: 'product', Text: 'Product Box' },
	{ value: 'annotation', Text: 'Annotation' },
	{ value: 'image', Text: 'Image Only' }
];
export const Opacity = (props) => {
	return (
		<Form.Field>
			<label>Opacity</label>
			<Slider
				min={0}
				max={1}
				step={0.1}
				value={props.defaultValue}
				onValueChanged={({ value }) => props.setValue('opacity', value)}
			/>
		</Form.Field>
	);
};

export const FontFamily = (props) => {
	return (
		<Form.Field>
			<label>Font Family</label>
			<SelectBox
				displayExpr="Name"
				valueExpr="font"
				value={props.defaultValue}
				items={fontFamilyOptions}
				onValueChanged={(e) => props.setValue('fontFamily', e.value)}
			/>
		</Form.Field>
	);
};

export const FontSize = (props) => {
	return (
		<Form.Field>
			<label>Font Size</label>
			<SelectBox
				displayExpr="Text"
				value={props.defaultValue}
				items={fontSizeOptions}
				valueExpr="size"
				onValueChanged={(e) => props.setValue('fontSize', e.value)}
			/>
		</Form.Field>
	);
};

export const Stroke = (props) => {
	return (
		<Form.Field>
			<label>Stroke Color</label>
			<ColorBox
				value={props.defaultValue}
				onValueChanged={({ value }) => props.setValue('stroke', value)}
			/>
		</Form.Field>
	);
};
export const StrokeWidth = (props) => {
	return (
		<Form.Field>
			<label>Stroke Width</label>
			<SelectBox
				displayExpr="Name"
				value={props.defaultValue}
				items={strokeWidthOptions}
				valueExpr="size"
				onValueChanged={(e) => props.setValue('strokeWidth', e.value)}
			/>
		</Form.Field>
	);
};

export const Fill = (props) => {
	return (
		<Form.Field>
			<label>Fill Color</label>
			<ColorBox value={props.defaultValue} onValueChanged={({ value }) => props.setValue('fill', value)} />
		</Form.Field>
	);
};

export const ImageUrl = (props) => {
	return (
		<Form.Field>
			<label>Image url</label>
			<Input value={props.defaultValue} onChange={(e, data) => props.setValue('imageUrl', data.value)} />
		</Form.Field>
	);
};

export const LinkoutOptions = (props) => {
	return (
		<Form.Field>
			<label>Click Action</label>
			<SelectBox
				displayExpr="Text"
				valueExpr="value"
				value={props.defaultValue}
				items={linkoutOptions}
				onValueChanged={(e) => props.setValue('linkoutOptions', e.value)}
			/>
		</Form.Field>
	);
};

export const ShowAsOptions = (props) => {
	return (
		<Form.Field>
			<label>Show Product As</label>
			<SelectBox
				displayExpr="Text"
				valueExpr="value"
				value={props.defaultValue}
				items={showAsOptions}
				onValueChanged={(e) => props.setValue('ShowAs', e.value)}
			/>
		</Form.Field>
	);
};
export const LinkoutUrl = (props) => {

	return (
		<Form.Field>
			<label>External Url</label>

			<Input defaultValue={props.defaultValue} onChange={(e, data) => props.setValue('linkout', data.value)} />
		</Form.Field>
	);
};

export const VisibilityOptions = (props) => {
	return (
		<Form.Field>
			<label>Visibility {props.defaultValue}</label>
			<SelectBox
				displayExpr="Text"
				value={props.defaultValue}
				items={visibilityOptions}
				valueExpr="value"
				onValueChanged={(e) => props.setValue('visibility', e.value)}
			/>
		</Form.Field>
	);
};
