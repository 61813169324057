import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Form } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { getCampaignByUrl, newCampaignUser } from '../../lib/graphql';
import { withUser } from '../../context/user';
import './JoinForm.css';

const JoinForm = (props) => {
	const [ campaignGroups, setCampaignGroups ] = useState([]);
	const [ campaign, setCampaign ] = useState({});
	const [ campaignGroup, setCampaignGroup ] = useState(0);
	const [ redirect, setRedirect ] = useState(false);

	const user = props.user;

	useEffect(() => {
		handlelistDynalogs();
	}, []);

	const handlelistDynalogs = async () => {
		const url = window.location.hostname;
		//const url = 'toyota.catalogshub.com';

		//console.log('URL to query campaigns ', url);
		getCampaignByUrl(url).then((result) => {
			//console.log("getCampaignByUrl ", result)
			const campaign = result[0];
			if (!isEmpty(campaign)) {
				const campaignGroups = campaign['campaignGroupsByDynalogCampaign'] || [];
				setCampaign(campaign);
				setCampaignGroups(campaignGroups);
			}
		});
	};

	const handleJoin = async (event) => {
		event.preventDefault();
		const payload = {
			email: user.email,
			campaignId: campaign.oid,
			campaignGroupId: campaignGroup
		};

		//console.log('createCampaignUser', payload);

		newCampaignUser(payload).then((result) => {
			props.onJoin(user.email);
			setRedirect(true);
		});
	};

	const dealerOptions = () => {

		//console.log("---------- ", campaignGroups)
		return campaignGroups.map((group) => {
			return { key: group.oid, value: group.oid, text: group.name };
		});
	};

	return redirect == true ? (
		<Redirect to="/dashboard" />
	) : (
		<div className="JoinForm">
			<h2>Please select you dealer for {campaign.name}</h2>
			<Form onSubmit={handleJoin}>
				<Form.Field required>
					<label>Dealer</label>
					<Dropdown
						placeholder="Select Dealer"
						fluid
						search
						selection
						required
						options={dealerOptions()}
						onChange={(e, data) => setCampaignGroup(data.value)}
					/>
				</Form.Field>
				<Button type="submit" disabled={campaignGroup == 0} primary>
					Submit
				</Button>
			</Form>
		</div>
	);
};

export default withUser(JoinForm);
