import React, { useState, useEffect } from "react";
import DataGrid, {
  Paging,
  MasterDetail,
  Column,
  Form,
  Popup,
  Item,
  Position,
  Editing,
  SearchPanel,
  Button,
  FilterRow,
} from "devextreme-react/data-grid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import GroupCatalogsView from "./groupCatalogsView";
import pictureCellRender from "../ColumnImageEdit";
import ImageSrcEdit from "../Dynalog/imageSrcEdit";
import { withUser } from "../../context/user";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_CATALOG } from "../../models/merchant/mutations";

const GroupsTab = (props) => {
  console.log("Groups! ", props);
  const history = useHistory();
  const groupList = () => {
    const catalogGroups = props.catalogs || [];
    return catalogGroups.map((xx) => {
      return { ...xx, status: xx.status };
    });
  };

  const [updateCatalog] = useMutation(UPDATE_CATALOG);

  const onRowUpdated = (event) => {
    //console.log('onRowUpdated', event);

    const { oid, name, cover, isDigital, active, ...category } = event.data;

    const payload = {
      name,
      cover,
      isDigital,
      active,
    };
    updateCatalog({
      variables: {
        oid: oid,
        catalog: payload,
      },
    })
      //.then((result) => refetch())
      .catch((e) => console.log(e));
  };

  return (
    <div className="">
      <DataGrid
        columnHidingEnabled={true}
        allowEditing={false}
        dataSource={groupList()}
        keyExpr={"oid"}
        showBorders={true}
        onRowUpdated={onRowUpdated}
      >
        <SearchPanel visible={true} placeholder={"Search..."} />
        <Paging enabled={true} />
        <Editing mode="form" allowUpdating={true} />
        <Column
          dataField="cover"
          width={100}
          cellRender={pictureCellRender}
          editCellComponent={ImageSrcEdit}
        />
        <Column dataField="id" allowEditing={false} />
        <Column dataField="name" />
        <Column dataType={"boolean"} dataField="active" />
        <Column dataField="isDigital" />
        <Column dataField="status" allowEditing={false} />
        <Column type="buttons">
          <Button name="edit" />
          <Button
            text="Select"
            onClick={(e) => {
              props.setUser((user) => ({
                ...user,
                activeAccount: props.account,
                catalog: e.row.data,
                version: e.row.data.activeVersion,
                userChanged: false,
                activeCatalog: e.row.data.id,
                activeVersion: e.row.data.activeVersion?.id,
              }));
              history.push("/editor");
            }}
          />
        </Column>
        <MasterDetail enabled={true} component={GroupCatalogsView} />
      </DataGrid>
    </div>
  );
};

export default withUser(GroupsTab);
