import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { isEmpty, chunk } from 'lodash';
import { Image, Button, Icon, Modal, Header } from 'semantic-ui-react';
import defaultImage from './../../assets/img/page.png';
import ProductsGrid from '../Dynalog/productsGrid';
const uuidv1 = require('uuid/v1');


export default function AddBulkProducts({ id, addPages }) {
    const [visible, setvisible] = useState(false);
    const [selected, setselected] = useState([]);
    const [items, setItems] = useState([]);
    const SortableItem = SortableElement((props) => {
        let backgroundColor = '#CCC';
        if (!isEmpty(props.value.options) && !isEmpty(props.value.options.canvas)) {
            //backgroundColor = props.value.options.canvas.data.background;
        }
        const { imageUrl, pageType } = props.value;
        const isDouble = (!isEmpty(props.value.options) && !isEmpty(props.value.options.isDouble)) ? true : false;
        return (
            <li
                className={["dynPage", isDouble ? "dynPage-isDouble" : ""].join(" ")}

            >
                <Button
                    circular
                    className="deleteBtn"
                    icon="recycle"
                    color="green"
                    size="mini"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        //restore(props.value);		
                        setvisible(false);
                    }}
                />
                {pageType === 'products' ? (
                    <div style={{ backgroundColor: backgroundColor, width: '100%', height: '86%' }} />
                ) : (
                        <Image
                            src={
                                !isEmpty(imageUrl) && imageUrl != '/' ? `https://cdn.catalogs.com${imageUrl}` : defaultImage
                            }
                            size="small"
                            bordered
                        />
                    )}

                <div>{props.position + 1}</div>
            </li>
        );
    });
    const SortableList = SortableContainer(() => {

        return (
            <ul className="pagesWrapper">
                {items?.map((value, index) => {
                    return <SortableItem key={`item-${index}`} index={index} value={value} position={index} />;
                })}
            </ul>
        );
    });

    const createPages = (count, products) => {

        
        const productPages = chunk(products, count)
        var pages = [];
        productPages.forEach((products)=>{
            pages.push({
                oid: uuidv1(),
                pageType: 'products',
                options: {
                    products: products
                }
            });

        })
        setItems(pages);
        /*
        for (let index = 0; index = products.length; index += count) {
            const list = products.slice(index, index + count);
            pages.push({
                oid: uuidv1(),
                pageType: 'products',
                options: {
                    products: list
                }
            });
        };
        setItems(pages);
        */
    }

    return (
        <>
            <Button
                secondary
                icon
                labelPosition="left"
                onClick={() => setvisible(true)}
            >
                <Icon name="plus" />
				Create Bulk Products Pages
		</Button>
            <Modal size="fullscreen" className="settingsModal" closeIcon open={visible} onClose={() => (setvisible(false))}>
                <Header icon="browser" content="Select Products To Create Pages" />
                <Modal.Content scrolling>
                    {items.length > 0 ?
                        <>
                            <Button
                                teal
                                icon
                                labelPosition="right"
                                onClick={() => {                                    
                                    addPages(items);
                                    setItems([]);
                                    setvisible(false);
                                }}
                            >
                            <Icon name="plus" />
                                    Add Pages
                            </Button>
                            <SortableList distance={1} axis="xy" />
                        </> :
                        <ProductsGrid versionId={id} createPages={createPages} />}
                </Modal.Content>
            </Modal>
        </>
    )
}