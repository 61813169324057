import React from 'react';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';

const DynalogWidget = ({ data, dataKey, stroke, fill }) => {
	return (
		<ResponsiveContainer width="100%" height={80}>
			<AreaChart data={data} margin={{ top: 0, bottom: 0, right: 0, left: 0 }}>
				<Area type="monotone" dataKey={dataKey} stroke={stroke} fill={fill} strokeWidth={2} fillOpacity=".8" />
			</AreaChart>
		</ResponsiveContainer>
	);
};

export default DynalogWidget;
