import { API, graphqlOperation } from 'aws-amplify';
import { isEmpty } from 'lodash';
import {
	getAuthorizedUsers,
	getAllAuthorizedUsers,
	getLastUserId,
	getLastPartnerAccessId,
	getUserCampaigns,
	getCampaigns,
	getMarketingCampaigns
} from './queries';
import {
	createAuthorizedUser,
	createPartnerPartnerAccessAuthorizedUserAuthorizedUser,
	createCampaignUser
} from './mutations';
const uuidv1 = require('uuid/v1');

export const getUserDynalogs = async (email) => {
	const result = await API.graphql(graphqlOperation(getAuthorizedUsers, { email: email }));
	console.log(' get user data ', result);
	let user = {
		dynalogs: []
	};
	const authorizedUsersList = result.data.authorizedUsers[0];

	if (!isEmpty(authorizedUsersList)) {
		user.id = authorizedUsersList['oid'];
		user.email = authorizedUsersList['email'];
		const partners = authorizedUsersList['partnerPartnerAccessAuthorizedUserAuthorizedUsersByAuthorizedUsers'];
		const dynalogs = partners.map((partner) => {
			return {
				name: partner.partnerByPartnerAccess.name,
				merchants: partner.partnerByPartnerAccess.dynalogAccountsByPartner.map((merchant) => {
					return {
						id: merchant.id,
						name: merchant.name,
						account: merchant.oid,
						legacyId: merchant.legacyId,
						dynalogs: merchant.dynalogsByAccount
							.filter((dynalog) => {
								return dynalog.dynalogVersionByActiveDynalog != null;
							})
							.map((dynalog) => {
								return {
									id: dynalog.id,
									oid: dynalog.oid,
									merchantId: merchant.id,
									account: merchant.oid,
									name: dynalog.name
								};
							})
					};
				})
			};
		});

		user.dynalogs = dynalogs;
	}

	return user;
};

export const getNextUserId = async () => {
	const { data } = await API.graphql(graphqlOperation(getLastUserId));

	return data['authorizedUsers'][0]['oid'] + 1;
};

export const getNextPartnerAccessId = async () => {
	const { data } = await API.graphql(graphqlOperation(getLastPartnerAccessId));

	return data['partnerPartnerAccessAuthorizedUserAuthorizedUsers'][0]['oid'] + 1;
};

export const updateUserPartner = async (user) => {
	const partnerId = 'c5eae6fe-18d3-4130-a89e-dafa1f906553'; // Demo
	const partnerPartnerAccessAuthorizedUserAuthorizedUserPayload = {
		partnerPartnerAccessAuthorizedUserAuthorizedUser: {
			authorizedUsers: user.id,
			partnerAccess: partnerId,
			oid: uuidv1()
		}
	};
	const { data } = await API.graphql(
		graphqlOperation(createPartnerPartnerAccessAuthorizedUserAuthorizedUser, {
			input: partnerPartnerAccessAuthorizedUserAuthorizedUserPayload
		})
	);
	return data['createPartnerPartnerAccessAuthorizedUserAuthorizedUser']['authorizedUserByAuthorizedUsers'];
};

export const createNewUser = async (email) => {
	const authorizedUserPayload = {
		authorizedUser: {
			email: email,
			oid: uuidv1()
		}
	};
	const { data } = await API.graphql(graphqlOperation(createAuthorizedUser, { input: authorizedUserPayload }));
	return data['createAuthorizedUser']['authorizedUser'];
};

// CAMPAIGN FUNCTIONS

export const getCampaignByUrl = async (url) => {
	const { data } = await API.graphql(graphqlOperation(getCampaigns, { url: url }));
	return data['dynalogCampaigns'];
};

export const getUserCampaign = async (email) => {
	const { data } = await API.graphql(graphqlOperation(getUserCampaigns, { email: email }));
	return data['campaignUsers'];
};

export const newCampaignUser = async (payload) => {
	const userCampaignPayload = {
		campaignUser: {
			oid: uuidv1(),
			email: payload.email,
			campaignGroup: payload.campaignGroupId
		}
	};

	console.log('userCampaignPayload', userCampaignPayload);

	const { data } = await API.graphql(graphqlOperation(createCampaignUser, { input: userCampaignPayload }));
	return data;
};

export const authorizedUsers = async () => {
	const { data } = await API.graphql(graphqlOperation(getAllAuthorizedUsers));
	return data['authorizedUsers'];
};

export const getMarketingCampaignsByAccount = async (account) => {
	const { data } = await API.graphql(graphqlOperation(getMarketingCampaigns, { account: account }));
	return data['marketingCampaigns'];
};
