import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button } from 'semantic-ui-react';


const ProductCard = ({ product, index, deleteCard }) => {
	return (
		<div key={index} className="productCard">
			{product.title}
            <Button
                    circular
                    floated="right"
					icon="trash"
					color="red"
					size="mini"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						deleteCard(index);
					}}
				/>
		</div>
	);
};

export default ProductCard;
