import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Image, Menu, Icon, Dropdown, Modal, Header } from "semantic-ui-react";
import { SelectBox } from "devextreme-react/select-box";
import { isEmpty } from "lodash";
import DataSource from "devextreme/data/data_source";
import { withUser } from "../../context/user";
import { useQuery } from "@apollo/react-hooks";
import { GET_AUTHORIZED_USERS, GET_USER } from "../../models/catalogs/queries";
import Loading from "../Loading";
import { Auth } from "aws-amplify";
import "./TopMenu.scss";
import CreateCatalog from "../Dynalog/CreateForm/createCatalog";

const TopMenu = ({ email, user, setUser, clearUser, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [showCreate, setshowCreate] = useState(false);

  const { loading: loadingUser, error, data: userData, refetch } = useQuery(
    GET_USER,
    {
      variables: { email: user.email || email },
    }
  );

  const getaccounts = () => {
    let ret = [];
    if (userData && userData.user && userData.user.allAccounts)
      userData.user.allAccounts.forEach((x) => {
        if (x.account.active) ret.push(x.account);
      });
    return ret;
  };
  
  useEffect(()=>{
    clearUser(email);
  },[])

  useEffect(() => {
    console.log("userData", userData);
    if (userData?.user) {
      if (user.userChanged) {
        console.log("updating accounts");
        const accounts = getaccounts();
        const activeAccount = accounts ? accounts[0] : null;
        const catalog = activeAccount?.catalogs[0];
        const version = catalog?.activeVersion;
        const newuser = {
          email: user.email,
          isAdmin: email.includes("@catalogs.com"),
          accounts,
          activeAccount,
          catalog,
          version,
          userChanged: false,
          activeCatalog: catalog?.id,
          activeVersion: version?.id,
        };
        console.log("newuser", newuser);
        setUser((user) => ({
          ...user,
          ...newuser,
        }));
      }
    }
    setLoading(false);
  }, [userData]);

  
  if (error) return <div>{JSON.stringify(error)}</div>;

  if (loading || loadingUser) return <Loading />;

  const doLogout = () => {
    Auth.signOut({ global: true })
      .then(() => {
        clearUser({});
        setUser({});
      })
      .catch((err) => console.log(err));
  };
  const onChangeDynalog = (e) => {
    setUser({
      ...user,
      activeCatalog: e.value?.id,
      activeVersion: e.value?.activeVersion?.id,
      padOrg: 0,
    });
  };

  const onChangeAccount = (e) => {
    if (!e.value) return;

    const catalog1 = e.value?.catalogs;
    if (catalog1 !== undefined && catalog1.length > 0) {
      setUser({
        ...user,
        activeAccount: e.value,
        activeCatalog: catalog1[0].id, // e.value.catalogs && e.value.catalogs[0]?.id,
        catalog: catalog1[0],
        activeVersion: catalog1[0].activeVersion?.id, //e.value.catalogs.length>0 && e.value.catalogs[0]?.activeVersion?.id
      });
    } else {
      setUser({
        ...user,
        activeAccount: e.value,
        activeCatalog: null, // e.value.catalogs && e.value.catalogs[0]?.id,
        activeVersion: null, //e.value.catalogs.length>0 && e.value.catalogs[0]?.activeVersion?.id
      });
    }
  };

  const onChangeUser = (e) => {
    clearUser(e.value);
  };

  const handleItemClick = (e, { name }) => {
    //console.log(e, name);
    //setActiveItem();
  };

  function catalogAdded() {
    setshowCreate(false);
    onChangeUser({ value: user.email });
    refetch();
  }

  return (
    <>
      <Menu stackable className="top-menu">
        <Menu.Menu postion="left" className="menu-logo">
          <Menu.Item>
            <Image
              size="mini"
              src="https://cdn.catalogs.com/images/Logo_128x128.png"
            />
            <NavLink to="dashboard"> Portal</NavLink>
          </Menu.Item>
          {user.activeAccount && email.includes("@catalogs.com") && (
            <Menu.Item name="catalogs" onClick={() => setshowCreate(true)}>
              <Icon name="plus" size="large" />
              Add New Catalog
            </Menu.Item>
          )}
        </Menu.Menu>

        <Menu.Menu position="right">
          {email.includes("@catalogs.com") && (
            <Menu.Item name="catalogs" onClick={handleItemClick}>
              <Icon name="user secret" size="large" />
              <UserSelectBox
                activeEmail={user.email}
                onChangeUser={onChangeUser}
              />
            </Menu.Item>
          )}
          {user.accounts &&
          <Menu.Item name="merchants" onClick={handleItemClick}>
            <Icon name="user secret" size="large" />
            <SelectBox
              dataSource={user.accounts}
              value={user?.activeAccount || user.accounts[0]}
              grouped={false}
              searchEnabled={true}
              searchMode="contains"
              searchExpr="name"
              searchTimeout={200}
              minSearchLength={0}
              showDataBeforeSearchOption={false}
              displayExpr={"name"}
              onValueChanged={onChangeAccount}
            />
          </Menu.Item>
          }
          {user.activeAccount && (
            <Menu.Item name="catalogs" onClick={handleItemClick}>
              <Icon name="desktop" size="large" />
              <SelectBox
                dataSource={user.activeAccount.catalogs?.filter(
                  (xx) => xx.active
                )}
                value={user.catalog}
                grouped={false}
                searchEnabled={true}
                searchMode="contains"
                searchExpr="name"
                searchTimeout={200}
                minSearchLength={0}
                showDataBeforeSearchOption={false}
                displayExpr={"name"}
                onValueChanged={onChangeDynalog}
              />
            </Menu.Item>
          )}

          <Menu.Item name="user" onClick={handleItemClick}>
            <Icon name="user" size="large" />
            <Dropdown item text={user.email}>
              <Dropdown.Menu>
                <Dropdown.Item>Profile</Dropdown.Item>
                <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      <Modal closeIcon open={showCreate} onClose={() => setshowCreate(false)}>
        <Header>
          <h2>Create New Catalog in {user.activeAccount?.name}</h2>
        </Header>
        <Modal.Content>
          <CreateCatalog mode={"catalog"} done={catalogAdded} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default withUser(TopMenu);

function UserSelectBox({ onChangeUser, activeEmail }) {
  const { loading, error, data } = useQuery(GET_AUTHORIZED_USERS);

  if (loading || error) return "";

  const userList = new DataSource({
    store: data.authorizedUsers,
    key: "email",
    name: "email",
  });

  return (
    <SelectBox
      dataSource={userList}
      valueExpr={"email"}
      defaultValue={activeEmail}
      grouped={false}
      searchEnabled={true}
      searchMode="contains"
      searchExpr="email"
      searchTimeout={200}
      minSearchLength={0}
      showDataBeforeSearchOption={false}
      displayExpr={"email"}
      onValueChanged={onChangeUser}
    />
  );
}
