import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Form } from 'semantic-ui-react';
import Api from '../../lib/api';




const Incentive = (props) => {
	const org = props.org;
	const [ incentives, setIncentives ] = useState([]);
	useEffect(() => {
		handleListIncentives();
	}, []);

	const handleListIncentives = async () => {
		const { data } = await Api.getIncentives(org.id);
		console.log(data);
		setIncentives(data);

        
	};

	return (
		<div className="content">
			<h1>Incentive</h1>

		</div>
	);
};

export default Incentive;
