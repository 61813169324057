import React from 'react';

import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor';

class MyEditor2 extends React.Component {
	constructor(props) {
		super();

		this.state = {
			valueContent: props.value || '',
			editorValueType: 'html'
		};

		this.sizeValues = [ '8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt' ];
		this.fontValues = [
			'Arial',
			'Courier New',
			'Georgia',
			'Impact',
			'Lucida Console',
			'Tahoma',
			'Times New Roman',
			'Verdana'
		];

		this.valueChanged = this.valueChanged.bind(this);
	}

	componentDidMount() {}

	render() {
		let { valueContent, editorValueType } = this.state;

		return (
			<React.Fragment>
				<div className={'widget-container'}>
					<HtmlEditor
						height={300}
						value={valueContent}
						valueType={editorValueType}
						onValueChanged={this.valueChanged}
					>
						<Toolbar>
							<Item formatName={'undo'} />
							<Item formatName={'redo'} />
							<Item formatName={'separator'} />
							<Item formatName={'size'} formatValues={this.sizeValues} />
							<Item formatName={'font'} formatValues={this.fontValues} />
							<Item formatName={'separator'} />
							<Item formatName={'bold'} />
							<Item formatName={'italic'} />
							<Item formatName={'strike'} />
							<Item formatName={'underline'} />
							<Item formatName={'separator'} />
							<Item formatName={'alignLeft'} />
							<Item formatName={'alignCenter'} />
							<Item formatName={'alignRight'} />
							<Item formatName={'alignJustify'} />
							<Item formatName={'separator'} />
							<Item formatName={'color'} />
							<Item formatName={'background'} />
						</Toolbar>
					</HtmlEditor>
				</div>
			</React.Fragment>
		);
	}
	valueChanged(e) {
		this.setState({
			valueContent: e.value
		});
		this.props.onChange(e.value);
	}
}

export default MyEditor2;
