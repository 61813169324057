import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Divider, Icon } from "semantic-ui-react";
import { withUser } from "../../context/user";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import "./LeftMenu.css";

const LeftMenu = (props) => {
  const user = props.user;

  //console.log('user inside leftmenu', user, props);
  const path = props.location.pathname;
  const location = path.substring(1);

  const [activeMenu, setActiveMenu] = useState(location);

  console.log("location ", location, activeMenu);

  useEffect(() => {}, []);

  let menus = [
    
    {
      path: "leads",
      name: "Leads",
      icon: "group"
    }
  ];

  if (user.padOrg > 0) {
    menus.splice(menus.length, 0, {
      path: "pad",
      name: "Pad",
      icon: "group",
    });
  }

  // if user is admin or part of @catalogs.com
  if (props.user.isAdmin) {
    menus.push({
      // no icon is a divider
      name: "Admin",
    });
    menus.push({
      path: `editor`,
      name: "Editor",
      icon: "chess board",
    });
    menus.push({
      path: "analytics",
      name: "Analytics",
      icon: "inbox",
    });
    menus.push({
      path: "preview",
      name: "Preview",
      icon: "desktop",
    });
    menus.push({
      path: "feed_import",
      name: "feed import",
      icon: "sitemap",
    });
    menus.push({
      path: "merchant",
      name: "Merchant",
      icon: "cogs",
    });
    menus.push({
      path: "catalogs",
      name: "Catalogs",
      icon: "cogs",
    });
  }

  return (
    <div>
      {/*<Header menu={activeMenu} />*/}
      <div className="left-menus">
        {menus.map((item) => {
          const active = (!location && item.base) || activeMenu === item.path;
          return isEmpty(item.icon) ? (
            <Divider key={item.name} horizontal>
              {" "}
              {item.name}{" "}
            </Divider>
          ) : (
            <NavLink
              to={`/${item.path}`}
              name={item.name}
              key={item.path}
              className={active ? "menu active" : "menu"}
              onClick={() => setActiveMenu(item.path)}
            >
              <Icon name={item.icon} size="large" />
              <span>{item.name}</span>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default withUser(withRouter(LeftMenu));
