export const myConfig = {
  CATALOGS_URL:
    process.env.REACT_APP_CATALOGS_URL || "https://digital.catalogshub.com",
  CATALOGS_API_URL:
    process.env.REACT_APP_CATALOGS_API_URL || "https://gql.catalogshub.com/production",
  AUTOFEEDS_API_URL:
    process.env.REACT_APP_AUTOFEEDS_API_URL ||
    "https://gql.catalogshub.com/autofeeds",
  CLIENT_API_URL:
    process.env.REACT_APP_CLIENT_API_URL ||
    "https://gql.catalogshub.com/clients",
  LEADS_API_URL:
    process.env.REACT_APP_LEADS_API_URL || "https://gql.catalogshub.com/leads",
  IMAGE_API_URL:
    process.env.REACT_APP_IMAGE_API_URL || "https://image-api.catalogshub.com",
  FEED_API_URL:
    process.env.REACT_APP_FEED_API_URL || "https://feedapi.catalogs.com/prod",
};
