import axios from 'axios';
const BASE_REPORTS_URL = 'https://reports-qa.catalogs.com/api/';
const BASE_MERCHANT_URL = 'https://api-qa.catalogs.com/v1/';
const BASE_PAD_URL = 'https://padapi-qa.catalogs.com';
const TRACK_URL = 'https://track.catalogs.com/report/';

//const BASE_PAD_URL = 'http://vagrant.local:8080';

const VENTURE_AUTOMOTIVE_KEY = '079B2AA60CE54D768CC8193C89B158988BD830E201731EC2CDE76426D7E52287';
const VENTURE_REALESTATE_KEY = 'BC8619AC3AEF6B9E56855A62EE68352C45FC901F4CE45400E21E0A84A75AEE5E';

const BASE_MARKETCHECK_API = 'https://marketcheck-prod.apigee.net/v2/';
const MARKETCHECK_KEY = 'MfFyPylX5kyORzqjxXwbLLsleNwvcxU4';

var instance = axios.create();
delete instance.defaults.headers.common['X-CSRF-TOKEN'];
delete instance.defaults.headers.common['X-Requested-With'];

export default class Api {
	static getDynalog(groupId) {
		return instance.get(BASE_MERCHANT_URL + 'groups/' + groupId + '/active', {});
	}

	// REPORTS
	static getMerchantReport(payload) {
		return axios.post(BASE_REPORTS_URL + 'merchantreport', payload, {});
	}
	static getReportsByGroupId(id) {
		return axios.get(BASE_REPORTS_URL + 'reports/groupId/' + id);
	}
	static getDynalogDatesReport(payload) {
		return axios.post(TRACK_URL + 'dynalog/' + payload.dynalogId, payload, {});
	}
	static getVentureData(dates) {
		let payload = {
			apikey: VENTURE_AUTOMOTIVE_KEY,
			startdate: dates.startdate,
			enddate: dates.enddate
		};
		return axios.post(BASE_REPORTS_URL + `emailfocus/emailstatsextd`, payload);
	}
	static getTxt180Contacts(payload) {
		return axios.post(BASE_REPORTS_URL + `txt180/contacts`, payload);
	}

	//PAD

	static getPadByMerchantId(merchantId) {
		return axios.get(BASE_PAD_URL + '/orgs/merchant/' + merchantId);
	}

	//org
	static getOrg(id) {
		return axios.get(BASE_PAD_URL + '/orgs/' + id);
	}
	static updateOrgProfile(id, payload) {
		return axios.put(BASE_PAD_URL + '/orgs/' + id + '/profile', payload);
	}
	static getOrgUsers(id) {
		return axios.get(BASE_PAD_URL + '/orgs/' + id + '/users');
	}
	//user
	static createUser(user) {
		return axios.post(BASE_PAD_URL + '/users', user);
	}
	static updateUser(id, user) {
		return axios.put(BASE_PAD_URL + '/users/' + id, user);
	}
	static deleteUser(id) {
		return axios.delete(BASE_PAD_URL + '/users/' + id);
	}
	//grops
	static getGroups(id) {
		return axios.get(BASE_PAD_URL + '/groups/' + id);
	}
	//incentives
	static getIncentives(orgId) {
		return axios.get(BASE_PAD_URL + '/orgs/' + orgId + '/incentives');
	}
	static addIncentive(orgId, data) {
		return axios.post(BASE_PAD_URL + '/orgs/' + orgId + '/incentives', data);
	}
	static deleteIncentive(id) {
		return axios.delete(BASE_PAD_URL + '/incentives/' + id);
	}

	/** SiteImpact */

	static requestSiteimpactHeaders() {
		const token =
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImY2YmFmNzhlYmEzMTNhMmEwNGQ4M2M2OGJkNmZiYjRiOWM0NDJjM2E2MzhlMDRmMWEzYjQwMDViYWVkYmExODlkYzk1OTdhYWNlM2JkYTg4In0.eyJhdWQiOiIxIiwianRpIjoiZjZiYWY3OGViYTMxM2EyYTA0ZDgzYzY4YmQ2ZmJiNGI5YzQ0MmMzYTYzOGUwNGYxYTNiNDAwNWJhZWRiYTE4OWRjOTU5N2FhY2UzYmRhODgiLCJpYXQiOjE1NjU3NDI2MjAsIm5iZiI6MTU2NTc0MjYyMCwiZXhwIjoxNTk3MzY1MDIwLCJzdWIiOiIxMDIzMiIsInNjb3BlcyI6W119.a_-Wo-S6gYmKZrkJkjGoE6e6IPSk3l8klGAtjmg1DXuexpwfUqFgT2j30mk-tZr_y0QWDjBEqBzSLIdo-Ln94WLwtSJopRdJmi4guUdAyLz0j_NX5Yl1G9xWj8PIDH8RS1G3oruFu6T-zi6Jlnlqb-YG8DHSYN4qg32k6fyoBMEal37-lletuxliGPg_IPvn_nHEUkvekh8v0SffWzPtyQBWWtHjvALHwaGOaBmY6ploK8Wp8wJoZ93Xixr2JXHooWNEnZBgwO83UGhohYebQsyArszphi16i4ZvQ191hizf7-3B3KcjWx0aFji8f7X5tqx0qxlQdaZmjFJHpwknbbOOn7zslFhmU01V0QC9qqhRdzIXSwIkyRDHONqO6dssS9fAPwQyizq06gorfzLHyAcFCHwgyrFy76590eBfnxK0GIaCJbJduLQdAKM5nXRyWYajMJYVitObT16EOsEOexNG7gaDfwJ9X_ef1O2QGHd-4PcHYZe3Vi3WMvUH3JXegZ5Xy8A2A1_EgydvtMANZWQfneIztJv5bbQSGPY3_K2mBU_eXcmpdiY7_FZ3dcaFw79MIX67rqjRa42ut9GybHYHvLm429n0pCGnN-xbDmozaOUiJySCryPwtoyag9CWIXWr4vE8s_3hb3LHtE6SeTAgYU-6pHsyYbcVrwf1VAc';
		return {
			Authorization: 'Bearer ' + token,
			'X-Requested-With': 'XMLHttpRequest'
		};
	}
	static getSiteimpactCategories() {
		return axios.get(`https://counts.siteimpact.com/api/categories`, {
			headers: this.requestSiteimpactHeaders()
		});
	}
	static getSiteimpactCategoryOptions(category) {
		return axios.get(`https://counts.siteimpact.com/api/categories/options?category=${category}`, {
			headers: this.requestSiteimpactHeaders()
		});
	}

	static requestMarketchecktHeaders() {
		return {
			'Content-Type': 'application/json'
		};
	}

	static getMarketcheckDealer(search) {
		return axios.get(`${BASE_MARKETCHECK_API}dealers/car?api_key=${MARKETCHECK_KEY}&${search}`, {
			headers: this.requestMarketchecktHeaders()
		});
	}
	static getMarketcheckInventory(search) {
		return axios.get(`${BASE_MARKETCHECK_API}search/car/active?api_key=${MARKETCHECK_KEY}&${search}`, {
			headers: this.requestMarketchecktHeaders()
		});
	}
}
