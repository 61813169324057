import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_CATALOG_BY_VERSION } from '../../models/catalogs/queries';
import { UPDATE_CATALOG } from '../../models/merchant/mutations';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import DynalogSettingsForm from './dynalogSettingsForm';
import VersionSettingsFrom from './versionSettingsForm';

const DynalogSettings = (props) => {
	const { loading, error, data } = useQuery(GET_CATALOG_BY_VERSION, {
		variables: { id: parseInt(props.versionId) }
	});

	const [updateCatalog, { loading: updateDynalogLoading, error: updateDynalogError }] = useMutation(UPDATE_CATALOG);
	const [saving, setSaving] = useState(false);
	const onSubmit = (data) => {

		setSaving(true);
		if (!isEmpty(version) && !isEmpty(data)) {
			Object.keys(data).forEach(key => data[key] == null && delete data[key]);
			console.log('datasettings', data)

			let payload = {};
			if (props.mode === 'version')
				payload = {
					versionsUsingOid: {
						updateByOid: {
							patch: {
								name: data.title || version.title,
								options: {
									...versionOptions,
									...data
								}
							},
							oid: version.oid
						}
					}
				};

			if (props.mode === 'catalog')
				payload = {
					title: data.title,
					description: data.description,
					options: {
						...catalog.options,
						...data
					}
				};

			updateCatalog({
				variables: {
					oid: version.catalog.oid,
					catalog: payload
				}
			})
				.then(({ data }) => {
					//console.log('updated catalog ', data);
				})
				.catch((e) => console.log(e));
		}
		setSaving(false);
		props.done();
	};

	const updateLogo = (logoUrl) => {
		updateCatalog({
			variables: {
				oid: version.catalog.oid,
				catalog: {
					options: {
						...catalog.options,
						logo: logoUrl
					}
				}
			}
		})
			.then(({ data }) => {
				//console.log('updated catalog ', data);
			})
			.catch((e) => console.log(e));
	}

	if (loading) return <p>Loading ...</p>;
	if (error) return <p>ERROR: {error.message}</p>;
	if (!data || isEmpty(data.version)) return <p>Not found</p>;

	const version = data.version;
	const versionOptions = !isEmpty(version.options) ? version.options : {};
	const catalog = version.catalog;

	return (
		<div className="content">
			{props.mode === 'catalog' && <DynalogSettingsForm onSubmit={onSubmit} catalog={catalog} version={version} loading={saving} onUpdateLogo={updateLogo} />}
			{props.mode === 'version' && <VersionSettingsFrom onSubmit={onSubmit} catalog={catalog} version={version} loading={saving} />}
		</div>
	);
};

export default DynalogSettings;
