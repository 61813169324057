import React, { useState, useEffect } from 'react';
import Form, { Item, ButtonItem } from 'devextreme-react/form';

const OrderFrom = (props) => {
	const [ name, setName ] = useState('');
	const [ date, setDate ] = useState('');
	const [ time, setTime ] = useState('');
	const [ timeZone, setTimeZone ] = useState(0);
	const [ quantity, setQuantity ] = useState('');
	const [ count, setCount ] = useState(false);
	const [ from, setFrom ] = useState(false);
	const [ subject, setSubject ] = useState(false);
	const [ testSeeds, setTestSeeds ] = useState(false);
	const [ liveSeeds, setLiveSeeds ] = useState(false);

	useEffect(() => {}, []);

	const validationRules = {
		name: [ { type: 'required', message: 'Name is required.' } ],
		date: [ { type: 'required', message: 'Date is required.' } ],
		time: [ { type: 'required', message: 'Time is required.' } ],
		timeZone: [ { type: 'required', message: 'Time Zone is required.' } ],
		quantity: [ { type: 'required', message: 'Quantity is required.' } ]
	};

	const validateForm = (e) => {
		e.component.validate();
	};

	const timeZones = [ 'Eastern', 'Central', 'Mountain', 'Pacific' ];

	const handleSubmit = (e, value) => {
        e.preventDefault();
		console.log(e, value);
	};

	return (
		<div>
			<form action={'POST'} onSubmit={handleSubmit}>
				<Form>
					<Item dataField={'name'} validationRules={validationRules.name} />
					<Item dataField={'date'} validationRules={validationRules.date} editorType={'dxDateBox'} />
					<Item
						dataField={'time'}
						editorType={'dxDateBox'}
						editorOptions={{ type: 'time' }}
						validationRules={validationRules.time}
					/>
					<Item
						dataField={'timeZone'}
						editorType={'dxSelectBox'}
						editorOptions={{ items: timeZones, value: 'Eastern' }}
						validationRules={validationRules.timeZone}
					/>
					<Item dataField={'quantity'} validationRules={validationRules.quantity} />
					<ButtonItem
						horizontalAlignment={'left'}
						buttonOptions={{
							text: 'Submit',
							useSubmitBehavior: true
						}}
					/>
				</Form>
			</form>
		</div>
	);
};

export default OrderFrom;
