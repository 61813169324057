export default [
	{
        value: 'id',
        id: 'id',
		text: 'Id',
		required: true
	},
	{
        value: 'heading',
        id: 'title',
		text: 'Title',
		required: true
	},
	{
        value: 'inventory_type',
        id: 'category',
		text: 'Category',
		required: true
	},
	{
        value: 'build.model',
        id: 'subcategory',
		text: 'Subcategory',
		required: true
	},
	{
        value: 'msrp',
        id: 'markettextprice',
		text: 'MarkettextPrice',
		required: false
	},
	{
        value: 'ref_price',
        id: 'msrpprice',
		text: 'MSRPPrice',
		required: false
	},
	{
        value: 'price',
        id: 'internetprice',
		text: 'InternetPrice',
		required: false
	},
	{
        value: 'stock_no',
        id: 'skustock',
		text: 'SKUStock',
		required: false
	},
	{
        value: 'vin',
        id: 'vin',
		text: 'Vin',
		required: false
	},
	{
        value: 'media.photo_links.0',
        id: 'imageurl',
		text: 'ImageURL',
		required: false
	},
	{
        value: 'vdp_url',
        id: 'productpageurl',
		text: 'ProductPageURL',
		required: false
	},
	{
        value: 'build.year',
        id: 'year',
		text: 'Year',
		required: false
	},
	{
		value: 'build.make',
        text: 'Make',
        id: 'make',
		required: false
	},
	{
        value: 'build.model',
        id: 'model',
        text: 'Model',
		required: false
	},
	{
        value: 'build.trim',
        id: 'trim',
        text: 'Trim',
		required: false
	},
	{
        value: 'build.body_type',
        id: 'bodytype',
		text: 'BodyType',
		required: false
	},
	{
        value: 'build.drivetrain',
        id: 'drivetrain',
		text: 'DriveTrain',
		required: false
	},
	{
        value: 'build.engine_size',
        id: 'enginesize',
		text: 'EngineSize',
		required: false
	},
	{
        value: 'build.transmission',
        id: 'transmission',
		text: 'Transmission',
		required: false
	},
	{
        value: 'interior_color',
        id: 'interiorcolor',
		text: 'InteriorColor',
		required: false
	},
	{
        value: 'exterior_color',
        id: 'exteriorcolor',
		text: 'ExteriorColor',
		required: false
	},
	{
        value: 'build.city_miles',
        id: 'mpg',
		text: 'MPG',
		required: false
	},
	{
        value: 'miles',
        id: 'mileage',
		text: 'Mileage',
		required: false
	}
];
