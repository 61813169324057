import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_ACTIVE_VERSION } from '../../models/catalogs/queries';
import { DELETE_CATALOG } from '../../models/merchant/mutations';
import { withUser } from '../../context/user';
import {  NavLink } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Iframe from 'react-iframe';
import {myConfig} from '../../config';
const DynalogPreview = (props) => {
	const iframeRef = useRef(null);
	const [tabIndex, setTabIndex] = useState(0);
	const [deleteConfirm, setDeleteConfirm] = useState(false);
	const [messageData, setMessageData] = useState();

	const onResized = (data) => setMessageData(data);

	const onMessage = (data) => {
		setMessageData(data);
		iframeRef.current.sendMessage('Hello back from the parent page');
	};

	const closeDelete = () => {
		setDeleteConfirm(false);
	};

	const doDelete = () => {
		const version = data.catalog.activeVersion;

		deleteDynalogMutation({
			variables: {
				oid: data.catalog.oid
			}
		})
			.then(({ data }) => { })
			.catch((error) => console.log(error));

		setDeleteConfirm(false);
	};
	const {
		loading: loadingActiveDynalog,
		error: activeVersionError,
		data
	} = useQuery(GET_ACTIVE_VERSION, {
		variables: { id: parseInt(props.user.activeCatalog) }
	});

	const [deleteDynalogMutation] = useMutation(DELETE_CATALOG);

	if (loadingActiveDynalog) return <p>Loading ...</p>;
//	console.log('activeVersion ', activeVersion);

	if (
		props.user.activeCatalog > 0 &&
		!isEmpty(data) &&
		!isEmpty(data.catalog?.activeVersion)
	) {
		const catalog = data.catalog;
		const catalogVersion = catalog.activeVersion;

		//console.log('activeVersion ', activeVersion);
		
		const catalogUrl = catalog.groupUrl? `https://${catalog.groupUrl?.replace('http://','')}?merchantID=${catalog.merchantId}`
							: `https://dynalog.catalogs.com/catalogs/${catalogVersion.id}`;

		const catalogMobileUrl = `https://dynalog-mobile.catalogs.com/merchant/index.html#!catalogs/${catalogVersion.id}`;
		const catalog4Url = `${myConfig.CATALOGS_URL}/catalog/${catalog.id}`;
		return (
			<div className="content">
				<div className="catalogWrapper">
						<div className="catalogFrameWrapper">
						<Tabs selectedIndex={tabIndex} onSelect={(tabIndex) => setTabIndex(tabIndex)}>
							<TabList>
								<Tab>4.0</Tab>
								<Tab>Desktop</Tab>
								<Tab>Mobile</Tab>
							</TabList>
							<TabPanel>
							<a href={catalog4Url} target="_blank">{catalog4Url}</a>
							<Iframe
									id="4.0"
									url={catalog4Url}
									className="catalogIframe"
									display="initial"
									position="relative"
									width="100%"
									height="700px"
								/>
							</TabPanel>
							<TabPanel>								
							<a href={catalogUrl} target="_blank">{catalogUrl}</a>
								<Iframe
									id="desktop"
									className="catalogIframe"
									display="initial"
									position="relative"
									url={catalogUrl}
									width="100%"
									height="700px"
								/>
							</TabPanel>
							<TabPanel>
							<a href={catalogMobileUrl} target="_blank">{catalogMobileUrl}</a>
								<Iframe
									url={catalogMobileUrl}
									active={true}
									width="350px"
									height="700px"
									id="catalogIframe"
									className="catalogIframe"
									display="initial"
									position="relative"
								/>
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className="content">
				{JSON.stringify(data)}
				No active catalog found ! Create New Catalog From the Menu
				<br />				
			</div>
		);
	}
};

export default withUser(DynalogPreview);
