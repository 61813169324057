import React from 'react';



const UserContext = React.createContext({});

export const UserProvider = UserContext.Provider;
export const FamilyConsumer = UserContext.Consumer;

export const withUser = (Component) => {



	return function fn(props) {
		return <UserContext.Consumer>{(context) => {
			const clearUser = (nUserEmail) =>
				context.setUser({
					...context.user,
					email: nUserEmail,
					userChanged: true,
					catalog: null,
					version: null,
					activeAccount: null,
					activeCatalog: null, // e.value.catalogs && e.value.catalogs[0]?.id,
					activeVersion: null
				});

			return <Component {...props} user={context.user} clearUser={clearUser} setUser={context.setUser} />
		}
		}
		</UserContext.Consumer>;

	};
};
