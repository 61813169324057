import React, { useState, useEffect } from 'react';
import { Grid, Input, Dropdown, Button } from 'semantic-ui-react';

const MapItem = ({ id, defaultValue, name, required, options, ...props }) => {
	const getOptions = () => {
		const mapKeys = Object.keys(options);
		return mapKeys.map((key, index) => {
			return {
				key: key,
				value: key,
				text: options[key]
			};
		});
	};

	const handleChangeValue = (e, { value }) => props.onUpdateValue(value);
	const handleChangeKey = (e, { value }) => props.onUpdateKey(value);
	return (
		<Grid.Row className={props.className}>
			<Grid.Column width={8}>
				<Input
					label={required ? { icon: 'asterisk' } : false}
					labelPosition="left corner"
					value={name}
					readOnly={required}
					disabled={required}
					onChange={handleChangeKey}
					fluid
				/>
			</Grid.Column>
			<Grid.Column width={7}>
				<Dropdown
					placeholder="Select value"
					fluid
					search
					selection
					value={defaultValue===0?null:defaultValue}
					options={getOptions()}
					onChange={handleChangeValue}
					clearable={true}
				/>
			</Grid.Column>

			<Grid.Column width={1}>
				{!required && (
					<Button circular floated={'right'} icon="trash" onClick={() => props.onDelete(id)} />
				)}
			</Grid.Column>
		</Grid.Row>
	);
};

export default MapItem;
