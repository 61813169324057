import React from 'react';
import { isEmpty, forEach, orderBy } from 'lodash';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SmsReports = (props) => {
	const smsLeadsByDay = props.contacts
		.filter((reports) => {
			console.log('renderSmsReports ', reports);
			return !isEmpty(reports.contacts);
		})
		.map((report) => {
			let keyword = report.keyword;
			return {
				keyword,
				contacts: [].concat(report.contacts)
			};
		})
		.map((x) => {
			let days = {};
			x.contacts.forEach((contact) => {
				let day = moment(contact.timestamp).format('D');
				if (days[day]) {
					days[day] = days[day] + 1;
				} else {
					days[day] = 1;
				}
			});
			return {
				keyword: x.keyword,
				days
			};
		});

	let keywordValues = [];
	let daysValues = [];

	smsLeadsByDay.forEach((keywords) => {
		keywordValues.push(keywords.keyword);
		forEach(keywords.days, function(value, key) {
			const keyword = keywords.keyword;
			daysValues.push({
				day: parseInt(key),
				[keyword]: value
			});
		});
	});

	// merge keywords values by day
	let leadByDate = daysValues.filter(function(v) {
		return this[v.day] ? !Object.assign(this[v.day], v) : (this[v.day] = v);
	}, {});

	const colorsCbfRainbowRGB = [ '#88f3bf', '#69a2ff', '#9673ff' ];

	const chartData = orderBy(leadByDate, [ 'day' ]);
	console.log('OrderBy ', daysValues, chartData, leadByDate);

	return (
		<ResponsiveContainer width="100%" height={300}>
			<BarChart
				data={chartData}
				margin={{
					top: 5,
					right: 10,
					left: 10,
					bottom: 5
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="day" />
				<YAxis />
				<Tooltip />
				<Legend />
				{keywordValues.map((keyword, key) => {
					return <Bar key={keyword} dataKey={keyword} fill={colorsCbfRainbowRGB[key] || '#CCC'} />;
				})}
			</BarChart>
		</ResponsiveContainer>
	);
};

export default SmsReports;
