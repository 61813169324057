import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { withUser } from '../../context/user';
import moment from 'moment';
import Api from '../../lib/api';
import { Table, Dropdown } from 'semantic-ui-react';
import { Popup } from 'devextreme-react/popup';
import DynalogTimeline from '../../components/Reports/dynalogTimeline';
import SmsReports from '../../components/Reports/txt180';
import VentureEmailReports from '../../components/Reports/venture';
import DynalogWidget from '../../components/Reports/dynalogWidget';

import './analytics.css';

const Analytics = (props) => {
	const user = props.user;
	const [ dynalogReport, setDynalogReport ] = useState({});
	const [ reports, setReports ] = useState([]);
	const [ ventureData, seVentureData ] = useState([]);
	const [ smsContacts, setSmsContacts ] = useState([]);
	const [ clicksPopup, setClicksPopup ] = useState([]);
	const [ totalReport, setTotalReport ] = useState({});
	const [ year, setYear ] = useState(moment().format('YYYY'));
	const [ month, setMonth ] = useState(moment().format('MM'));

	useEffect(
		() => {
			Api.getDynalogDatesReport({
				dynalogId: user.activeCatalog,
				from: moment().subtract(12, 'months').format('YYYYMMDD'),
				format: 'true'
			})
				.then((result) => {
					if (result.data) {
						setTotalReport(result.data);
					}
				})
				.catch((err) => {
					setTotalReport([]);
					console.log('Error ', err);
				});
		},
		[ user.activeCatalog ]
	);

	useEffect(
		() => {
			getAllReports()
				.then((result) => {
					setReports(result);
				})
				.catch((error) => {
					setReports([]);
				});
		},
		[ year, month, user.activeCatalog]
	);
	useEffect(
		() => {
			getVentureData();
			getTxt180Data();
		},
		[ reports ]
	);

	const getVentureData = async () => {
		const daysInMonth = moment({ year: year, month: month - 1 }).daysInMonth();
		const from = moment({ year: year, month: month - 1 }).format('YYYY-MM-DD').toString();
		const to = moment({ year: year, month: month - 1, day: daysInMonth }).format('YYYY-MM-DD').toString();
		const dates = {
			startdate: from,
			enddate: to
		};
		await Api.getVentureData(dates)
			.then((result) => {
				seVentureData(result.data);
			})
			.catch((error) => {
				seVentureData([]);
			});
	};

	const getTxt180Data = async () => {
		setSmsContacts([]);

		const daysInMonth = moment({ year: year, month: month - 1 }).daysInMonth();
		const from = moment({ year: year, month: month - 1 }).format('YYYY-MM-DD').toString();
		const to = moment({ year: year, month: month - 1, day: daysInMonth }).format('YYYY-MM-DD').toString();
		const textReports = reports.filter((report) => {
			return report.type == 'txt180';
		});

		textReports.forEach((report) => {
			const keywords = report.settings.keywords.split(',');
			let promises = keywords.map(async (keyword) => {
				const payload = {
					account_id: report.settings.account_id,
					short_code: 90407,
					keyword: keyword,
					date_start: from,
					date_end: to
				};

				return await Api.getTxt180Contacts(payload).then((result) => {
					return {
						id: report.id,
						name: report.name,
						type: report.type,
						dynalogid: report.settings.dynalogid,
						keyword: keyword,
						data: result
					};
				});
			});
			// Wait for all Promises to complete
			Promise.all(promises)
				.then((results) => {
					let smsContacts = [];
					results.forEach((result) => {
						const data = result.data.data;
						smsContacts.push({
							keyword: result.keyword,
							contacts: !data.error ? data.contacts.contact : []
						});
					});
					setSmsContacts(smsContacts);
				})
				.catch((error) => {
					setSmsContacts([]);
					console.log('Error ', error);
				});
		});
	};
	const renderYears = () => {
		return years.map((year) => {
			return {
				text: year,
				value: year
			};
		});
	};

	const renderMonths = () => {
		return months.map((month) => {
			return {
				text: monthNames[month - 1],
				value: month
			};
		});
	};

	const openClicksPopup = (data) => {
		setClicksPopup(data.links);
	};
	const closeClicksPopup = () => {
		setClicksPopup([]);
	};

	const getAllReports = async () => {
		// get all reports by groupId
		return await Api.getReportsByGroupId(user.activeCatalog)
			.then((reports) => {
				if (!isEmpty(reports)) {
					//setReports(reports.data);
					return reports.data;
				} else {
					//setReports([]);
					return [];
				}
			})
			.catch((error) => {
				//setReports([]);
				return [];
			});
	};

	const getTotalCatalogViews = () => {
		return totalReport.totalCatalogView || 0;
	};

	const getTotalPageViews = () => {
		return totalReport.totalPageView || 0;
	};

	const getTotalProductViews = () => {
		return totalReport.totalProductView || 0;
	};

	const getTotalLinkouts = () => {
		return totalReport.totalLinkout || 0;
	};

	const getTotalPadUsers = () => {};

	const getTotalPadLeads = () => {};

	const renderClicks = () => {
		return clicksPopup.map((row) => {
			return (
				<Table.Row key={row.linkid}>
					<Table.Cell>{row.linkurl}</Table.Cell>
					<Table.Cell>
						{row.linkclicks} ({row.linkclickpct}%)
					</Table.Cell>
				</Table.Row>
			);
		});
	};
	return (
		<div className="content">
			<div className="dashboardPage">
				{!isEmpty(totalReport) && (
					<div>
						<div className="timelineWrap">
							<div className="timelineChart">
								<h4>CATALOG REPORT</h4>
								<DynalogTimeline data={totalReport.dates} />
							</div>
							{/* <div className="padWidgets">
								<div className="widget">
									<label>HOOK APP AGENTS</label> 0
								</div>
								<div className="widget">
									<label>SMS LEADS</label> 0
								</div>
							</div> */}
						</div>
						<div className="dashboardWidgets">
							<div className="dynalogWidgets">
								<div className="widget widget_total_catalog">
									<label>TOTAL CATALOG VIEWS</label> {getTotalCatalogViews()}
									<DynalogWidget
										data={totalReport.dates}
										dataKey="totalCatalogView"
										stroke="#88f3bf"
										fill="#88f3bf"
									/>
								</div>
								<div className="widget widget_total_page">
									<label>TOTAL PAGE VIEWS</label> {getTotalPageViews()}
									<DynalogWidget
										data={totalReport.dates}
										dataKey="totalPageView"
										stroke="#69a2ff"
										fill="#69a2ff"
									/>
								</div>

								<div className="widget widget_total_product">
									<label>TOTAL PRODUCT VIEWS</label> {getTotalProductViews()}
									<DynalogWidget
										data={totalReport.dates}
										dataKey="totalProductView"
										stroke="#9673ff"
										fill="#9673ff"
									/>
								</div>
							</div>
						</div>
					</div>
				)}

				<div className="dashboardReport">
					<div className="dateRange">
						<Dropdown
							selection
							options={renderYears()}
							onChange={(e, data) => setYear(data.value)}
							value={parseInt(year)}
						/>
						<Dropdown
							selection
							options={renderMonths()}
							onChange={(e, data) => setMonth(data.value)}
							value={parseInt(month)}
						/>
					</div>

					{/*!isEmpty(dynalogReport) && <div className="dynalogReport">{renderReport()}</div>*/}

					<div className="montlyReports">
						<div className="emailWrapper">
							<h4>PROSPECT EMAIL CAMPAIGNS</h4>
							{!isEmpty(ventureData) && (
								<div className="emailReports">
									<VentureEmailReports
										reports={reports}
										data={ventureData}
										openClicksPopup={openClicksPopup}
									/>
								</div>
							)}
						</div>
						<div className="smsWrapper">
							<h4>SMS KEYWORD ENGAGEMENT</h4>
							{!isEmpty(smsContacts) && (
								<div className="smsReports">
									<SmsReports contacts={smsContacts} />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<Popup
				className={'popup emailLinksPopup'}
				visible={!isEmpty(clicksPopup)}
				onHiding={closeClicksPopup}
				dragEnabled={false}
				closeOnOutsideClick={true}
				showTitle={true}
				title={'Email Link Details'}
				width={'80%'}
				height={'80%'}
			>
				<div>
					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell width={12}>Link</Table.HeaderCell>
								<Table.HeaderCell width={4}>Clicks</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>{renderClicks()}</Table.Body>
					</Table>
				</div>
			</Popup>
		</div>
	);
};

const monthNames = moment.months();
const months = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
const years = [ moment().year(), moment().year() - 1, moment().year() - 2 ];

export default withUser(Analytics);
