import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import LeftMenu from "./components/LeftMenu";
import TopMenu from "./components/TopMenu";
import MainContainer from "./pages/MainContainer";
import Amplify, { Auth } from "aws-amplify";
import { Dimmer, Loader } from "semantic-ui-react";
import { UserProvider } from "./context/user";
import { withAuthenticator } from "aws-amplify-react";
import JoinForm from "./components/JoinForm";
import { myConfig } from "./config";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { ApolloProvider } from "@apollo/react-hooks";
import awsconfig from "./aws-exports";
import Loading from "./components/Loading";
import AppWithAuth from "./pages/AppWithAuth";
import createPersistedState from "use-persisted-state";
const usePersistedUser = createPersistedState("count");

Amplify.configure(awsconfig);

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Basic ${btoa("admin:Catalogs!23")}`,
    },
  };
});
const clients = new ApolloClient({
  link: authLink.concat(
    createHttpLink({
      uri: myConfig.CATALOGS_API_URL,
    })
  ),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.oid || null,
  }),
  //defaultOptions: defaultOptions,
});

const App = () => {
  const [user, setUser] = usePersistedUser({});
  const [email, setemail] = useState();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(async (user) => {
        const authuser = user.email || user.attributes.email;
        setemail(authuser);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {!loading ? (
        <BrowserRouter>
          <ApolloProvider client={clients}>
            <UserProvider value={{ user, setUser }}>
              <AppWithAuth email={email} user={user} setUser={setUser} />
            </UserProvider>
          </ApolloProvider>
        </BrowserRouter>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const federatedOptions = {
  google_client_id:
    "204122962690-1vn1t2clclb4obhl8pvekndpmeuv6run.apps.googleusercontent.com",
};

export default withAuthenticator(
  App,
  {
    includeGreetings: false,
    federated: federatedOptions,
  },
  []
);
