import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { isEmpty, keys, values, value, sum } from 'lodash';
import { Dropdown, Button } from 'semantic-ui-react';
import DynalogTimeline from '../../components/Reports/dynalogTimeline';
import DateBox from 'devextreme-react/date-box';
import { Icon, Label, Menu, Table } from 'semantic-ui-react';
import { PieChart, Pie, Sector, Cell } from 'recharts';
import { withUser } from '../../context/user';
import Api from '../../lib/api';
import data from './data.json';
import './index.scss';

const Analytics2 = (props) => {
	const user = props.user;
	const now = new Date();
	const min = new Date(2000, 0, 1);

	const [totalReport, setTotalReport] = useState({});
	const [selectedView, setSelectedView] = useState('totalCatalogView');
	const [startDate, setStartDate] = useState(moment().subtract(6, 'months'));
	const [endDate, setEndtDate] = useState(moment());

	const options = [
		{ key: 1, text: 'Catalog Views', value: 'totalCatalogView' },
		{ key: 2, text: 'Page Views', value: 'totalPageView' },
		{ key: 3, text: 'Product Views', value: 'totalProductView' },
		{ key: 4, text: 'Linkouts', value: 'totalLinkout' },
		{ key: 5, text: 'Page Views Per Catalog View', value: 'totalPageViewPerCatalogView' },
		{ key: 6, text: 'Product Views Per Catalog View', value: 'totalProductViewPerCatalogView' },
		{ key: 7, text: 'Linkouts Per Catalog View', value: 'totalLinkoutPerCatalogView' }
	];

	const handleChange = (e, { value }) => {
		setSelectedView(value);
	};

	const onDateStartChanged = (e) => {
		setStartDate(e.value);
	};
	const onDateEndChanged = (e) => {
		setEndtDate(e.value);
	};

	const getData = () => {
		Api.getDynalogDatesReport({
			dynalogId: user.activeCatalog,
			from: moment(startDate).startOf('month').format('YYYYMMDD'),
			to: moment(endDate).endOf('month').format('YYYYMMDD'),
			format: 'true'
		})
			.then((result) => {
				if (result.data) {
					console.log('resultData ', result.data);
					setTotalReport(result.data);
				}
			})
			.catch((err) => {
				setTotalReport([]);
				console.log('Error ', err);
			});
	};

	useEffect(() => {
		getData();
	},
		[startDate, endDate, user.activeCatalog]);

	const getTableData = () => {
		const datasource = {};
		if (totalReport.totalCatalogView)
			switch (selectedView) {
				case 'totalCatalogView':
					datasource.label = 'Catalog Views';
					datasource.total = totalReport.totalCatalogView;
					datasource.desktop = totalReport.Desktop.catalogViewTotals;
					datasource.mobile = totalReport.Mobile.catalogViewTotals;
					break;
				case 'totalPageView':
					datasource.label = 'Page Views';
					datasource.total = totalReport.totalPageView;
					datasource.desktop = totalReport.Desktop.pageViewTotals;
					datasource.mobile = totalReport.Mobile.pageViewTotals;
					break;
				case 'totalPageViewPerCatalogView':
					datasource.label = 'Page Views Per Catalog View';
					datasource.total = (totalReport.totalPageView / totalReport.totalCatalogView).toFixed(2);
					datasource.desktop = (totalReport.Desktop.pageViewTotals / totalReport.Desktop.catalogViewTotals).toFixed(2);
					datasource.mobile = (totalReport.Mobile.pageViewTotals / totalReport.Mobile.catalogViewTotals).toFixed(2);
					break;
				case 'totalProductView':
					datasource.label = 'Product Views';
					datasource.total = totalReport.totalProductView;
					datasource.desktop = totalReport.Desktop.productViewTotals;
					datasource.mobile = totalReport.Mobile.productViewTotals;
					break;
				case 'totalProductViewPerCatalogView':
					datasource.label = 'Product Views Per Catalog View';
					datasource.total = (totalReport.totalProductView / totalReport.totalCatalogView).toFixed(2);
					datasource.desktop = (totalReport.Desktop.productViewTotals / totalReport.Desktop.catalogViewTotals).toFixed(2);
					datasource.mobile = (totalReport.Mobile.productViewTotals / totalReport.Mobile.catalogViewTotals).toFixed(2);
					break;
				case 'totalLinkout':
					datasource.label = 'Linkouts';
					datasource.total = totalReport.totalLinkout;
					datasource.desktop = totalReport.Desktop.linkoutTotals;
					datasource.mobile = totalReport.Mobile.linkoutTotals;
					break;
				case 'totalLinkoutPerCatalogView':
					datasource.label = 'Linkouts Per Catalog View';
					datasource.total = (totalReport.totalLinkout / totalReport.totalCatalogView * 100).toFixed(2);
					datasource.desktop = (totalReport.Desktop.linkoutTotals / totalReport.Desktop.catalogViewTotals).toFixed(2);
					datasource.mobile = (totalReport.Mobile.linkoutTotals / totalReport.Mobile.catalogViewTotals).toFixed(2);
					break;
				default:
					datasource.label = 'Catalog Views';
			}
		return datasource;
	};

	const RADIAN = Math.PI / 180;
	const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
				{`${(percent * 100).toFixed(0)}%`}
			</text>
		);
	};

	if (!totalReport) return 'Nothing to show';

	return (
		<div className="content">
			<h2>Analytics</h2>
			<div className="dateRangeWrapper">
				<Button onClick={getData} >Refresh</Button>
				<label>From</label>
				<DateBox value={startDate} min={min} max={now} onValueChanged={onDateStartChanged} displayFormat='monthAndYear' maxZoomLevel='year' />
				<label>To</label>
				<DateBox value={endDate} min={min} max={now} onValueChanged={onDateEndChanged} displayFormat='monthAndYear' maxZoomLevel='year' />
			</div>

			<DynalogTimeline data={totalReport.dates} show={selectedView} />

			<Dropdown onChange={handleChange} options={options} selection value={selectedView} />
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width="4">Device Category</Table.HeaderCell>
						<Table.HeaderCell width="4">{getTableData().label}</Table.HeaderCell>
						<Table.HeaderCell width="4">{getTableData().label}</Table.HeaderCell>
						<Table.HeaderCell width="4">{getTableData().label}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					<Table.Row>
						<Table.Cell>Total</Table.Cell>
						<Table.Cell textAlign="right">{getTableData().total}</Table.Cell>
						<Table.Cell textAlign="right">{getTableData().total}</Table.Cell>
						<Table.Cell rowSpan="4" textAlign="center">
							<PieChart width={200} height={200}>
								<Pie
									data={[
										{ name: 'Desktop', value: Math.ceil(getTableData().desktop) },
										{ name: 'Mobile', value: Math.ceil(getTableData().mobile) }
									]}
									label={renderCustomizedLabel}
									labelLine={false}
									outerRadius={80}
									fill="#8884d8"
									dataKey="value"
								>
									<Cell key={`cell-desktop`} fill="#0088FE" />
									<Cell key={`cell-mobile`} fill="#00C49F" />)
								</Pie>
							</PieChart>
						</Table.Cell>
					</Table.Row>
					<Table.Row className="desktopRow">
						<Table.Cell>1. Desktop</Table.Cell>
						<Table.Cell textAlign="right">{getTableData().desktop}</Table.Cell>
						<Table.Cell textAlign="right">
							{(getTableData().desktop / getTableData().total * 100).toFixed(2)} %
						</Table.Cell>
					</Table.Row>
					<Table.Row className="mobileRow">
						<Table.Cell>2. Mobile</Table.Cell>
						<Table.Cell textAlign="right">{getTableData().mobile}</Table.Cell>
						<Table.Cell textAlign="right">
							{(getTableData().mobile / getTableData().total * 100).toFixed(2)} %
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		</div>
	);
};

export default withUser(Analytics2);
