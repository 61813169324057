import React, { useState, useEffect } from 'react';
import { Popup, ScrollView } from 'devextreme-react';
import { Grid, Input, Message, Button } from 'semantic-ui-react';
import { includes, lowerCase, values } from 'lodash';
import MapItem from './mapItem';
import './mappingForm.scss';

const MappingForm = (props) => {
	const uuidv1 = require('uuid/v1');
	const { options } = props;
	const [ invalid, setInvalid ] = useState(false);
	const initialMapping = props.mapping.map((map) => {
		const keys = values(options);
		const value = includes(keys, map.value) ? map.value : 0;
		return {
			...map,
			value
		};
	});
	const [ mapping, setMapping ] = useState(initialMapping);

	const addNewItem = () => {
		setMapping([
			...mapping,
			{
				id: uuidv1(),
				required: false
			}
		]);
	};

	const deleteItem = (id) => {
		setMapping(mapping.filter((x)=>x.id!==id));
	};

	const updateMapValue = (id, value) => {
		const index = mapping.findIndex((map) => map.id === id);
		const newMapping = [
			...mapping.slice(0, index),
			{
				...mapping[index],
				value: value
			},
			...mapping.slice(index + 1)
		];
		setMapping(newMapping);
	};

	const updateMapKey = (id, value) => {
		const index = mapping.findIndex((map) => map.id === id);
		const newMapping = [
			...mapping.slice(0, index),
			{
				...mapping[index],
				text: value
			},
			...mapping.slice(index + 1)
		];
		setMapping(newMapping);
	};

	const renderMapping = () => {
		return mapping.map((map, index) => {
			return (
				<MapItem
					key={index}
					id={map.id}
					defaultValue={map.value}
					required={map.required}
					name={map.text}
					options={options}
					onUpdateKey={(v) => updateMapKey(map.id, v)}
					onUpdateValue={(v) => updateMapValue(map.id, v)}
					onDelete={(v) => deleteItem(v)}
					className={[ 'mapItem', index % 2 === 0 ? 'itemEven' : 'itemOdd' ].join(' ')}
				/>
			);
		});
	};

	const validateMapping = () => {
	//	console.log('validate mapping ', mapping);
		setInvalid(true);
		for (let map of mapping) {
			if (map.required && (map.value === 0 || map.value==="")) 
				return false;
		}
		setInvalid(false);
		return true;
	};

	const saveMapping = (mapping) => {
		const isValid = validateMapping();
		//console.log('isValid? ', isValid);
		if (isValid) {
			props.onSave(mapping);
		} else {
			console.log('mapping incomplete');
		}
	};
	return (
		<React.Fragment>
			<Grid doubling divided="vertically" className="mappingGrid">
				<Grid.Row>
					<Grid.Column width={8}>Product Field</Grid.Column>
					<Grid.Column width={7}>Feed Column</Grid.Column>
					<Grid.Column width={1} />
				</Grid.Row>
				{renderMapping()}
			</Grid>
			<br />
			{invalid && <Message negative>Form invalid, be sure to map all * fields.</Message>}
			
			{<Button primary onClick={() => addNewItem()}>Add Additional Fields</Button>}
			<Button secondary float="right" onClick={() => saveMapping(mapping)}>
				Map Feed  
			</Button>
			
		</React.Fragment>
	);
};

export default MappingForm;
