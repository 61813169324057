import { Manage } from "./manage";
import React, { useState, useEffect, useCallback } from "react";
import { Redirect } from "react-router-dom";
import arrayMove from "array-move";
import { maxBy, isEmpty, find } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_VERSION_FULL_BY_ID } from "../../models/catalogs/queries";
import {
  UPDATE_CATALOG,
  UPDATE_CATALOG_VERSION,
  DELETE_CATALOG_PAGE,
  CREATE_CATALOG_VERSION,
} from "../../models/merchant/mutations";
import {
  Button,
  Confirm,
  Dimmer,
  Loader,
  Icon,
  Modal,
  Segment,
  Header
} from "semantic-ui-react";
import axios from "axios";
import "react-tabs/style/react-tabs.css";
import "./pageManager.scss";
import DropZone from "../Dynalog/CreateForm/dropZone";
import { myConfig } from "../../config";
import { withUser } from "../../context/user";
import PageEditor from "./pageEditor";
import DynalogSettings from "./dynalogSettings";
import AddBulkProducts from "./addBulkProducts";
import CatalogPageView from "../Dynalog/CreateForm/manage";
import { RestorePages } from "./RestorePages";

const uuidv1 = require("uuid/v1");

const PageManager = ({
  saved,
  setSaved,
  versionId,
  isActive,
  activate,
  update,
}) => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState({});
  const [folder] = useState(uuidv1());
  const [position, setPosition] = useState(null);
  const [deletePopup, setDeletePopup] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupVisibleUrls, setPopupVisibleUrls] = useState(false);
  const [version, setVersion] = useState({});
  const [confirmChangesOpen, setConfirmChangesOpen] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { loading: loadingPages, error, data: dataVersion, refetch } = useQuery(
    GET_VERSION_FULL_BY_ID,
    {
      variables: { id: parseInt(versionId) },
    }
  );

  const groupUrl = version.catalog?.options?.groupUrlTitle ? 
    (myConfig.CATALOGS_URL + "/" + version.catalog?.options.groupUrlTitle + "/catalog/" + version.catalog?.id) : 
    (myConfig.CATALOGS_URL + "/catalog/" + version.catalog?.id)

  const [updateVersion] = useMutation(UPDATE_CATALOG_VERSION);
  const [createVersion] = useMutation(CREATE_CATALOG_VERSION);
  const [] = useMutation(DELETE_CATALOG_PAGE);

  const [updateCatalog] = useMutation(UPDATE_CATALOG);

  const addProductPage = () => {
    setLoading(true);
    const page = {
      oid: uuidv1(),
      pageType: "products",
    };
    addPage(page, version);
    setLoading(false);
    setSaved(false);

    //setPopupVisible(true);
  };

  const addBulk = (bulk) => {
    bulk.map((page) => addPage(page, version));
    setSaved(false);
  };

  const addPage = (page, version) => {
    updateVersion({
      variables: {
        oid: version.oid,
        version: {
          pagesUsingOid: {
            create: page,
          },
        },
      },
    })
      .then(() => {
        setPages([...pages, page]);
        setSaved(false);
      })
      .catch((e) => console.log(e));
  };

  const loadpages = (version) => {
    const order = !isEmpty(version.order) ? version.order : [];

    var npages = version.pages;

    if (!isEmpty(order)) {
      npages = [];
      order.map((o) => {
        const page = find(version.pages, { oid: o.oid });
        //	console.log(o,page);
        if (page) npages.push(page);
      });
    }
    setPages(npages);
    setSaved(true);
    //refetch();
  };
  useEffect(() => {
    if (dataVersion && !isEmpty(dataVersion.version)) {
      setVersion(dataVersion.version);
      loadpages(dataVersion.version);
      //console.log('version@ ', versionPage, versionPage.dynalog.id, props.user.activeVersion);
    }
  }, [dataVersion?.version]);
  const opages = dataVersion?.version?.pages;
  const getNextPosition = (order) => {
    const max = maxBy(order, function (o) {
      return o.num;
    });
    if (max) return max.num + 1;

    return 0;
  };

  const editPage = (position) => {
    let page = pages[position];
    page.position = position;
    page.vProducts = version.products; //Add Products in version

    setPage(page);
    setPopupVisible(true);
  };

  const deletePage = () => {
    if (position) {
      try {
        let page = pages[position];
        const newPages = pages.filter((p) => {
          return p.oid != page.oid;
        });

        setPages(newPages);
        setDeletePopup(false);
        setSaved(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const publishVersion = () => {
    const dynalogOid = version.catalog.oid;
    const versionOid = version.oid;

    updateCatalog({
      variables: {
        oid: dynalogOid,
        catalog: {
          activeVersion: versionOid,
          cover: version.coverUrl,
        },
      },
    })
      .then(() => {
        setVersion({
          ...version,
          catalog: {
            ...version.catalog,
            activeVersion: versionOid,
          },
        });
      })
      .catch((e) => console.log(e));
    activate();
  };
  const applyCover = () => {
    const dynalogOid = version.catalog.oid;
    const versionOid = version.oid;

    updateCatalog({
      variables: {
        oid: dynalogOid,
        catalog: {
          cover: version.coverUrl,
        },
      },
    }).catch((e) => console.log(e));
  };
  const archive = () => {
    updateVersion({
      variables: {
        oid: version.oid,
        version: {
          active: !version.active,
        },
      },
    })
      .then(({ data }) => {
        setSaved(true);
      })
      .catch((e) => console.log(e));
  };
  const cloneVersion = () => {
    let order = [];
    const versionPages = pages.map((page, index) => {
      var oid = uuidv1();
      order.push({ num: index, oid });
      return {
        oid,
        imageSource: page.imageSource,
        imageUrl: page.imageUrl,
        pageType: page.pageType,
        options: page.options,
      };
    });

    const versionPayload = {
      oid: uuidv1(),
      coverUrl: version.coverUrl,
      active: true,
      catalog: version.catalog.oid,
      order,
      options: version.options,
      pagesUsingOid: {
        create: versionPages,
      },
    };

    createVersion({
      variables: {
        version: versionPayload,
      },
    })
      .then(({ data }) => {
        update();
      })
      .catch((e) => console.log(e));
  };
  const deletedItems = () => {
    var npages = opages;
    let dp = [];
    npages.map((page) => {
      if (pages.filter((xx) => xx.oid === page.oid).length === 0) dp.push(page);
    });

    return dp;
  };
  const restorePage = (page) => {
    setPages([...pages, page]);
    setSaved(false);
  };

  const saveVersion = () => {
    var norder = pages.map((page, index) => ({ order: index, oid: page.oid }));
    const newVersion = {
      order: norder,
      coverUrl: pages[0].imageUrl,
    };
    const versionPages = [];
    pages.forEach((page) => {
      const existingPage = version.pages.filter((xx) => xx.oid === page.oid);

      if (existingPage.length === 0) {
        versionPages.push({
          oid: page.oid,
          imageUrl: page.imageUrl,
          pageType: page.pageType,
        });
      }
    });

    if (versionPages.length > 0)
      newVersion.pagesUsingOid = {
        create: versionPages,
      };

    updateVersion({
      variables: {
        oid: version.oid,
        version: newVersion,
      },
    })
      .then(({ data }) => {
        refetch();
        update();
        setSaved(true);
      })
      .catch((e) => console.log(e));
  };

  if (loadingPages) return <p>Loading ...</p>;

  if (error) return <p>Error {JSON.stringify(error)}</p>;
  if (!dataVersion) return <p>Pages not found</p>;
  const versionUrl = `${myConfig.CATALOGS_URL}/version/${versionId}?live=true`;

  const isActiveVersion =
    !isEmpty(version) && version.oid === version.catalog.activeVersion
      ? true
      : false;
  const nextPosition = getNextPosition(version.order);
  const done = (e) => {
    if (e?.page)
      setPages(pages.map((page) => (page.oid === e.page.oid ? e.page : page)));
  };
  /* 
  const hidePopup = (e) => {
    setPopupVisible(false);
  };
  */

  const goTo = (x) => {
    if (page && pages) {
      const nx = page.position + x;
      if (nx > -1) {
        const npage = pages[nx];

        if (npage) {
          npage.position = nx;
          setPage(npage);
        }
      }
    }
  };
  return (
    <div className="content">
      <div className="ui horizontal divider">Edit Version {version.id}</div>
      <Button
        color="teal"
        icon
        labelPosition="left"
        onClick={() => window.open(versionUrl, "_blank")}
      >
        <Icon name="external alternate" />
        Live Preview
      </Button>
      <Button
        positive
        icon
        labelPosition="left"
        onClick={() => publishVersion()}
        disabled={isActive}
      >
        <Icon name="paper plane" />
        {isActive ? "Active Version" : "Publish as Active"}
      </Button>
      <Button
        secondary
        icon
        labelPosition="left"
        onClick={() => cloneVersion()}
      >
        <Icon name="paper plane" />
        Clone Version
      </Button>
      <Button
        secondary
        icon
        labelPosition="left"
        onClick={() => setPopupVisibleUrls(true)}
      >
        <Icon name="info" />
        URL/Iframe Code
      </Button>
      <Button secondary icon labelPosition="left" onClick={() => applyCover()}>
        <Icon name="info" />
        Set As Catalog Cover
      </Button>
      <Button negative icon labelPosition="left" onClick={() => archive()}>
        <Icon name="file archive" />
        {version.active ? "Archive " : "Restore "} Version
      </Button>
      <h3>Page Manager (Drag and Drop to Reorder Pages)</h3>
      <Button
        secondary
        icon
        labelPosition="left"
        onClick={() => addProductPage(version, nextPosition)}
      >
        <Icon name="plus" />
        Add page
      </Button>
      {opages.length !== pages.length && (
        <RestorePages restore={restorePage} items={deletedItems()} />
      )}
      <AddBulkProducts id={versionId} addPages={addBulk} />
      {!saved && (
        <>
          <Button
            primary
            icon
            labelPosition="left"
            onClick={() => saveVersion()}
          >
            <Icon name="sort numeric down" />
            Save Catalog
          </Button>
          <Button
            negative
            icon
            labelPosition="left"
            onClick={() => loadpages(version)}
          >
            <Icon name="recycle" />
            Revert
          </Button>
        </>
      )}

      <CatalogPageView
        pages={pages}
        onDelete={(position) => {
          setPosition(position);
          setDeletePopup(true);
        }}
        editPage={editPage}
        setPages={(pages) => {
          console.log("rapges", pages);
          setPages(pages);
          setSaved(false);
        }}
      />
      <Confirm
        open={deletePopup}
        onCancel={() => setDeletePopup(false)}
        onConfirm={() => deletePage()}
      />
      <Modal
        size="fullscreen"
        className="editorModal"
        closeIcon
        open={popupVisible}
        onClose={() => unsavedChanges ? setConfirmChangesOpen(true) : setPopupVisible(false)} // If there are unsaved changes, open the Confirm Changes modal, otherwise close this modal
      >
        <PageEditor
          versionId={versionId}
          products={version.products}
          catalog={version.catalog}
          pages={pages}
          page={page}
          goTo={goTo}
          done={done}
          confirmChangesOpen={confirmChangesOpen}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={(bool) => setUnsavedChanges(bool)}
          resetConfirmChanges={() => setConfirmChangesOpen(false)}
          closePageEditor={() => setPopupVisible(false)}
        />
      </Modal>
      
      <Modal
        size="fullscreen"
        className="settingsModal"
        closeIcon
        open={popupVisibleUrls}
        onClose={() => setPopupVisibleUrls(false)}
      >
        <Modal.Content>
        {version.catalog && 
          <div class="ui divided selection list">
            <Header size='medium'>URLs & iFrame Code </Header>
            <Header size='small'>Click to copy</Header>
            <a class="item" onClick={()=>navigator.clipboard.writeText(myConfig.CATALOGS_URL + "/version/" + version.id)}>
              <div class="ui blue horizontal label"> Version URL</div>
              {myConfig.CATALOGS_URL}/version/{version.id}
            </a>
            
            <a class="item" onClick={()=>navigator.clipboard.writeText(groupUrl)}>
              <div class="ui green horizontal label">Group URL</div>
              {groupUrl}
            </a>
            <a class="item" onClick={()=>navigator.clipboard.writeText(`<iframe scrolling="auto" width="950px" height="700px" frameborder="0" allowtransparency="true" src="${groupUrl}"></iframe>`)}>
              <div class="ui yellow horizontal label">iFrame Snippet</div>
              {`<iframe scrolling="auto" width="950px" height="700px" frameborder="0" allowtransparency="true" src="${groupUrl}"></iframe>`}
            </a>  
          </div>
          }
        </Modal.Content>
      </Modal>
    </div>
  );
};

export default withUser(PageManager);
