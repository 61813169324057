/**
 * 
CreateAuthorizedUserInput
    authorizedUser: AuthorizedUserInput!
    The AuthorizedUser to be created by this mutation.

    AuthorizedUserInput
        oid: Int
        email: String
 */
export const createAuthorizedUser = `mutation CreateAuthorizedUser($input: CreateAuthorizedUserInput!){
    createAuthorizedUser(input: $input){
        authorizedUser{
          oid
          email
        }
      }
}
  `;

/**
 * 
UpdateAuthorizedUserInput
    patch: AuthorizedUserPatch!
        An object where the defined keys will be set on the AuthorizedUser being updated.

    oid: Int!
 */
export const updateAuthorizedUser = `mutation UpdateAuthorizedUser($input: UpdateAuthorizedUserInput!){
    updateAuthorizedUser(input: $input){
        authorizedUser{
          oid
          email
        }
      }
}
  `;

/*
DeleteAuthorizedUserInput
    oid: Int!
 */
export const deleteAuthorizedUser = `mutation DeleteAuthorizedUser($input: DeleteAuthorizedUserInput!){
    deleteAuthorizedUser(input: $input){
        authorizedUser{
          oid
          email
        }
      }
}
  `;

/**
   * 
   * 
   CreatePartnerPartnerAccessAuthorizedUserAuthorizedUserInput!
    partnerPartnerAccessAuthorizedUserAuthorizedUser
      authorizedUsers: Int
      partnerAccess: Int
      oid: Int
   */
export const createPartnerPartnerAccessAuthorizedUserAuthorizedUser = `mutation CreatePartnerPartnerAccessAuthorizedUserAuthorizedUser($input: CreatePartnerPartnerAccessAuthorizedUserAuthorizedUserInput!){
  createPartnerPartnerAccessAuthorizedUserAuthorizedUser(input: $input){
    authorizedUserByAuthorizedUsers{
      oid
      email
      }
    }
}
  `;

// CAMPAIGNS
/**
  CreateCampaignUserInput
    campaignUser
      oid: Int
      email: String
      campaignGroup: Int
  */
export const createCampaignUser = `mutation createCampaignUser($input: CreateCampaignUserInput!){
  createCampaignUser(input: $input){
    campaignUser{
      oid
      email
      }
    }
}
`;
