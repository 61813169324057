import React, { Component } from "react";
import styled, { css } from "styled-components";

function Product1(props) {
return <Rect73>{props.products[0]}</Rect73>;
}

const Rect73 = styled.div`
  top: 50px;
  left: 25px;
  width: 300px;
  height: 400px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default Product1;
