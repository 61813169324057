import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import LeftMenu from '../../components/LeftMenu';
import TopMenu from '../../components/TopMenu';
import MainContainer from '../MainContainer';
import Loading from '../../components/Loading';
import { getCampaignUserData, getUserData, getXUserData, getPadAccount } from '../../lib/user';
import JoinForm from '../../components/JoinForm';

function AppWithAuth({ email, user, setUser }) {
    if (user.showJoinCampaign)
        return (
            <div className="App">
                <TopMenu email={email} />
                <div
                    className="content"
                    style={{
                        marginTop: '80px'
                    }}
                >
                    {/* <div>
                        <JoinForm onJoin={(email) => onJoin(email)} />
                    </div> */}
                </div>
            </div>);

    return (
        <div className="App">
            <TopMenu email={email} />
            <LeftMenu />
            <MainContainer />
            {/*<Footer />*/}
        </div>
    )
}
export default AppWithAuth;