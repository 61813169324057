import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import defaultImage from './../../assets/img/page.png';
import { Image } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import {withUser } from '../../context/user';
import './groupCatalogsView.scss';
import { CREATE_CATALOG_VERSION } from '../../models/merchant/mutations';

const GroupCatalogsView = (props) => {

	let history = useHistory();
	const catalogActive = props.data.data.activeVersion;

	const renderCatalogs = () => {
		
		const versions = props.data.data.versions || []
		const selectVersion = (version) =>{
			history.push('/editor');
			props.setUser({
				...props.user,
				activeCatalog: props.data.data.id,
				activeVersion: version
			})
			
		}

		return versions.map((version) => {
			const cover = !isEmpty(version.coverUrl) ? `https://cdn.catalogs.com${version.coverUrl}` : defaultImage;
			return (
				<div key={version.id} onClick={()=>selectVersion(version.id)} className="catalogGroup">
					<Image key={version.id} src={cover} size="medium" circular className={["catalogImage", catalogActive && version.id===catalogActive.id?"activeGroup":""].join(" ")} />
				</div>
			);
		});
		
	};

	return (
		<div className="content">
			<h4>Versions</h4>
			{renderCatalogs()}
		</div>
	);
};

export default withUser(GroupCatalogsView);
