import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { Form, Button } from 'semantic-ui-react';
import { Menu, Icon } from 'semantic-ui-react';

import {
	Opacity,
	Stroke,
	StrokeWidth,
	Fill,
	ImageUrl,
	LinkoutUrl,
	FontFamily,
	FontSize,
	LinkoutOptions,
	ShowAsOptions,
	VisibilityOptions
} from './properties';

const CanvasDesignerMenu = ({ initialValues, ...props }) => {
	const [activeIndex, setActiveIndex] = useState(0);

	

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	const onSubmit = (data) => {
		props.onUpdateObject(data);
	};

	const deleteObject = () => {
		props.onDeleteObject();
	};

	const componentsByType = {
		'i-text': ['opacity', 'fontFamily', 'fontSize', 'fill', 'linkoutOptions', 'LinkoutUrl', 'VisibilityOptions'],
		circle: ['opacity', 'stroke', 'StrokeWidth', 'fill', 'linkoutOptions', 'LinkoutUrl', 'VisibilityOptions'],
		rect: ['opacity', 'stroke', 'StrokeWidth', 'fill', 'linkoutOptions', 'LinkoutUrl', 'VisibilityOptions'],
		image: ['opacity', 'ImageUrl', 'linkoutOptions', 'LinkoutUrl', 'VisibilityOptions'],
		product: ['opacity', 'ImageUrl', 'stroke', 'StrokeWidth', 'fill',  'VisibilityOptions']
	};

	const setValue = (key, value) => {
		const payload = {
			[key]: value
		};
		props.onUpdateObject(payload);
	};

	const renderProperties = (object) => {
        const {
			type,
			stroke,
			fill,
			strokeWidth,
			linkout,
			opacity,
			fontFamily,
			fontSize,
			linkoutOptions,
			showAs,
			visibility,
			src
        } = object;
        const typeComponent = object.showAs ? 'product' : object.type;
        
		const array = componentsByType[typeComponent] || [];


		return array.map((component) => {

			switch (component) {
				case 'fontFamily':
					return <FontFamily key="fontFamily" setValue={setValue} defaultValue={fontFamily} />;
					break;
				case 'fontSize':
					return <FontSize key="fontSize" setValue={setValue} defaultValue={fontSize} />;
					break;
				case 'opacity':
					return <Opacity key="opacity" setValue={setValue} defaultValue={opacity} />;
					break;
				case 'stroke':
					return <Stroke key="stroke" setValue={setValue} defaultValue={stroke} />;
					break;
				case 'StrokeWidth':
					return <StrokeWidth key="strokeWidth" setValue={setValue} defaultValue={strokeWidth} />;
					break;
				case 'fill':
					return <Fill key="fill" setValue={setValue} defaultValue={fill} />;
					break;
				case 'ImageUrl':
					return (
						<ImageUrl
							key="imageUrl"
							setValue={setValue}
							defaultValue={type === 'image' ? src : ''}
						/>
					);
					break;
				case 'linkoutOptions':
					return <LinkoutOptions key="linkoutOptions" setValue={setValue} defaultValue={linkoutOptions} />;
					break;
				case 'showAsOptions':
					return <ShowAsOptions key="showAsOptions" setValue={setValue} defaultValue={showAs} />;
					break;
				case 'VisibilityOptions':
					return <VisibilityOptions key="visibilityOptions" setValue={setValue} defaultValue={visibility} />;
					break;
				case 'LinkoutUrl':
					return <LinkoutUrl key="LinkoutUrl" setValue={setValue} defaultValue={linkout} />;
					break;
			}
		});
	};
	const handleItemIndex = (value) => {
		props.onItemIndex(value);
	};
	return (
		<div key={initialValues.id} className="object-settings">
			<Form>
				{renderProperties(initialValues)}
				{initialValues.showAs && (
					<>
					<Form.Field>
						<Button secondary fluid content="Box" icon="square full" onClick={() => props.convertProduct('square')} />
					</Form.Field>
					<Form.Field>
						<Button secondary fluid content="Annotation" icon="plus" onClick={() => props.convertProduct('annotation')} />
					</Form.Field>
					<Form.Field>
						<Button secondary fluid content="Image" icon="image" onClick={() => props.convertProduct('image')} />
					</Form.Field>
					<Form.Field>
						<Button secondary fluid content="Full Page" icon="dochub" onClick={() => props.convertProduct('fullPage')} />
					</Form.Field>
					<Form.Field>
						<Button secondary fluid datacontent="Add users to your feed" content="Product/Category Box" icon="folder" onClick={() => props.convertProduct('product')} />
					</Form.Field>
				</>
				)}
			</Form>
		</div>
	);
};

export default CanvasDesignerMenu;
