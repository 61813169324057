import React, { useState, useCallback } from 'react';
import { isEmpty, times } from 'lodash';
import { Form, Dropdown, Button } from 'semantic-ui-react';
import { ColorBox } from 'devextreme-react';
import './customizePage.scss';

const CustomizePage = ({page, ...props }) => {
	if(!page.options) page.options={};
	console.log('page.options', page.options)
	const [ backgroundColor, setBackgroundColor ] = useState(page.options.backgroundColor || '#CCC');
	const [ borderColor, setBorderdColor ] = useState(page.options.borderColor || '#000');
	const [ borderWidth, setBorderdWidth ] = useState(page.options.borderWidth || 0);

	const getOptions = (number, sufix = '') =>
		times(number, (index) => ({
			key: index,
			text: `${index}${sufix}`,
			value: index
		}));

	const apply = () => {
		const payload = {
			backgroundColor,
			borderColor,
			borderWidth
		};
		
		console.log('paylod', payload)
		props.onApply(payload);
	};
	const applyAll = () => {
		const payload = {
			backgroundColor,
			borderColor,
			borderWidth
		};
		console.log('paylod', payload)
		props.applyAll(payload)
	};
	return (
		<div className="customizePage">
			<Form>
				<Form.Group widths="equal">
					<Form.Field>
						<label> Page Background </label>
						<ColorBox value={backgroundColor} onValueChanged={({ value }) => setBackgroundColor(value)} />
					</Form.Field>
				</Form.Group>
				<Form.Group widths="equal">
					<Form.Field>
						<label> Product Border Color </label>
						<ColorBox value={borderColor} onValueChanged={({ value }) => setBorderdColor(value)} />
					</Form.Field>
					<Form.Field>
						<label> Product Border Size </label>
						<Dropdown
							selection
							compact
							value={borderWidth}
							options={getOptions(10, ' px')}
							onChange={(e, data) => setBorderdWidth(data.value)}
						/>
					</Form.Field>
				</Form.Group>
				<Button content="Apply" negative onClick={() => apply()} />
				<Button content="Apply to All Pages" negative onClick={() => applyAll()} />
			</Form>
		</div>
	);
};

export default CustomizePage;
