import React, { useEffect, useState } from 'react';
import { Menu, Icon, Divider } from 'semantic-ui-react';
import {isEmpty} from 'lodash';
const CanvasSelectorMenu = (props) => {
	const handleItemClick = (e, v) => {
		props.onAddElement(v.name);
	};
	const handleItemIndex = (value) => {
		props.onItemIndex(value);
	};
	return (
		<div className="menuWrapper">
			<Menu compact icon vertical>
				<Menu.Item name="circle" onClick={handleItemClick}>
					<Icon name="circle outline" />
				</Menu.Item>

				<Menu.Item name="text" onClick={handleItemClick}>
					<Icon name="font" />
				</Menu.Item>

				<Menu.Item name="square" onClick={handleItemClick}>
					<Icon name="square outline" />
				</Menu.Item>

				<Menu.Item name="image" onClick={handleItemClick}>
					<Icon name="image" />
				</Menu.Item>
				<Menu.Item name="fullPage" onClick={handleItemClick}>
					<Icon name="dochub" />
				</Menu.Item>
			</Menu>

			<Divider></Divider>
			<Divider></Divider>
			<Divider></Divider>

			{!isEmpty(props.selected) &&
			<Menu compact icon vertical>
				<Menu.Item name="delete" onClick={props.onDeleteObject}>
					<Icon color='red' name="trash" />
				</Menu.Item>
				<Menu.Item name="caretup" onClick={()=>handleItemIndex('up')}>
					<Icon color='grey' name="copy" />
				</Menu.Item>
				<Menu.Item name="caretdown" onClick={()=>handleItemIndex('down')}>
					<Icon color='grey' name="copy outline" />
				</Menu.Item>
			</Menu>
			}
		</div>
	);
};

export default CanvasSelectorMenu;
