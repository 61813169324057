import React, { useEffect, useRef, useCallback, useState } from 'react';
import update from 'immutability-helper';
import { find, isEmpty, compact } from 'lodash';
import { useDrop } from 'react-dnd';
import { Button } from 'semantic-ui-react';
import ProductCard from './productCard';
import './productTemplateEditor.scss';
import ProductTemplate from '../ProductTemplate';
import { ScrollView, Sortable } from 'devextreme-react';


const ProductTemplateEditor = ({ versionId, page, onSave, fullProducts, ...props }) => {
    const initialColor = !isEmpty(page.options) && !isEmpty(page.options.backgroundColor)?page.options.backgroundColor:'#CCC'
	const [ products, setProducts ] = useState([]);

	useEffect(() => {
		const initialProducts = !isEmpty(page.options) && isEmpty(page.options.canvas) ? page.options.products : [];
        setProducts(initialProducts);
    }, [page])

    useEffect(() =>{
        if (props.pageSaved == true)
            savePage();
    }, [props.pageSaved])

	const [ { canDrop, isOver }, drop ] = useDrop({
		accept: [ 'Product' ],
		drop: (_item, monitor) => {
			products ? setProducts([ ...products, _item.product.data ]) : setProducts([_item.product.data]);			
			props.setUnsavedChanges(true);
			props.addSku(_item.product.data.sku)
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});


	function onListReorder(e) {
		  const lists= reorder(products, products[e.fromIndex], e.fromIndex, e.toIndex);
          setProducts(lists);
		  props.setUnsavedChanges(true);
	}

   function reorder(items, item, fromIndex, toIndex) {
    if(fromIndex >= 0) {
      items = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)];
    }

    if(toIndex >= 0) {
      items = [...items.slice(0, toIndex), item, ...items.slice(toIndex)];
    }

    return items;
  }
	const deleteProduct = (index) => {
		const array = [ ...products ];
		array.splice(index, 1);
		setProducts(array);
		props.setUnsavedChanges(true);
	};


	const renderProductsCard = () => {
			return products?.map((product, index) => {
			console.log('product', product)
			if (!product.oid) 
			{	const nproduct = find(fullProducts, { sku: product.sku });
				if(!nproduct)
				{				
					product.title='Missing in Feed-' + product.sku;				
				}
				else product=nproduct;
			}
			console.log('product.id', product.id)
			if (product)
				return (
					<ProductCard
						key={index}
						product={product}
						index={index}						
						deleteCard={deleteProduct}
					/>
				);
		});
	};

	const renderProductTemplate = () => {
		return products?.map((product) => {
			product = find(fullProducts, { sku: product.sku });
			if (product) {
				const imageUrl = product.src.includes('http') ? product.src : `https://cdn.catalogs.com${product.src}`;
				return (
					<div key={product.sku} className="product">
						<img src={imageUrl} />
					</div>
				);
			}
		});
	};

	const savePage = () => {
		const legacyProducts = products.map((product, index) => {
			return {
				productId: product.ID || product.productId,
				orderNum: index + 1,
				sku: product.sku,
				type: 'product',
				versionId
			};
		});
		const cleaned = compact(legacyProducts);
		onSave(cleaned);
        props.resetPageSaved();
        if (props.confirmChangesOpen){  // If saving with Confirm Changes modal, close after saving
            props.resetConfirmChanges();
            props.closePageEditor();
        }
        props.setUnsavedChanges(false);
	};



	return (
		<div className="Editor" ref={drop}>
			<div className="canvasWrapper">			
				<div style={{ backgroundColor: initialColor }} className="productsRender">
				 <ProductTemplate products={renderProductTemplate()} />
				</div>
			</div>

			<div className="canvasWrapper">
				<h5>Drop products here</h5>
					<Sortable onReorder={onListReorder} className="productList">
						{renderProductsCard()}
					</Sortable>
			</div>
		</div>
	);
};

export default ProductTemplateEditor;
