import React, { Component } from "react";
import styled, { css } from "styled-components";

function Product2(props) {
  return (
    <>

      <Rect1>{props.products[0]}</Rect1>
      <Rect>{props.products[1]}</Rect>
    </>
  );
}

const Rect = styled.div`
  top: 260px;
  left: 25px;
  width: 300px;
  height: 220px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

const Rect1 = styled.div`
  top: 25px;
  left: 25px;
  width: 300px;
  height: 220px;
  background-color: rgba(230, 230, 230,1);
  position: absolute;
`;

export default Product2;
