import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import GroupsTab from "./groupsTab.js";
import SettingsTab from "./settingsTab.js";

const MerchantGroupsView = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const catalogs = props.data.data.catalogs || [];
  //console.log("dynalogsByAccount ", dynalogsByAccount)
  return (
    <React.Fragment>
      <h4>Catalogs In Account</h4>
      <GroupsTab account={props.data.data} catalogs={catalogs} />
    </React.Fragment>
  );
  return (
    <Tabs
      selectedIndex={activeIndex}
      onSelect={(tabIndex) => setActiveIndex(tabIndex)}
    >
      <TabList>
        <Tab>Groups</Tab>
        <Tab>Settings</Tab>
      </TabList>
      <TabPanel>
        <GroupsTab dynalogs={catalogs} />
      </TabPanel>
      <TabPanel>
        <SettingsTab />
      </TabPanel>
    </Tabs>
  );
};

export default MerchantGroupsView;
